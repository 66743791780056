import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Link, Route } from 'react-router-dom';
import moment from 'moment'
import common from "../../helpers/common";
import { withRouter } from "react-router";
import { setLoginInfo } from '../../actions/loginAction'
import {getKeywordSearch, keywordSearch, keysearchUrlRes, serachUsedCar} from "../../actions/searchAction";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'


class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            keySearch : [],
        }
        const initialState = {
            isLoggedIn  : false,
            status      : true,
            id          : '',
            message     : '',
            name        : '',
            username    : '',
            email       : '',
            email_verified_at: '',
            user_detail : [],
            client_detail: {
                country: {
                    code : 'JP'
                }
            },
            remember_token: '',
            created_at    : '',
            token: {
                headers     : {},
                original    : {},
                exception   : '',
            },
            loading     : true,
            searchText  :''
        };

        let loginSession = JSON.parse(localStorage.getItem('login-session'));
        if (loginSession === null || loginSession.isLoggedIn === false) {
            localStorage.setItem('login-session', JSON.stringify(initialState));
        } else {
            this.props.setLoginInfo(loginSession)
        }
    }

    onLogout = (e) => {
        common.logout();
        this.props.setLoginInfo({
            loginresp : {
                isLoggedIn:false
            }
        })
        this.props.history.push('/user/login');

    }
    componentDidMount() {
        this.props.serachUsedCarRes()
    }

    usedCarClick =async(title,type)=>{

       await this.props.history.push(`/used-car?search=`+title +`&type=`+type +`&limit=`+15 +`&page=`+1);
    }

    render() {

        let usedCarRes = this.props.usedCarRes;
        let bidTypeList = []
        for (const [index, bidType] of Object.entries(usedCarRes.bidType)) {
            bidTypeList.push(
                <li key={index}><a href="#" onClick={(e) => this.usedCarClick(bidType.title,'bidtype')}>{bidType.title.slice(0,1).toUpperCase() + bidType.title.slice(1, bidType.title.length)}</a></li>
            )
        }
        let bidMakerList = []
        for (const [index, bidMaker] of Object.entries(usedCarRes.bidMaker)) {
            bidMakerList.push(
                <li key={index}><a href="#" onClick={(e) => this.usedCarClick(bidMaker.title,'maker')}>{bidMaker.title}</a></li>
            )
        }
        let enginCCList = []
        for (const [index, bidCC] of Object.entries(usedCarRes.enginCC)) {
            enginCCList.push(
                <li key={index}><a href="#" onClick={(e) => this.usedCarClick(bidCC,'engine_cc')}>{bidCC}</a></li>
            )
        }


        const handleOnSearch =async (string, results) => {
            // onSearch will have as the first callback parameter
            // the string searched and for the second the results.

            this.setState({
                'searchText'     : string,
            })
            await this.props.keywordSearch(string)
            this.setState({ keySearch: this.props.keywordSearchRes })
        }

        const handleOnHover = (result) => {
            // the item hovered
            // console.log(result)
        }

        const handleOnSelect = async (item) => {
            // the item selected
            this.setState({
                'searchText'     : item.id,
            })
            await this.props.keysearchUrlRes({'key':item.id,'type':'select'})
            this.props.history.push(`/keyword?search=`+item.id +`&type=`+'select' +`&limit=`+15 +`&page=`+1);
        }

        const handleOnFocus = () => {
            // console.log('Focused')
        }

        const clickSeacrh = async (e) => {
            e.preventDefault();
            await this.props.keysearchUrlRes({'key':this.state.searchText,'type':''})
            this.props.history.push(`/keyword?search=`+this.state.searchText +`&type=`+'' +`&limit=`+15 +`&page=`+1);
        }


        const handleKeyDown = async (event) => {
            if (event.key === 'Enter') {
                await this.props.keysearchUrlRes({'key':this.state.searchText,'type':''})
                this.props.history.push(`/keyword?search=`+this.state.searchText +`&type=`+'' +`&limit=`+15 +`&page=`+1);
            }
        }

        const formatResult = (item) => {
            return item;
            // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
        }
        let loginSession    = this.props.loginResp //JSON.parse(localStorage.getItem('login-session'));
        let LoginUser       = '';
        if (loginSession != undefined && loginSession.isLoggedIn == true) {
            LoginUser = <div className="wo-user">
                <div className="wo-user__title">
                    <Link to={"/user/profile"}>{loginSession.name}</Link>
                </div>
                <div className="loghov">
                    <figure className="wo-user__img"><img src="/assets/images/index/user-img.jpg" alt="img description" /></figure>
                    <ul>
                    <ul className="sub-menu">
                <li><Link to={'/user/dashboard'}><i className="ti-user"></i> Dashboard</Link></li>
                <li><Link to={'/user/profile'}><i className="ti-user"></i> Profile Settings</Link></li>
                <li><Link to={'/user/deals'}><i className="ti-pencil-alt"></i> My Deals</Link></li>
                <li><Link to={'/user/remittances'}><i className="ti-pencil-alt"></i> My Remittances</Link></li>
                <li> <a onClick={(e) => this.onLogout(e)} ><i className="ti-power-off"></i> Logout</a></li> 
                </ul>
                    </ul>
                </div>
            </div>;
           } else {
            LoginUser = <div className="wo-user">
                <div className="wo-user__title lgwo-btn">
                    <Link to={'/user/login'}>Login</Link>
                </div>
                {/*<div className="wo-user__title lgwo-btn">*/}
                {/*    <Link to={'/register'}>Register</Link>*/}
                {/*</div>*/}
                {/* <div className="loghov">
                    <figure className="wo-user__img"><img src="/assets/images/index/user-img.jpg" alt="img description" /></figure>
                    <ul>
                        <ul className="sub-menu">
                            <li> <Link to={'/register'}><i className="ti-power-off"></i> Register</Link></li>
                        </ul>
                    </ul>
                </div> */}
            </div>
        }
        let items = this.state.keySearch

        return (
            <>
                {/*Preloader Start */}
                {/* <div className="preloader-outer">
                  <div className="loader"></div>
                </div> */}
                {/*Preloader End */}
                {/*HEADER START */}
                <header className="wo-header">
                    <div className="wo-header-topbarwrap">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="wo-topbarcontent">
                                    <div className="wo-topbarcontent__selecter">
                                        <div className="wo-select">
                                            <select id="wo-lang" className="form-control" disabled>
                                                <option hidden="">ENG</option>
                                                <option>CH</option>
                                                <option>EN</option>
                                                <option>AR</option>
                                            </select>
                                        </div>
                                        <div className="wo-select">
                                            <select id="wo-usd" className="form-control" disabled>
                                                <option hidden="">USD ( $ )</option>
                                                <option>CNY ( ¥ )</option>
                                                <option>USD ( $ )</option>
                                                <option>GBP ( £ )</option>
                                                <option>INR ( ₹ )</option>
                                            </select>
                                        </div>
                                    </div>
                                    <ul className="wo-topbarcontent__moreinfo">
                                        <li><a href="tel:+81368514321"><i className="fa fa-phone-alt"></i>+81-368-514-321</a></li>
                                        <li><address><i className="far fa-clock"></i>Japan Time:<b id="current-time-append"> {moment().utcOffset('+0900').format('dddd , MMMM Do YYYY, h:mm a')}</b></address></li>
                                        <li className="menu-item-has-children d-none"><a href="/" data-toggle="modal" data-target="#inspectionpopup"><i className="fas fa-globe-asia"></i>Choose your Country/Region</a>
                                        </li>
                                    </ul>
                                    <div className="wo-topbarcontent__social d-none">
                                        <ul className="wo-socialmedia">
                                            <li><span>Follow Us:</span></li>
                                            <li className="wo-facebook"><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="wo-linkedin"><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li className="wo-twitter"><a href="/"><i className="fab fa-twitter"></i></a></li>
                                            <li className="wo-googleplus"><a href="/"><i className="fab fa-google"></i></a></li>
                                            <li className="wo-youtube"><a href="/"><i className="fab fa-youtube"></i></a></li>
                                            <li className="wo-instagram"><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="wo-header__content">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-2 pl-0 pr-0">
                                                <div className="wo-logo">
                                                    <Link to="/">
                                                        <img src={process.env.REACT_APP_BASE_IMG+"logo.png"} alt="CFBAJapan" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-6 col-3 pr-0 pl-xs-0">
                                                <div className="wo-header-nav-center">
                                                    <nav className="navbar-expand-xl">
                                                        <button className="navbar-toggler wo-btn-small" type="button" data-toggle="collapse" data-target="#jbMainNavbar" aria-expanded="false" aria-label="Toggle navigation">
                                                            <i className="lnr lnr-menu"></i>
                                                        </button>
                                                        <div id="jbMainNavbar" className="collapse navbar-collapse">
                                                            <ul className="navbar-nav wo-navbarnav">
                                                                <li>
                                                                    <Link to="/" > Home </Link>
                                                                </li>
                                                                <li className="menu-item-has-children menu-item-has-mega-menu">
                                                                    <a href="/">Userd Cars</a>
                                                                    <div className="mega-menu">
                                                                        <div className="wo-navcarinfowrap">
                                                                            <div className="wo-navcarinfo">
                                                                                <div className="wo-navbarinfo">
                                                                                    <div className="wo-navbarinfo__title">
                                                                                        <h4>By Car Body Type</h4>
                                                                                    </div>
                                                                                    <ul className="wo-navbarinfo__list">
                                                                                        {bidTypeList}
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="wo-navbarinfo">
                                                                                    <div className="wo-navbarinfo__title">
                                                                                        <h4>By Car Make</h4>
                                                                                    </div>
                                                                                    <ul className="wo-navbarinfo__list">
                                                                                        {bidMakerList}
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="wo-navbarinfo">
                                                                                    <div className="wo-navbarinfo__title">
                                                                                        <h4>By Engine Capacity</h4>
                                                                                    </div>
                                                                                    <ul className="wo-navbarinfo__list">
                                                                                        {enginCCList}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="wo-cararticle">
                                                                                <div className="wo-cararticle__img">
                                                                                    <a href="/" data-toggle="modal" data-target="#videopopup"><img src="/assets/images/index/video-img.jpg" alt="imagedescription" /></a>
                                                                                </div>
                                                                                <div className="wo-cararticle__content">
                                                                                    <h3>Before You Buy Used Car</h3>
                                                                                    <p>Consectetur adipisicing elitem sed dotium eiusmod tempor incididunt ut labore etnalo dolore</p>
                                                                                    <a href="/">Read Full Article</a>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                        {/* <div className="wo-navad">
                                                                            <a href="/"><img src="/assets/images/ad-img.jpg" alt="img description" /></a>
                                                                        </div> */}
                                                                    </div>
                                                                </li>
                                                                
                                                                <li>
                                                                    <Link to={"/about"}> About </Link>

                                                                </li>
                                                                <li>
                                                                    <Link to="/stock" > Stock </Link>
                                                                </li>
                                                                <li className="menu-item-has-children d-none">
                                                                    <a href="/">Search</a>
                                                                    <ul className="sub-menu">
                                                                        <li><a href="indexv2.html">Search by Sedan</a></li>
                                                                        <li><a href="owner-reviews.html">Search by Coupe</a></li>
                                                                        <li><a href="vehicle-single.html">Search by Suv</a></li>
                                                                        <li><a href="compare-vehicles.html">Search by Hatchback</a></li>
                                                                        <li><a href="about.html">Search by Station Wagon</a></li>
                                                                        <li><a href="contact.html">Search by Mpv</a></li>
                                                                        <li><a href="404.html">Search by Pick Up</a></li>
                                                                    </ul>
                                                                </li>
                                                                <li className="menu-item-has-children">

                                                                    <Link to="/how-to-buy" > How to Buy </Link>
                                                                    <ul className="sub-menu">
                                                                        <li><Link to="/how-to-buy" > Bank Details </Link></li>
                                                                        <li><Link to="/sale-process" > Sale Process </Link></li>
                                                                        <li><Link to="/faqs" > FAQs </Link></li>
                                                                    </ul>
                                                                </li>
                                                                <li className="menu-item-has-children">
                                                                    <a href="/">Auction</a>
                                                                    <ul className="sub-menu">
                                                                        <li><Link to="/auction-system" > Auction System </Link></li>
                                                                        <li><a href="https://cfbajapan.ajes.com/">Live Auction</a></li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <Link to="/auto-parts" > Auto Parts </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/reviews" > Reviews </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/shipping-schedule" > Shipping Schedule </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/trusted-client" > Trusted Client Reviews </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </nav>
                                                    <div className="wo-header-btns d-none">
                                                        <a href="/" className="wo-btn wo-compare-btn" data-toggle="modal" data-target="#inspectionpopup"><i className="ti-reload"></i> Show Compare<em>03</em></a>
                                                        <a href="dashboard-post-ads.html" className="wo-btn">Sell Vehicle</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-sm-3 col-9 pr-0">

                                                <div className="wo-header-user wo-userlogin">
                                                    <div className="wo-user">
                                                        <div className="wo-user__title">
                                                            <a href="/" data-toggle="modal" data-target="#loginpopup">Hi! User5256<em>Join Now/Login</em></a>
                                                        </div>
                                                        <figure className="wo-user__img"><img src="/assets/images/index/user-img2.png" alt="img description" /></figure>
                                                    </div>
                                                </div>
                                                <div className="wo-header-user">
                                                    {LoginUser}
                                                    {/* <div className="wo-user">
                                                     {user}
                                                     <div className="loghov">
                                                     <figure className="wo-user__img"><img src="/assets/images/index/user-img.jpg" alt="img description" /></figure>
                                                     <ul>
                                                     {dashboardList}
                                                     </ul>
                                                     </div>
                                                     </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/*HEADER END*/}
                {/*TOPSEARCH START*/}
                <div className="wo-innerbanner-wrap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="wo-innerbanner">
                                    <div className="wo-searchbar">
                                        <div className="wo-searchbar__title">
                                            <h4>Search by Keywords</h4>
                                        </div>
                                        <header className="App-header">
                                            <div className="header-search" onKeyDown={handleKeyDown}>
                                                <ReactSearchAutocomplete
                                                    items={items}
                                                    onSearch={handleOnSearch}
                                                    onHover={handleOnHover}
                                                    onSelect={handleOnSelect}
                                                    onFocus={handleOnFocus}
                                                    autoFocus
                                                    value =''
                                                    formatResult={formatResult}
                                                />
                                            </div>
                                        </header>

                                        <form className="wo-themeform wo-bannerform">
                                            <fieldset>
                                                <div className="wo-group-wrap">
                                                    {/*<div className="form-group wo-formradio">*/}
                                                    {/*    <input type="text" className="form-control" placeholder="Search for Used Car"></input>*/}
                                                    {/*</div>*/}
                                                    <div className="form-group wo-form-btn">
                                                        <a href="#" onClick={clickSeacrh} className="wo-searchntn"><i className="ti-search"></i></a>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*TOPSEARCH END */}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setLoginInfo    : (logindata)   => dispatch(setLoginInfo(logindata)),
    keywordSearch   : (search)      => dispatch(keywordSearch(search)),
    keysearchUrlRes : (key)         => dispatch(keysearchUrlRes(key)),
    serachUsedCarRes: ()            => dispatch(serachUsedCar())
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    loginResp       : state.customerReducer.loginresp,
    keywordSearchRes: state.homeReducer.keywordSearchRes,
    urlSearchRes    : state.homeReducer.urlSearchRes,
    usedCarRes      : state.homeReducer.usedCarRes,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
