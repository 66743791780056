import React, {Component} from 'react';
import ReactStars from "react-rating-stars-component";
import common from "../../helpers/common";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import {confirmAlert} from "react-confirm-alert";
import RCarousel from "./RCarousel";
import ReviewCarousel from "./ReviewCarousel";
import {Carousel} from "react-responsive-carousel";

export class ReviewList extends Component {
    constructor(props) {
        super(props)
    }
    onReviewShow = (review) => {
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                        <ReviewCarousel review={review} />
                );
            }
        });
    }
    componentDidMount() {
    }
    render() {
        let reviewList      = []
        let customerReviews = this.props.data
        customerReviews.map((review,key) =>{
            if(review.rate){
                const firstExample = {
                    size: 30,
                    value: review.rate,
                    edit: false,
                    activeColor: 'red',
                };
                let baseImageUrl = process.env.REACT_APP_BASE_IMG+'review/'+review.id+'/';
                let sliderimages = [];
                if(review.media.length > 0){
                    review.media.map(media =>
                        {
                            let fileExtension = media.fileName.split('.').pop();
                            if (fileExtension=="jpg" || fileExtension=="jpeg" || fileExtension=="png"){
                                sliderimages.push(
                                    <div key={media.id} className='RCarouselItem'>
                                        <img className="img-fluid" width="600px" height="400px" src={baseImageUrl+media.fileName}/>
                                    </div>
                                )
                            }else{
                                {
                                    sliderimages.push(
                                        <div key={media.id} width="600px" height="200px" className='RCarouselItem'>
                                            <video  controls>
                                                <source src={baseImageUrl+media.fileName} type="video/mp4"/>
                                            </video>
                                        </div>
                                    )
                                }
                            }

                        }
                    )
                }else{
                    sliderimages =
                        <div className='RCarouselItem'>
                            <img src="https://www.carfitexperts.com/car-models/wp-content/uploads/2019/01/zen-1.jpg"/>
                        </div>
                }
                reviewList.push(
                        <div className="col-md-4" key={key}>
                            <div className="review-block">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Carousel showThumbs={false}>
                                            {sliderimages}
                                        </Carousel>
                                    </div>
                                    <div className="col-sm-12">
                                        {/* <div className="text-dark">Reviews</div> */}
                                        <div className="review-block-rate">
                                            <ReactStars {...firstExample} />
                                        </div>
                                        <div className="review-block-title">{review.deal.bid.car_maker.title} {review.deal.bid.car_model.title} {review.deal.bid.car_color.title}</div>
                                        <div className="review-block-description">
                                            {review.description}
                                        </div>
                                        <span className="reviewer-name">by {review.client.fullname} ({review.client.country.name}) <br/> {review.created_at}
                                            <br/>
                                        <a href="#" onClick={(e) => this.onReviewShow(review)}>Review Videos</a>
                                    </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                )
            }

        })

        return (
            <div className="review">
                <div className="row">
                    {reviewList.length ? reviewList : 'No Record Found!' }
                </div>
            </div>
        )
    }
}


export default ReviewList;