import React, { Component } from 'react'
import common from "../../../../helpers/common"
import { Link } from "react-router-dom"
import NumberFormat from 'react-number-format'
import {confirmAlert} from "react-confirm-alert";
import {getKeywordSearch, getMainSearch, keysearchUrlRes, serachByType} from "../../../../actions/searchAction";
import {getCountries, getMakers, getModels} from "../../../../actions/commonAction";
import {connect} from "react-redux";
import {storeQuoteAction} from "../../../../actions/storeQuoteAction";
import {toast, ToastContainer} from "react-toastify";

class HorizontalItem extends Component {
    constructor(props) {
        super(props)
        let loginSession = JSON.parse(localStorage.getItem('login-session'));
        this.state = {
            // sold : props.sold ?? false,
            current_status : false,
            deal_id     : '',
            first_name  : loginSession.name,
            loading     : true,
            last_name   : '',
            email       : loginSession.email ?? '',
            phone       : loginSession.user_detail.phone ?? '',
            country     : loginSession.country != undefined ? loginSession.country.name : '',
            message     : '',
            nameError   : '',
            emailError  : '',
            phoneError  : '',
            success     : '',
            qouteModel  : true,
            modelAction : '',
            countrieslist: [],
        }
    }
    textareaChange  (event)  {
        this.setState({ message: event.target.value});
    }

    onQuotationNow    = (deal_id) => {
        let countrieslist  = this.props.countrieslist
        this.setState({
            modelAction     : 'qoute',
            deal_id         : deal_id,
        });
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Get Quote</h4>
                                <a ref={input => this.closeModal = input} onClick={() => { onClose() }} className="close"><i className="ti-close" ></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">First Name:</label>
                                            <input defaultValue={this.state.first_name} name={'first_name'} placeholder={"First Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Last Name:</label>
                                            <input name={'last_name'} placeholder={"Last Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.nameError}</p>
                                        </div>
                                        <div className="form-group">
                                            <alabel className="wo-titleinput">Email:</alabel>
                                            <input defaultValue={this.state.email} name={'email'} placeholder={"Enter your email address."} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.emailError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Phone:</label>
                                            <input defaultValue={this.state.phone} name={'phone'} placeholder={"+1(801)889-3563*."} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.phoneError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Country:</label>
                                            <select name={"country"} onChange={e => this.handleChange(e)} className="wo-titleinput">
                                                {countrieslist.map((obj) => {
                                                    let selected = (266 === obj.label) ? ' selected' : '';

                                                    return <option value={obj.label} >{obj.label}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Message:</label>
                                            <textarea id="message" name="message" onChange={e=>this.textareaChange(e)} className="input-detail  form-control" name="" rows="4" placeholder="Enter your message *"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-success'}>{this.state.success}</p>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <button onClick={(e) => this.onModelSubmit(e)} className='wo-btn'>Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    handleChange = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.name === "sortBy" && this.state.type == 'main-search'){
            await this.onSubmit('','function')
        }else if(e.target.name === "sortBy" && this.state.type == 'category'){
            await this.props.getMainSearch({ 'maker': this.state.category ,'sort':this.state.sortBy});
        }else if(e.target.name === "sortBy" && this.state.type == 'country'){
            await this.props.getMainSearch({ 'location': this.state.country ,'sort':this.state.sortBy});
        }else if(e.target.name === "sortBy" && this.state.type == 'prices'){
            await this.props.getMainSearch({ 'prices': this.state.prices ,'sort':this.state.sortBy});
        }else if(e.target.name === "sortBy" && this.state.type == 'used_car'){
            const { usedCarSearch,usedCarType } = this.state;
            this.props.getkeywordSearch(usedCarSearch,usedCarType,this.state.sortBy);
        }
    }

    onModelSubmit = (e) => {
        e.preventDefault();
        const data = {
            'name'      : this.state.first_name + ' ' + this.state.last_name,
            'email'     : this.state.email,
            'country'   : this.state.country,
            'phone'     : this.state.phone,
            'message'   : this.state.message,
            'deal_id'   : this.state.deal_id,
        }
        this.props.storeQuote(data,'listingPage')
        this.setState({qouteModel:true});

    }

    componentDidMount() {
        // common.appendScript('./assets/js/pages/topnew.js')
    }

    componentWillReceiveProps = (nextProps) =>{
        this.setState(
            {
                qouteResp       :nextProps.listingQuoteresp,
            },()=>{
                const quoteresp = this.state.qouteResp
                if (quoteresp !== undefined && quoteresp !== '' && this.state.modelAction === "qoute" && this.state.qouteModel) {
                    if (quoteresp.status === true) {
                        let quotePopup = <ToastContainer>
                            {
                                toast.success(quoteresp.msg, {
                                    position    : "top-right",
                                    autoClose   : 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable   : true,
                                    progress    : undefined,
                                })
                            }
                        </ToastContainer>;
                        this.setState({qouteModel:false});
                        this.setState({
                            success     :quotePopup,
                            nameError   : '',
                            emailError  : '',
                            phoneError  : '',
                        }, () => {
                            this.closeModal.click()
                            // this.onQuotationNow()
                        });

                    } else {
                        this.setState({
                            success: '',
                            nameError   : quoteresp.errors.name,
                            emailError  : quoteresp.errors.email,
                            phoneError  : quoteresp.errors.phone,
                        }, () => {
                            this.onQuotationNow()
                        });
                    }
                    this.setState({qouteResp:''})
                }
            });
    }

    render() {
        const { deal , sold } = this.props
        this.current_status = deal.bid.attributes.current_status ?? sold
        let baseImageUrl = process.env.REACT_APP_BASE_IMG;
        let image = ''
        let currencyType = deal.currencyType  === 'usd' ? '$' : '¥';
        if (deal.bid.media.length > 0) {
            image = <figure style={{height:'170px' ,width:'225px'}} className="wo-vehicles-img">
                <img src={baseImageUrl + common.imageFullPath(deal.bid.media[0])}
                    alt={deal.bid.car_maker.title + " " + deal.bid.year_month} />
            </figure>
        } else {
            image = <figure className="wo-vehicles-img">
                <img src="https://www.carfitexperts.com/car-models/wp-content/uploads/2019/01/zen-1.jpg"
                    alt="no image available" />
            </figure>

        }

        return (
            <div className="wo-topvehicles wo-topvehicles-list">
                {this.state.success}
                <Link to={'/car-details/'+deal.id+'/'+deal.bid.car_maker.title +'-'+ deal.bid.car_model.title +'-'+ deal.bid.year_month}>
                    <div className={`wo-vehiclesslider ${this.current_status}`}>
                    {image}
                    <div className="wo-vehiclesimg-tags">
                        <div className="wo-tags">
                            <a href="#" className={"wo-tag-rent " + (this.current_status ? '' : 'd-none')}>{deal.bid.attributes.current_status}</a>
                            <a href="#" className="wo-tag-photos"><i className="ti-image"></i>25</a>
                        </div>
                        <a href="#" className="wo-likepost"><i className="ti-heart"></i></a>
                    </div>
                </div>
                </Link>
                <div className="wo-vehicles">
                    <div className="col-xl-9 col-lg-12">
                        <Link to={'/car-details/'+deal.id+'/'+deal.bid.car_maker.title +'-'+ deal.bid.car_model.title +'-'+ deal.bid.year_month}>
                            <div className="wo-vehicles-head">
                                <div className="wo-vehicles__title">
                                    <h5><Link to={'/car-details/'+deal.id+'/'+deal.bid.car_maker.title +'-'+ deal.bid.car_model.title +'-'+ deal.bid.year_month}>{deal.bid.car_maker.title} {deal.bid.car_model.title} - {deal.bid.year_month}</Link></h5>
                                </div>
                            </div>
                            <table className="table-detailed-spec">
                                <tbody>
                                    <tr>
                                        <td className="th th-tab">Mileage</td>
                                        <td className="td">{deal.bid.mileage} km</td>
                                        <td className="th th-tab">Year</td>
                                        <td className="td">{deal.bid.year_month}</td>
                                        <td className="th th-tab">Engine</td>
                                        <td className="td">{deal.bid.engine_cc}cc</td>
                                        <td className="th th-tab">Trans</td>
                                        <td className="td">Mt</td>
                                        <td className="th th-tab">Location</td>
                                        <td className="td">{deal.logistic_info.yard.title ? deal.logistic_info.yard.title.split('-')[0] : '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-detailed-spec">
                            <tbody>
                                <tr>
                                    <td className="th th-tab">Stock id</td>
                                    <td className="td">{ deal.serial ?? '-'}</td>
                                    <td className="th th-tab">Steering</td>
                                    <td className="td">{ common.capitalize(deal.bid.attributes.steering ?? '-')}</td>
                                    <td className="th th-tab">Fuel</td>
                                    <td className="td">{ common.capitalize(deal.bid.attributes.fuel ?? '-')}</td>
                                    <td className="th th-tab">Seats</td>
                                    <td className="td">{ deal.bid.attributes.seats ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td className="th th-tab">Body Type</td>
                                    <td className="td">{common.capitalize(deal.bid.attributes.bid_type ?? '--')}</td>
                                    <td className="th th-tab">Color</td>
                                    <td className="td">{common.capitalize(deal.bid.car_color.title ?? '-')}</td>
                                    <td className="th th-tab">Drive</td>
                                    <td className="td">{deal.bid.attributes.drive ?? '-'}</td>
                                    <td className="th th-tab">Doors</td>
                                    <td className="td">{deal.bid.attributes.doors ?? '-'}</td>
                                </tr>
                                {/* <tr>
                                    <td className="th th-tab">Max load</td>
                                    <td className="td-colspan" colSpan="7">1.25 tons</td>
                                </tr> */}
                            </tbody>
                        </table>
                            <ul className="wo-vehicles__list">
                                <li><span>Power Steering / </span></li>
                                <li><span>A/C  /</span></li>
                                <li><span>Radio /</span></li>
                                {/* <li><span>Jack /</span></li> */}
                                {/* <li><span>Fog Lights /</span></li> */}
                                <li><span>Power Window /</span></li>
                                <li><span>Wheel Spanner</span></li>
                            </ul>
                        </Link>
                    </div>

                    <div className="col-xl-3 col-lg-12">
                        <div className="wo-vehicles-head">
                            <div className="wo-vehicles__title">
                                <Link to={'/car-details/'+deal.id+'/'+deal.bid.car_maker.title +'-'+ deal.bid.car_model.title +'-'+ deal.bid.year_month}>
                                    <p className="label-price">Price</p>
                                    <p className="vehicle-price">
                                        <span className="currency-label"></span><span className="price"><NumberFormat value={deal.bid.attributes.selling_price ?? deal.clientTotal} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={currencyType} /></span></p>
                                    <div className="line"></div>
                                    <p className="label-total-price">Total Price</p>
                                    <p className="total-price">
                                        <span className="currency-label"></span><span className="price"><NumberFormat value={deal.bid.attributes.selling_price ?? deal.clientTotal} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={currencyType} /></span></p>
                                    <div className="destination-wrap">
                                        <p className="price-condition">{deal.bid.attributes.selling_type != undefined ? deal.bid.attributes.selling_type.toUpperCase() : '--'} to  <strong>{ deal.logistic_info.destination_port.title ?? '-'}</strong></p>
                                    </div>
                                    <div className="destination-wrap">
                                        <p className="price-condition">Inspection : <strong>{deal.logistic_info.InspecType ?? '--'}</strong></p>
                                    </div>
                                </Link>
                                
                                <div className={`form-group wo-form-btn typesearch ${this.current_status !== 'available' ? 'd-none' : ''}`}><a href="#" onClick={(deal_id) => this.onQuotationNow(deal.id)} className="wo-inq">Get Quote</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapDispatchToProps = dispatch => ({
    storeQuote      : (jsondata,type) => dispatch(storeQuoteAction(jsondata,type)),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        listingQuoteresp     : state.dealReducer.listingQuoteresp,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HorizontalItem);