import NumberFormat from 'react-number-format';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCarDetails } from '../../actions/dealAction'
import { getCountries } from '../../actions/commonAction';
// import common from '../../helpers/common'
import Loader from '../../components/partials/Loader'
import RCarousel from './RCarousel'
import Similar from './Similar';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {storeQuoteAction} from "../../actions/storeQuoteAction";
import ReactStars from "react-rating-stars-component";
import {getcustomerFeedback, getcustomerReview, storeFeedback, storeReview} from "../../actions/customerAction";
import ReviewList from "./ReviewList";
import {FeedbackList} from "./FeedbackList";
import {Link} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router';
import ReactGa from 'react-ga'
import MetaHelmet from './widgets/MetaHelmet';

export class CarDetails extends Component {
    constructor(props) {
        super(props)
        let loginSession  = this.props.loginresp //JSON.parse(localStorage.getItem('login-session'));
        this.state = {
            id          : '',
            first_name  : loginSession.name,
            loading     : true,
            last_name   : '',
            email       : loginSession.email ?? '',
            phone       : loginSession.user_detail.phone ?? '',
            country     : loginSession.country != undefined ? loginSession.country.name : '',
            message     : '',
            nameError   : '',
            emailError  : '',
            phoneError  : '',
            success     : '',
            countrieslist       : [],
            current_status      : props.sold ?? false,
            title               : '',
            rate                : '',
            image               : [],
            description         : '',
            titleError          : '',
            rateError           : '',
            imageError          : '',
            descriptionError    : '',
            car_id_error        : '',
            client_id_error     : '',
            feedbackName        : '',
            feedbackPhone       : '',
            feedbackEmail       : '',
            feedbackSubject     : '',
            feedbackMessage     : '',
            feedbackPhoneError  : '',
            feedbackNameError   : '',
            loginSession        : loginSession,
            modelAction         : '',
            reviwModel          :true,
            feebackModel        :true,
            qouteModel          :true,
        }
        this.textareaChange = this.textareaChange.bind(this);

    }

    handleChange    = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    textareaChange  (event)  {
        this.setState({ description: event.target.value ,message: event.target.value,feedbackMessage: event.target.value});
    }

    changeHandler = (event) => {
        let file = event.target.files
        for (let i = 0; i < event.target.files.length; i++) {
            let file_size = event.target.files[i].size;
            if(file_size > 10e6){
                event.target.value = null
                this.setState({ imageError: 'Please upload a file smaller than 10 MB'},()=> {
                    this.reviewNow()
                });
                return false;
            }else{
                this.setState({ image: file,imageError: ''},()=>{
                    this.reviewNow()
                });
            }
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            'name'      : this.state.first_name + ' ' + this.state.last_name,
            'email'     : this.state.email,
            'country'   : this.state.country,
            'phone'     : this.state.phone,
            'message'   : this.state.message,
            'deal_id'   : this.state.id,
        }
        this.props.storeQuote(data,'carDetail')
        this.setState({qouteModel:true});

    }

    onSubmitReview  =(e) => {
        e.preventDefault();
        const loginsession = this.state.loginSession
        let data = {
            'title'         : this.state.title,
            'rate'          : this.state.rate,
            'description'   : this.state.description,
            'car_id'        : this.state.id,
            'client_id'     : loginsession.client_id,
            'file'          : this.state.image
        }

        this.props.storeReview(data)
        this.setState({reviwModel:true});

    }

    onSubmitFeedback= (e) => {
        e.preventDefault();
        const loginsession = this.state.loginSession
        const data = {
            'name'       : this.state.feedbackName,
            'subject'    : this.state.feedbackSubject,
            'message'    : this.state.feedbackMessage,
            'phone'      : this.state.feedbackPhone,
            'email'      : this.state.feedbackEmail,
            'car_id'     : this.state.id,
            'client_id'  : loginsession.client_id,
        }

        this.props.storeFeedback(data)
        this.setState({feebackModel:true});

    }

    updateStateData = async ()  => {
        await this.props.getCarDetails(this.state.id);
        window.scrollTo(0, 150);
        this.setState({success : ''});
    }

    onInquireNow    = () => {

        let countrieslist  = this.props.countrieslist
        this.setState({
            modelAction     : 'qoute'
        });
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Get Quote</h4>
                                <a ref={input => this.closeModal = input} onClick={() => { onClose() }} className="close"><i className="ti-close" ></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">First Name:</label>
                                            <input defaultValue={this.state.first_name} name={'first_name'} placeholder={"First Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Last Name:</label>
                                            <input name={'last_name'} placeholder={"Last Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.nameError}</p>
                                        </div>
                                        <div className="form-group">
                                            <alabel className="wo-titleinput">Email:</alabel>
                                            <input defaultValue={this.state.email} name={'email'} placeholder={"Enter your email address."} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.emailError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Phone:</label>
                                            <input defaultValue={this.state.phone} name={'phone'} placeholder={"+1(801)889-3563*."} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.phoneError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Country:</label>
                                            <select name={"country"} onChange={e => this.handleChange(e)} className="wo-titleinput">
                                                {countrieslist.map((obj) => {
                                                    let selected = (266 === obj.label) ? ' selected' : '';

                                                    return <option value={obj.label} >{obj.label}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Message:</label>
                                            <textarea id="message" name="message" onChange={e=>this.textareaChange(e)} className="input-detail  form-control" name="" rows="4" placeholder="Enter your message *"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-success'}>{this.state.success}</p>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <button onClick={(e) => this.onSubmit(e)} className='wo-btn'>Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    reviewNow       = () => {
        this.setState({
            modelAction     : 'review'
        });
        const secondExample = {
            size    : 25,
            count   : 5,
            color   : "black",
            activeColor : "red",
            value       : 7.5,
            a11y        : true,
            isHalf      : true,
            emptyIcon   : <i className="far fa-star" />,
            halfIcon    : <i className="fa fa-star-half-alt" />,
            filledIcon  : <i className="fa fa-star" />,
            onChange: newValue => {
                this.setState({
                    rate: newValue,
                })
            }
        };
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Review</h4>
                                <a ref={input => this.closeReviewModal = input} onClick={() => { onClose()}} className="close"><i className="ti-close" ></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin" enctype="multipart/form-data">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Title:</label>
                                            <input name="title" placeholder={"Title"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.titleError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Rate:</label>
                                            <ReactStars {...secondExample} />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.rateError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Image / Video:</label>
                                            <input name="image[]" placeholder={"file"} id={'image'} multiple onChange={this.changeHandler} type='file' className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Description:</label>
                                            <textarea  id="description" name="description" onChange={e=>this.textareaChange(e)} className="input-detail  form-control" name="" rows="4" placeholder="Enter your description *"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.descriptionError}{this.state.imageError}</p>
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-success'}>{this.state.success}</p>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <button onClick={(e) => this.onSubmitReview(e)} className='wo-btn'>Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    }

    feedbackNow     = () => {
        this.setState({
            modelAction     : 'feedback'
        });
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Feed Back</h4>
                                <a ref={input => this.closeFeedbackModal = input} onClick={() => { onClose() }} className="close"><i className="ti-close" ></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Name:</label>
                                            <input name={'feedbackName'} placeholder={"name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.feedbackNameError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Phone:</label>
                                            <input name={'feedbackPhone'} placeholder={"phone"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.feedbackPhoneError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Subject:</label>
                                            <input name={'feedbackSubject'} placeholder={"subject"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Email:</label>
                                            <input name={'feedbackEmail'} placeholder={"emai"} type='email' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Message:</label>
                                            <textarea  id="feedbackMessage" name="feedbackMessage" onChange={e=>this.textareaChange(e)} className="input-detail  form-control" name="" rows="4" placeholder="Enter your message *"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-success'}>{this.state.success}</p>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <button onClick={(e) => this.onSubmitFeedback(e)} className='wo-btn'>Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    componentWillReceiveProps = (nextProps) =>{
        this.setState(
            {
                ...nextProps.match.params,
                qouteResp       :nextProps.quoteResp,
                reviewResp      :nextProps.reviewResp,
                feedbackResp    :nextProps.feedbackResp,
                loading         :nextProps.cardata.loading,
            },()=>{
                const reviewresp = this.state.reviewResp
                if(reviewresp !== undefined && reviewresp !== '' && this.state.modelAction === "review" && this.state.reviwModel){
                    if(reviewresp.status === true){
                        let reviewPopup = <ToastContainer>
                            {
                                toast.success(reviewresp.message, {
                                    position    : "top-right",
                                    autoClose   : 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable   : true,
                                    progress    : undefined,
                                })
                            }
                        </ToastContainer>;
                        this.setState({
                            success         : reviewPopup,
                            titleError      : '',
                            rateError       : '',
                            descriptionError: '',
                            reviewResp      : '',
                            rate            : '',
                            reviwModel      : false,
                            imageError      : false,
                        },()=> {
                            this.closeReviewModal.click()
                        });

                    }else{
                        if(reviewresp.errors !== undefined){
                            this.setState({
                                success         : '',
                                titleError      : reviewresp.errors.title,
                                rateError       : reviewresp.errors.rate,
                                descriptionError: reviewresp.errors.description,
                            },()=> {
                                this.reviewNow()
                            });
                        }else {
                            this.setState({
                                success         : '',
                                imageError      : reviewresp.message,
                            },()=> {
                                this.reviewNow()
                            });
                        }

                    }
                    this.setState({reviewResp:''})
                }

                const quoteresp = this.state.qouteResp
                if (quoteresp !== undefined && quoteresp !== '' && this.state.modelAction === "qoute" && this.state.qouteModel) {
                    if (quoteresp.status === true) {
                        let quotePopup = <ToastContainer>
                            {
                                toast.success(quoteresp.msg, {
                                    position    : "top-right",
                                    autoClose   : 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable   : true,
                                    progress    : undefined,
                                })
                            }
                        </ToastContainer>;
                        this.setState({qouteModel:false});
                        this.setState({
                            success     :quotePopup,
                            nameError   : '',
                            emailError  : '',
                            phoneError  : '',
                        }, () => {
                            this.closeModal.click()
                            // this.onInquireNow()
                        });

                    } else {
                        this.setState({
                            success: '',
                            nameError   : quoteresp.errors.name,
                            emailError  : quoteresp.errors.email,
                            phoneError  : quoteresp.errors.phone,
                        }, () => {
                            this.onInquireNow()
                        });
                    }
                    this.setState({qouteResp:''})
                }

                const feedbackresp = this.state.feedbackResp
                if(feedbackresp !== undefined && feedbackresp !== '' && this.state.modelAction === "feedback" && this.state.feebackModel){
                    let feedbackPopup = <ToastContainer>
                        {
                            toast.success(feedbackresp.message, {
                                position    : "top-right",
                                autoClose   : 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable   : true,
                                progress    : undefined,
                            })
                        }
                    </ToastContainer>;
                    this.setState({feebackModel:false});
                    if(feedbackresp.status === true){
                        this.setState({
                            success           : feedbackPopup,
                            feedbackNameError : '',
                            feedbackPhoneError: '',
                        },()=> {
                            // this.feedbackNow()
                            this.closeFeedbackModal.click()
                        });

                    }else{
                        this.setState({
                            success           : '',
                            feedbackNameError : feedbackresp.errors.name,
                            feedbackPhoneError: feedbackresp.errors.phone,
                        },()=> {
                            this.feedbackNow()
                        });
                    }
                    this.setState({feedbackResp:''})
                }
            });

        if (nextProps.location.pathname !== this.props.location.pathname) {
            const { id } = nextProps.match.params
            this.setState({
                id: id ,
                isLoggedIn : this.props.loginresp.isLoggedIn
            }, () => {
                this.updateStateData()
            })
        }
    }

    componentDidMount =async () => {
        this.props.getCountries()
        const {id} = this.props.match.params
        this.props.customerReviews(id)
        this.props.customerFeedback(id)

        await this.setState({
            id         : id ,
            isLoggedIn : this.props.loginresp.isLoggedIn,
        }, () => {
            this.updateStateData()
        })
    }

    render() {
        const { data } = this.props.cardata
        let  loading   = this.state.loading

        if (data === null || data === undefined ) {
            this.props.history.push('/pagenotfound')
            return false;
        }
        let customerReviews     = this.props.reviews.data
        let getCustomerFeedback = this.props.getFeedback.data
        let currencyType        = data.currencyType  === 'usd' ? '$' : '¥';
        let dealBreakdownDesc   = '';

        let reviewButton   = "";
        let feedbackButton = "";
        let inquiryButton  = "";
        if(this.state.loginSession.client_id === data.client_id){
            reviewButton   = <li className="wo-btnsarea"><a href="#" onClick={(e) => this.reviewNow(e)}  className="wo-btn">Review</a></li>
            feedbackButton = <li className="wo-btnsarea"><a href="#" onClick={(e) => this.feedbackNow(e)}  className="wo-btn">Feeeback</a></li>
        }else{
            inquiryButton  = <li className="wo-btnsarea"><a href="#" onClick={(e) => this.onInquireNow(e)} className="wo-btn">Get Quote</a></li>
        }

        if (data.bid.attributes.selling_type != undefined) {
            dealBreakdownDesc = data.bid.attributes.selling_type == 'Fob' ? 'Service Charges' : 'Service charges, Inspection, Freight'
        } 

        let metaHelmet = {
            title : data.bid.car_maker.title + " "+data.bid.car_model.title + " "+data.bid.year_month,
            description : 'test'
        }

        return (
            <main className="wo-main overflow-hidden">
                <MetaHelmet {...metaHelmet}/>
                <section className="wo-main-section">
                    {this.state.success}
                    {loading ? <Loader /> :
                        <div className="container">
                            <div className="row">
                                <div id="wo-twocolumns" className="wo-twocolumns">
                                    <div className="col-lg-7">
                                        <div className="wo-vsinglehead">
                                            <div className="wo-vsinglehead__title">

                                                <h2>{data.bid.car_maker.title + " "+data.bid.car_model.title + " "+data.bid.year_month}</h2>
                                            </div>
                                            <ul className="wo-vsinglehead__share d-none">
                                                <li>
                                                    <span><i className="ti-eye"></i>15,063 Viewed</span>
                                                </li>
                                                <li>
                                                    <span><i className="ti-bookmark-alt"></i>ID: 83462234</span>
                                                </li>
                                                <li>
                                                    <span><i className="ti-reload"></i>Add To Compare</span>
                                                </li>
                                                <li>
                                                    <a href="#" className="wo-reportvehicle"><i className="ti-alert"></i>Report now</a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="ti-share"></i>Share Post</a>
                                                </li>
                                            </ul>

                                        </div>
                                        <div>
                                            <RCarousel media={data.bid.media}/>
                                        </div>
                                        {/* <div id="wo-vsingleslider" className="wo-vsingleslider owl-carousel">
                              {loading ? <Loader /> : sliderimages}
                              </div> */}
                                        <ul className="wo-vslider-features">
                                            <li>
                                                <img src="/assets/images/car-detail/car-detail-icon-1.png" alt="img description"/>
                                                <h4>{data.bid.mileage} km</h4>
                                                <span>Total Drived</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/car-detail/car-detail-icon-2.png" alt="img description"/>
                                                <h4>Petrol</h4>
                                                <span>Fuel Type</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/car-detail/car-detail-icon-5.png" alt="img description"/>
                                                <h4>Automatic</h4>
                                                <span>Transmission</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/car-detail/car-detail-icon-4.png" alt="img description"/>
                                                <h4>{data.bid.engine_cc} CC</h4>
                                                <span>Engine Capacity</span>
                                            </li>
                                        </ul>
                                        <div className="wo-vsingledetails">
                                            <div className="wo-vsingledetails__title">
                                                <h3>Vehicle Description</h3>
                                            </div>
                                            <div className="wo-vsingledetails__content">
                                                <div className="wo-description">
                                                    <p>Consectetur adipisicing elit sedaten do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam, quis dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonat proident, sunt
                                                        in culpa qui officia deserunt mollit anim perspiciatis unde.</p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="wo-vsingledetails ">
                                            <div className="wo-vsingledetails__title">
                                                <h3>Car Features</h3>
                                            </div>
                                            <table className="table-detailed-spec carfeature">
                                                <tbody>
                                                    <tr>
                                                        <td className={`td ${data.bid.attributes.cd_player ? 'attached_on' : 'attached_off'}`}>CD Player</td>
                                                        <td className={`td ${data.bid.attributes.sun_roof  ? 'attached_on' : 'attached_off'}`}>Sun Roof</td>
                                                        <td className={`td ${data.bid.attributes.leather_seat  ? 'attached_on' : 'attached_off'}`}>Leather Seat</td>
                                                        <td className={`td ${data.bid.attributes.alloy_wheels  ? 'attached_on' : 'attached_off'}`}>Alloy Wheels</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`td ${data.bid.attributes.power_steering  ? 'attached_on' : 'attached_off'}`}>Power Steering</td>
                                                        <td className={`td ${data.bid.attributes.power_window  ? 'attached_on' : 'attached_off'}`}>Power Window</td>
                                                        <td className={`td ${data.bid.attributes.AC  ? 'attached_on' : 'attached_off'}`}>A/C</td>
                                                        <td className={`td ${data.bid.attributes.ABS  ? 'attached_on' : 'attached_off'}`}>ABS</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`td ${data.bid.attributes.airbag  ? 'attached_on' : 'attached_off'}`}>Airbag</td>
                                                        <td className={`td ${data.bid.attributes.radio  ? 'attached_on' : 'attached_off'}`}>Radio</td>
                                                        <td className={`td ${data.bid.attributes.cd_changer  ? 'attached_on' : 'attached_off'}`}>CD Changer</td>
                                                        <td className={`td ${data.bid.attributes.DVD  ? 'attached_on' : 'attached_off'}`}>DVD</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`td ${data.bid.attributes.TV  ? 'attached_on' : 'attached_off'}`}>TV</td>
                                                        <td className={`td ${data.bid.attributes.power_seat  ? 'attached_on' : 'attached_off'}`}>Power Seat</td>
                                                        <td className={`td ${data.bid.attributes.back_tire  ? 'attached_on' : 'attached_off'}`}>Back Tire</td>
                                                        <td className={`td ${data.bid.attributes.grill_guard  ? 'attached_on' : 'attached_off'}`}>Grill Guard</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`td ${data.bid.attributes.rear_spoiler  ? 'attached_on' : 'attached_off'}`}>Rear Spoiler</td>
                                                        <td className={`td ${data.bid.attributes.central_locking  ? 'attached_on' : 'attached_off'}`}>Central Locking</td>
                                                        <td className={`td ${data.bid.attributes.jack  ? 'attached_on' : 'attached_off'}`}>Jack</td>
                                                        <td className={`td ${data.bid.attributes.spare_tire  ? 'attached_on' : 'attached_off'}`}>Spare Tire</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`td ${data.bid.attributes.wheel_spanner  ? 'attached_on' : 'attached_off'}`}>Wheel Spanner</td>
                                                        <td className={`td ${data.bid.attributes.fog_lights  ? 'attached_on' : 'attached_off'}`}>Fog Lights</td>
                                                        <td className={`td ${data.bid.attributes.back_camera  ? 'attached_on' : 'attached_off'}`}>Back Camera</td>
                                                        <td className={`td ${data.bid.attributes.push_start  ? 'attached_on' : 'attached_off'}`}>Push Start</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`td ${data.bid.attributes.keyless_entry  ? 'attached_on' : 'attached_off'}`}>Keyless Entry</td>
                                                        <td className={`td ${data.bid.attributes.ESC  ? 'attached_on' : 'attached_off'}`}>ESC</td>
                                                        <td className={`td ${data.bid.attributes.degree_camera  ? 'attached_on' : 'attached_off'}`}>360 Degree Camera</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="wo-vsingledetails wo-mhistory d-none">
                                            <div className="wo-vsingledetails__title">
                                                <h3>Maintenance History</h3>
                                            </div>
                                            <div className="wo-vsingledetails__content">
                                                <div className="wo-description">
                                                    <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore etnali dolore magna aliqua enim ad minim veniam, irure dolor in reprehenderit in voluptate velit esse cillum dolore eutae fugiat nulla pariatur.
                                                        Excepteur <a href="#">sint occaecat</a> cupidatat.</p>
                                                    <img src="/assets/images/vehicle-single/img-01.jpg" alt="img description"/>
                                                    <p>Proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque ladantiume totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                                        Nemo enim ipsam voluptatem quia voluptas.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <aside id="wo-sidebar" className="wo-sidebar">
                                            <div className="wo-sidebarprice">
                                                <div className="wo-sidebarprice__title">
                                                    <h3>Vehicle Asking Price</h3>
                                                </div>
                                                <div className="wo-sidebarprice__content">
                                                    <div className="wo-priceinfo">
                                                        <div className="wo-vehicles-head">
                                                            <div className="wo-vehicles__title" id="customlist">
                                                                <p className="label-price">Price</p>
                                                                <p className="vehicle-price"><span className="currency-label"></span><span className="price"><NumberFormat value={data.bid.attributes.selling_price ?? data.clientTotal} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={currencyType} /></span></p>
                                                                <div className="line"></div>
                                                                <p className="label-total-price">Total Price</p>
                                                                <p className="total-price">
                                                                    <span className="currency-label"></span><span className="price"><NumberFormat value={data.bid.attributes.selling_price ?? data.clientTotal} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={currencyType} /></span></p>
                                                                <div className="destination-wrap">
                                                                    <p className="price-condition">
                                                                        {data.bid.attributes.selling_type != undefined ? data.bid.attributes.selling_type.toUpperCase() : '--'} to <strong>{ data.logistic_info.destination_port.title ?? '-'}</strong>
                                                                        <br/>
                                                                         {data.bid.attributes.isInspectionPassed ? 'Inspection Passed : Yes' : ''}
                                                                        </p>
                                                                        <small>{dealBreakdownDesc} (inclusive)</small>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className="wo-dealerinfo">
                                                        <ul>
                                                            <li>
                                                                <span><a href="https://api.whatsapp.com/send?phone=+818098746969&text=Hi%20!!" target="_blank"><i className="fab fa-whatsapp"></i> Whatsapp Chat</a></span>
                                                            </li>
                                                            <li>
                                                                <span><a href=""><i className="far fa-comment-dots"></i>   Chat with Expert</a></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="wo-socialcontact">
                                                        <ul>
                                                            {inquiryButton}
                                                            {reviewButton}
                                                            {feedbackButton}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wo-vsingledetails mt-4">
                                                <div className="wo-vsingledetails__title">
                                                    <h3>Vehicle Main Features</h3>
                                                </div>
                                                <div className="wo-vsingledetails__content">
                                                    <div className="wo-featureslist">
                                                        <ul>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Chasis-icon.png" alt="img description"/> Chassis :</strong><span>{data.bid.chassis_no}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Stock-icon.png" alt="img description"/> Stock ID :</strong><span>{data.serial}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Body-icon.png" alt="img description"/> Body :</strong><span>{data.bid.attributes.bid_type ?? '-'}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Engine-icon.png" alt="img description"/>Engine :</strong><span>{data.bid.engine_cc}CC</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Mileage-icon.png" alt="img description"/> Mileage :</strong><span>{data.bid.mileage}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Trans-icon.png" alt="img description"/> Trans :</strong><span>{data.bid.attributes.transmission}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Door-icon.png" alt="img description"/> Door :</strong><span>{data.bid.attributes.doors}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Drive-icon.png" alt="img description"/> Drive :</strong><span>{data.bid.attributes.drive}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail//Steering-icon.png" alt="img description"/> Steering :</strong><span>{data.bid.attributes.steering}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Color-icon.png" alt="img description"/> Color :</strong><span>{data.bid.car_color.title}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Fuel-icon.png" alt="img description"/> Fuel :</strong><span>{data.bid.attributes.fuel}</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="wo-featureslistcontent">
                                                                    <strong><img src="/assets/images/car-detail/Year-icon.png" alt="img description"/> Year :</strong><span>{data.bid.year_month}</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="wo-singlelikepost">
                                        <div className="wo-singlelikepost__title">
                                            <h3>Similar And Related Vehicles</h3>
                                        </div>
                                        <div className="wo-singlelikepost__content">
                                            <div className="row">
                                                <Similar cardata={data}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12" >
                                    <div className="tittles typ">
                                        <h2><a href="">Customer Reviews and Feedback</a></h2>
                                        <span className="float-right"> <Link to={'/reviews'} >Show All! </Link></span>
                                    </div>
                                    <ReviewList   data={customerReviews} onClick={(e) => this.onReviewShow(e)}/>
                                    <FeedbackList data={getCustomerFeedback} />
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </main>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCarDetails   : (id) => dispatch(getCarDetails(id)),
    getCountries    : ()   => dispatch(getCountries()),
    storeQuote      : (jsondata,type) => dispatch(storeQuoteAction(jsondata,type)),
    storeReview     : (jsondata) => dispatch(storeReview(jsondata)),
    storeFeedback   : (jsondata) => dispatch(storeFeedback(jsondata)),
    customerReviews : (id)       => dispatch(getcustomerReview({'car_id':id},3)),
    customerFeedback: (id)       => dispatch(getcustomerFeedback(id))
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    cardata     : state.dealReducer.cardetails,
    quoteResp   : state.dealReducer.quoteresp,
    reviewResp  : state.customerReducer.reviewresp,
    feedbackResp: state.customerReducer.feedbackresp,
    reviews     : state.customerReducer.customerReviews,
    getFeedback : state.customerReducer.customerFeedback,
    Resp        : state.dealReducer.quoteresp,
    countrieslist:state.dealReducer.countries,
    loginresp   : state.customerReducer.loginresp
})

export default connect(mapStateToProps, mapDispatchToProps)(CarDetails);
