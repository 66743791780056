import React , {Component} from 'react'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export default class App extends Component {
  //other logic
  render() {
    return (
      <Loader
        type="Oval"
        color="#ce3c30"
        height={100}
        width={100}
        timeout={10000} //3 secs
      />
    );
  }
}