import { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { getNewArrival } from "../../actions/dealAction"
import DealItem from "../partials/Listings/items/DealItem"
import Loader from "../../components/partials/Loader"

export class NewArrival extends Component {

    
    componentDidMount() {
        // common.appendScript('./assets/js/pages/topnew.js')
        // common.appendScript('./assets/js/pages/brandslider.js')
        // this.props.getDeals(this.props.match.params.name);
        this.props.getNewArrival();
    }
    render() {
        const { data, meta, loading } = this.props.newarrivals
        let newarrival = []
        if(data.length != 1){
            data.map((deal,key) => (
                newarrival.push(
                    <div className="col-md-3" key={key}>
                        <DealItem deal={deal} />
                    </div>
                )
            ))
        }else{
            newarrival.push(
                <div className="col-md-3">
                    <Loader/>
                </div>
            )
        }


        return (
            <>
                {newarrival}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getNewArrival : () => dispatch(getNewArrival()),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        newarrivals : state.homeReducer.newarrivals,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewArrival);
