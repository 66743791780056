import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Search from '../components/layouts/Search';
import Layout from '../components/user/Layout';
import Login from './user/Login';
import PageNotFound404  from '../components/layouts/errors/PageNotFound404'
import HomeBase from '../components/home/HomeBase'
import CarDetails from './partials/CarDetails';
import {BrowserRouter as Router,Switch, Route, Redirect} from 'react-router-dom';
import history from '../history'
import '../App.css';
import BankDetails from "./pages/BankDetails";
import SaleProcess from "./pages/SaleProcess";
import Faqs from "./pages/Faqs";
import AuctionSystem from "./pages/AuctionSystem";
import AutoParts from "./pages/AutoParts";
import PrivateRoute from './partials/PrivateRoute';
import About from "./pages/About";
// import Stock from "./pages/Stock";
import Reviews from './pages/Reviews';
import Register from "./user/Register";
import OTP from "./user/OTP";
import ShippingSchedule from "./pages/ShippingSchedule";
import EmailVerify from './user/EmailVerify';
import ClientReview from "./pages/ClientReview";
// import {Register} from "./user/Register";

import ReactGA from 'react-ga'
import TrustedClient from "./pages/TrustedClient";
// import AppConfig from '../App.config'

// ReactGa.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({

})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state,
})

/**
 * @class App
 * @extends {Component}
 */
class App extends Component {
  /**
   * @memberof App
   * @summary handles button click 
   */
  // testAction = (event) => {
  //   this.props.testAction();
  // }

  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  componentWillReceiveProps(){
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    return (
      <div className="App">
        <Router basename={process.env.PUBLIC_URL} history={history}>
        <Header />
        <Switch>
        {/* <Route exact path="/" component={Main}/> */}
        <Route exact path="/" component={HomeBase} />
        <Route exact path="/pagenotfound" component={PageNotFound404}/>
        <Route path="/car-details/:id" component={CarDetails}/>

        {/* <Route exact path="/new-cars" render={(props) => (
            <Search type={'new-cars'}/>
        )} />
        <Route exact path="/motorbikes" render={(props) => (
            <Search type={'motorbikes'}/>
        )} />
        <Route exact path="/spare-parts" render={(props) => (
            <Search type={'spare-parts'}/>
        )} /> */}
        <Route exact path="/auction-system" render={(props) => (
            <AuctionSystem type={'auction-system'}/>
        )} />
        <Route exact path="/auto-parts" render={(props) => (
            <AutoParts type={'auto-parts'}/>
        )} />
        <Route exact path="/how-to-buy" render={(props) => (
            <BankDetails type={'how-to-buy'}/>
        )} />
        <Route exact path="/sale-process" render={(props) => (
            <SaleProcess type={'sale-process'}/>
        )} />
        <Route exact path="/about" render={(props) => (
            <About type={'about'}/>
        )} />
        <Route exact path="/reviews" render={(props) => (
            <Reviews type={'reviews'}/>
        )} />
        <Route exact path="/trusted-client" render={(props) => (
            <TrustedClient type={'trusted-client'}/>
        )} />
        <Route exact path="/stock" render={(props) => (
            <Redirect to='/category/toyota'/>
            // <Stock type={'stock'}/>
        )} />
        <Route exact path="/faqs" render={(props) => (
            <Faqs type={'faqs'}/>
        )} />
         <Route path="/brand/:name" render={() => (
            <Search type={'brand'}/>
        )} />
        <Route path="/search/:name" render={() => (
            <Search type={'brand'}/>
        )} />
        {/* <Route exact path="/listing" render={() => (
            <Search type={'listing'}/>
            )} /> */}
        <Route exact path="/country/:name" render={(props) => (
            <Search type={'country'} {...props}/>
        )} />
        <Route exact path="/prices/:name" render={(props) => (
            <Search type={'prices'} {...props}/>
        )} />
        <Route exact path="/main-search/:maker?/:sort?/:type?/:prices?/:model?/:year?/:steering_type?/:fuel_type?/:transmission?/:mileage?/:engine_cc?/:cd_player?/:sun_roof?/:leather_seat?/:alloy_wheels?/:power_steering?/:power_window?/:ac?/:abs?/:air_bag?/:radio?/:cd_changer?/:dvd?/:tv?/:power_seat?/:back_tire?/:grill_guard?/:rear_spoiler?/:central_locking?/:jack?/:spare_tire?/:degree_camera?/:wheel_spanner?/:back_camera?/:push_start?/:keyless_entry?/:esc?/:limit?/:page?" render={(props) => (
            <Search type={'main-search'} {...props}/>
        )} />

        <Route exact path="/keyword/:search?/:type?/:limit?/:page?" render={(props) => (
            <Search type={'keysearch'} {...props}/>
        )} />

        <Route exact path="/client-review/:car_id?/:client_id?" render={(props) => (
            <ClientReview {...props} />
        )} />
        <Route exact path="/bid-type/:search?/:limit?/:page?" render={(props) => (
            <Search type={'bid-type'} {...props}/>
        )} />

        <Route exact path="/category/:name" render={(props) => (
            <Search type={'category'} {...props}/>
        )} />
        <Route exact path="/used-car/:search?/:type?/:limit?/:page?" render={(props) => (
            <Search type={'used_car'} {...props}/>
        )} />

        <Route exact path="/login" component={Login}/>

        {/*temporary register link commented */}
        {/*<Route exact path="/register" render={(props) => (*/}
        {/*    <Register type={'register'}/>*/}
        {/*)} />*/}

        <Route exact path="/otp" render={(props) => (
            <OTP />
        )} />
        <Route path="/email/verify/:id" render={(props) => (
            <EmailVerify type={'emailverification'} {...props}/>
        )} />

        <Route exact path="/shipping-schedule" render={(props) => (
            <ShippingSchedule type={'shipping-schedule'}/>
        )} />
        <PrivateRoute exact path="/user/:type" component={Layout}/>
        <Redirect to="/pagenotfound" />
        </Switch>
        <Footer />
        </Router>
        {/*<Navbar />*/}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);