import React, { Component } from 'react'
import common from "../../../../helpers/common"
import { Link } from "react-router-dom"
import NumberFormat from 'react-number-format'

export class DealItem extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        // common.appendScript('./assets/js/pages/topnew.js')
    }
    render() {
        const { deal } = this.props
        let baseImageUrl = process.env.REACT_APP_BASE_IMG;
        let image = ''
        let currencyType = deal.currencyType  === 'usd' ? '$' : '¥';
        if(deal.bid.media.length > 0){
            image = <div className="item">
                    <figure className="wo-vehicles-img dealitem1">
                        <img src={baseImageUrl+common.imageFullPath(deal.bid.media[0])}
                             alt={deal.bid.car_maker.title + " "+deal.bid.year_month}/>
                    </figure>
                </div>
            
            // image = deal.bid.media.map(media =>
            //     <div className="item" key={media.id}>
            //         <figure className="wo-vehicles-img dealitem1">
            //             <img src={baseImageUrl+common.imageFullPath(media)}
            //                  alt={deal.bid.car_maker.title + " "+deal.bid.year_month}/>
            //         </figure>
            //     </div>
            // )
        }else{
            image = <div className="item">
                <figure className="wo-vehicles-img dealitem1">
                    <img src="https://www.carfitexperts.com/car-models/wp-content/uploads/2019/01/zen-1.jpg"
                         alt="no image available"/>
                </figure>
            </div>
        }
        return (
            <Link to={'/car-details/'+deal.id+'/'+deal.bid.car_maker.title +'-'+ deal.bid.car_model.title +'-'+ deal.bid.year_month}>
            <div className="wo-topvehicles ">
                   {image}
                <div className="wo-vehicles">
                    <div className="wo-vehicles__title">
                    <h5><Link to={'/car-details/'+deal.id+'/'+deal.bid.car_maker.title +'-'+ deal.bid.car_model.title +'-'+ deal.bid.year_month}>{deal.bid.car_maker.title} {deal.bid.car_model.title} - {deal.bid.year_month}</Link><span>
                    <NumberFormat value={deal.bid.attributes.selling_price ?? deal.clientTotal} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={currencyType} /></span></h5>
                    </div> 
                </div>
            </div>
            </Link>
        )
    }
}


export default DealItem
