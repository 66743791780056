import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import common from '../../helpers/common'

class RCarousel extends Component {
    
    // constructor(props){
    //     super(props)
    // }
    componentDidMount(){
        // let media = this.props.media;
    }
    render() {
        let baseImageUrl = process.env.REACT_APP_BASE_IMG;
        const {media} = this.props;
        let sliderimages = [];
        if(media.length > 0){
            media.map(media =>
                sliderimages.push(
                    <div key={media.id} className='RCarouselItem'>
                        <img src={baseImageUrl+common.imageFullPath(media)}/>
                    </div>
                )
            )
        }else{
            sliderimages = 
            <div className='RCarouselItem'>
                <img src="https://www.carfitexperts.com/car-models/wp-content/uploads/2019/01/zen-1.jpg"/>
            </div>
        }
        return (
            <Carousel>
               {sliderimages}
            </Carousel>
        );
    }
}

export default RCarousel