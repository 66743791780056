import { React, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCountryCount, getMakerCount } from "../../../actions/homeAction"

function Brands(props) {
    function makerLogo(name) {
        switch (name) {
            case 'Antigua And Barbuda':
                return 'icn-barbuda';
            case 'Aruba':
                return 'icn-aruba';
            case 'Bahamas The':
                return 'icn-bahamas';
            case 'Barbados':
                return 'icn-barbados';
            case 'Botswana':
                return 'icn-botswana';
            case 'British Indian Ocean Territory':
                return 'icn-british';
            case 'Burundi':
                return 'icn-burundi';
            case 'C-The Democratic Republic Of The':
                return 'icn-democratic';
            case 'Cayman Islands':
                return 'icn-cayman';
            case 'Comoros':
                return 'icn-comoros';
            case 'Congo':
                return 'icn-congo';
            case 'Cyprus':
                return 'icn-cyprus';
            case 'Dominican Republic':
                return 'icn-dominican';
            case 'Fiji Islands':
                return 'icn-fiji';
            case 'Grenada':
                return 'icn-grenada';
            case 'Guyana':
                return 'icn-guyana';
            case 'Jamaica':
                return 'icn-jamaica';
            case 'Japan':
                return 'icn-japan';
            case 'Kenya':
                return 'icn-kenya';
            case 'Korea North':
                return 'icn-korea';
            case 'Malawi':
                return 'icn-malawi';
            case 'Mauritius':
                return 'icn-mauritius';
            case 'Micronesia':
                return 'icn-micronesia';
            case 'Mozambique':
                return 'icn-mozambique';
            case 'New Zealand':
                return 'icn-newzealand';
            case 'None':
                return 'icn-none';
            case 'Palau':
                return 'icn-palau';
            case 'Saint Kitts And Nevis':
                return 'icn-saint';
            case 'Saint Lucia':
                return 'icn-lucia';
            case 'Saint Vincent And The Grenadines':
                return 'icn-grenadines';
            case 'Samoa':
                return 'icn-samoa';
            case 'South Sudan':
                return 'icn-sudan';
            case 'Suriname':
                return 'icn-suriname';
            case 'Tanzania':
                return 'icn-tanzania';
            case 'Tonga':
                return 'icn-tonga';
            case 'Trinidad And Tobago':
                return 'icn-trinidad';
            case 'Uganda':
                return 'icn-uganda';
            case 'United Arab Emirates':
                return 'icn-uae';
            case 'United Kingdom':
                return 'icn-kingdom';
            case 'United States':
                return 'icn-us';
            case 'Virgin Islands (British)':
                return 'icn-virgin';
            case 'Zambia':
                return 'icn-zambia';
            case 'Zimbabwe':
                return 'icn-zimbabwe';
            default:
                return '';
        }
    }

    useEffect(() => {
        props.getCountryCount();
    }, [])

    const countryRes = props.countryRes
    let countryCount = [];
    countryRes.map((category, key) =>
        countryCount.push(
            <li key={key}><Link to={"/country/" + category.name.toLowerCase()}><i className={makerLogo(category.name)}></i><span>{category.name}</span><em>({category.deals})</em></Link></li>
        ),
    );
    return (
        <div className="wo-widget" id="right-side">
            <div className="wo-widget__title wo-collapse-title" role="button" id="title2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true">
                <h2>By Country:</h2>
            </div>
            <div className="collapse show" id="collapse3" aria-labelledby="title2">
                <div className="wo-widget__content wo-widget-categories">
                    <ul>
                        {countryCount}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    getCountryCount: () => dispatch(getCountryCount())
    // getMainSearch   : (jsondata,page,limit) => dispatch(getMainSearch(jsondata,page,limit))

})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        countryRes: state.homeReducer.countryRes
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
