import {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { countries } from '../../data/countries'
// import common from '../../helpers/common';
import MetaHelmet from '../partials/widgets/MetaHelmet';

class About extends Component{
    componentDidMount() {

    }
    render() {
      let metaHelmet = {
        title : 'About Us',
        description : 'CFBA Japan, based in Japan, is a highly acclaimed and famous exporter of Japanese used cars.'
      }
        return (
          <>
          <MetaHelmet {...metaHelmet}/>
              <div className="container">
              <div className="col-md-12 col-sm-12 col-xs-12 main-about">
                <h3>Japanese Used Cars For Sale At CFBA JAPAN</h3>
                <p>CFBA Japan, based in Japan, is a highly acclaimed and famous exporter of Japanese used cars.</p>
                <p>We maintain used vehicle stocks of Toyota, Nissan, Mitsubishi, Mazda, Honda and other major Japanese and non-Japanese car manufacturers to furnish the demands of our customers.</p>
                <p>In order to cater to the needs of buyers, we also provide cars on-demand in case that particular vehicle is somehow not available in our stock.</p>
                <p>CFBA Japan has expanded over the years and our product line has different types of cars which include Sedans, Hatchbacks, SUVs, Vans, Wagons, Buses and Trucks. All of our used vehicles are purchased from major Japanese auction houses and maintained by professional technical staff to ensure quality.</p>
                <p>Our customer representatives are available on working hours for your assistance. They provide you the necessary help required in selecting and purchasing your dream car within your budget. You can reach our customer representatives by phone, online chat or email to inform them about your questions which they will be glad to answer as quickly as possible.</p>
                <p>You can generate a Pro-forma invoice of your selected vehicle in a couple of minutes and you will receive a copy of the invoice in your email. You can send payment to our designated banks in US Japanese Yen. Once payment is cleared from the customer we will start shipping process and try to deliver your car as soon as possible. You will get notifications from time to time about your shipment status and other vehicle details through our excellent customer support and after sales services.</p>
            </div>
              </div>
          </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state,
})

export default connect(mapStateToProps, mapDispatchToProps)(About);
