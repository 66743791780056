import { dealConstants } from '../constants/index'
import axiosConfig from '../axiosConfig'
import common from '../../src/helpers/common'

const getDeals = () => async dispatch => {
    
    try{
        const res = await axiosConfig.get(`/deals/view`)
        dispatch( {
            type: dealConstants.LIST,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }

}

const getMainSearch = (jsonobj,page =1,limit = 15) => async dispatch => {
    try{
        // console.log(jsonobj)
        let queryString = common.objToQueryString(jsonobj);
        let url = `/search/main-search?`+queryString+`&limit=`+limit +`&page=`+page
        const res = await axiosConfig.get(url)
        // const res = await axiosConfig.get(`/search/main-search?maker=`+maker+`&type=`+type +`&prices=`+prices +`&model=`+model +`&year=`+year +`&limit=`+limit +`&page=`+page)
        dispatch( {
            type: dealConstants.SEARCH,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }
}
const keysearchUrlRes = (res) => async dispatch => {
    try{
       dispatch( {
            type: dealConstants.KEY_SEARCH_URL_RES,
            payload: res
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.KEY_SEARCH_URL_RES_ERROR,
            payload: console.log(e),
        })
    }
}
const keywordSearch = (jsonobj) => async dispatch => {
    try{
        let url = `/search/keyword-search?`+`search=`+jsonobj
        const res = await axiosConfig.get(url)
        dispatch( {
            type: dealConstants.KEYWORD_SEARCH,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.KEYWORD_SEARCH_ERROR,
            payload: console.log(e),
        })
    }
}

const getKeywordSearch = (search,type,sort,page =1,limit = 15) => async dispatch => {
    try{
        let url = `/search/getkeyword-search?`+`search=`+search+`&type=`+type+`&sort=`+sort+`&limit=`+limit +`&page=`+page
        const res = await axiosConfig.get(url)
        dispatch( {
            type: dealConstants.GET_KEYWORD_SEARCH,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.GET_KEYWORD_SEARCH_ERROR,
            payload: console.log(e),
        })
    }
}

const serachByType = () => async dispatch => {
    try{
        const res = await axiosConfig.get(`/search/by-type`)
        dispatch( {
            type: dealConstants.TYPE_SEARCH,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.TYPE_SEARCH_ERROR,
            payload: console.log(e),
        })
    }
}

const serachUsedCar = () => async dispatch => {
    try{
        const res = await axiosConfig.get(`/search/used-car`)
        dispatch( {
            type: dealConstants.USED_CAR_SEARCH,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.USED_CAR_SEARCH_ERROR,
            payload: console.log(e),
        })
    }
}

const getSerachUsedCar = () => async dispatch => {
    try{
        const res = await axiosConfig.get(`/search/used-car`)
        dispatch( {
            type: dealConstants.GET_USED_CAR_SEARCH,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.GET_USED_CAR_SEARCH_ERROR,
            payload: console.log(e),
        })
    }
}

export {getDeals,getMainSearch,keywordSearch,getKeywordSearch,keysearchUrlRes,serachByType,serachUsedCar,getSerachUsedCar};