import React, { Component } from 'react';
import { connect } from 'react-redux';
// import common from '../../helpers/common';
import { getcustomerReview } from '../../actions/customerAction'
import ReviewList from '../partials/ReviewList';
import MetaHelmet from '../partials/widgets/MetaHelmet';
import {getTrustedClient} from "../../actions/commonAction";
import {Carousel} from "react-responsive-carousel";
import ReactStars from "react-rating-stars-component";

class TrustedClient extends Component {

    componentWillReceiveProps(nextProps) {
        // this.props.customerReviews(0)
    }
    componentDidMount() {
        this.props.getTrustedClient()
    }
    render() {

        let metaHelmet = {
            title: `Trusted Client Reviews`,
            description: ''
        }
        let customerReviews  = this.props.trustedClientRes

        let empty = '';
        if(!this.props.trustedClientRes.status){
            empty = 'No Record Found!'
        }

        let reviewList      = []
        customerReviews.data.map((review,key) => {
            if(review.fullname !== ''){
                const firstExample = {
                    size: 30,
                    value: review.rate,
                    edit: false,
                    activeColor: 'red',
                };
                reviewList.push(

                    <div className="col-md-6" key={key}>
                        <div className="review-block">
                            <div className="row">
                                <div className="col-sm-12">
                                    {/* <div className="text-dark">Reviews</div> */}
                                    <div className="review-block-rate">
                                        <ReactStars {...firstExample} />
                                    </div>
                                    <span className="reviewer-name">by {review.fullname} ({review.country.name}) <br/> {review.created_at}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            }
        })
        return (
            <div className="col-8 offset-2">
                <MetaHelmet {...metaHelmet} />
                <div className="tittles typ">
                    <h2><a href="">Trusted Client Reviews</a></h2>
                </div>
                <div className="row">
                    {reviewList}
                    {empty}
                </div>

            </div>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    getTrustedClient: () => dispatch(getTrustedClient()),
})

/*
* mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    trustedClientRes: state.customerReducer.trustedClientRes,
})

export default connect(mapStateToProps, mapDispatchToProps)(TrustedClient);