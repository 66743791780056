import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { countries } from '../../data/countries'
// import common from '../../helpers/common';
import MetaHelmet from '../partials/widgets/MetaHelmet';

class AutoParts extends Component {
    componentDidMount() {
        // common.appendScript('assets/js/main.js')
    }
    render() {
        let metaHelmet = {
            title: `Auto Parts`,
            description: ''
          }
        return (
            <>
            <MetaHelmet {...metaHelmet} />
                <div className="container">
                    <div className="col-md-12 halfcut ">
                        <h3>Half Cut</h3>
                        <ul>
                            <li>20 feet half cut.</li>
                            <li>40 feet container.</li>
                            <li>Car will be  picked as per customer will and price quoted</li>
                            <li>Service charges of our company will be implemented as  per normal quoted</li>
                            <li>Inland Transportation charges : Depend on distance </li>
                            <li>Cutting charges</li>
                            <li>Vanning charges depend on number of units</li>
                            <li>Containers shipments charges (20/40 feet's) : Depend on destination port</li>
                        </ul>
                        <div className="row">

                            <div className="col-md-4 imgcut">
                                <img src="/assets/images/innerpages/car-cutting-4.jpg"></img>
                            </div>
                            <div className="col-md-4 imgcut">
                                <img src="/assets/images/innerpages/auto-car.jpg"></img>
                            </div>
                            <div className="col-md-4 imgcut">
                                <img src="/assets/images/innerpages/auto-part-12.jpg"></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 nosecut ">
                        <h3>NOSE CUT</h3>
                        <ul>
                            <li>20 feet container.</li>
                            <li>40 feet container.</li>
                            <li>Car will be picked as per customer will and price quoted</li>
                            <li>Service Charges of our company will be implemented as per normal quoted</li>
                            <li>Inland Transportation Charges depend on distance from auction house to yard</li>
                            <li>Dismantling Charges depend on Unit (car/truck)</li>
                            <li>Vanning Charges depend on Unit (car/truck)</li>
                            <li>Container Shipment Charges (20/40 feet's) depend on destination port.</li>
                        </ul>
                        <div className="row">

                            <div className="col-md-4 imgcut">
                                <img src="/assets/images/innerpages/car-parts-3.jpg"></img>
                            </div>
                            <div className="col-md-4 imgcut">
                                <img src="/assets/images/innerpages/auto-part-15.jpg"></img>
                            </div>
                            <div className="col-md-4 imgcut">
                                <img src="/assets/images/innerpages/car-auto-parts-2-2.jpg"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({

})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoParts);