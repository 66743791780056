import React, { Component, useCallback } from 'react'
// import Select from "react-dropdown-select";
import { connect } from "react-redux";
import { getCountry, updateProfileAction, getDashboardCount } from "../../actions/customerAction";
import { withRouter } from "react-router";
import { getCarDetails } from '../../actions/dealAction';
import Similar from '../partials/Similar'
export class Dashboard extends Component {
    constructor(props) {
        super(props)
        let loginSession = this.props.loginresp //JSON.parse(localStorage.getItem('login-session'));

        this.state = {
            id: 1025,
            shipped_count : '',
            not_shipped_count : '',
            client_id : loginSession.client_id
        }
    }

    componentDidMount() {
        
        this.props.getDashboardCount(this.state.client_id)
        this.props.getCarDetails(this.state.id);
        
    }

    componentWillReceiveProps(nextProps) {

    }

    render() {
        const { cardetails, loading } = this.props.cardata
        let data = cardetails.data
        let dashboard = this.props.dashboardCount

        return (
            <>
                <div className="col-lg-8 col-md-12">
                    <div className="wo-settingsave">
                        <div className="wo-settingsave__title">
                            <h2>Dashboard!</h2>
                        </div>
                    </div>
                    <div className="wo-singlelikepost__content">
                        <div className="row">
                            <div class="wo-stats__content">
                                {/* <img src="images/my-ads/stats/img-01.jpg" alt="Image Description" /> */}
                                <div class="">
                                    <h6>{dashboard.shipped} Shipped</h6>
                                    {/* <p>No. of Vehicles Posted</p> */}
                                </div>
                            </div>
                            <div class="wo-stats__content">
                                {/* <img src="images/my-ads/stats/img-01.jpg" alt="Image Description" /> */}
                                <div class="wo-stats__title">
                                    <h6>{dashboard.notShipped} Not-Shipped</h6>
                                    {/* <p>No. of Vehicles Posted</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-12">
                                    <div className="wo-singlelikepost">
                                        <div className="wo-singlelikepost__title">
                                            <h3>Similar And Related Vehicles</h3>
                                        </div>
                                        <div className="wo-singlelikepost__content">
                                            <div className="row">
                                                <Similar cardata={data}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-sm-12 col-md-12 col-lg-2 ">
                    <div className="detaildashbord">
                        <p>Final Country : <b>US</b></p>
                        <p>Port / City : <b>CIF  Savannah</b></p>
                        <p><i className="fas fa-exclamation-triangle"></i>  For USA, you can only import vehicles registered in 1996 or ordee</p>
                        <p>Find similar models that meet USA regulation ></p>
                        <p>To buy this vehicle for a different country, please request for another quotation</p>
                    </div>
                </div> */}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCarDetails: (id) => dispatch(getCarDetails(id)),
    getDashboardCount : (client_id) => dispatch(getDashboardCount(client_id))

})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    cardata: state.dealReducer,
    dashboardCount : state.customerReducer.dashboardCount,
    loginresp : state.customerReducer.loginresp,

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
