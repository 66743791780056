import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './components/App'

import { Provider } from 'react-redux';
import configureStore from './store';
import history from '../src/history'
// import {BrowserRouter as Router,Switch, Route} from 'react-router-dom';

ReactDOM.render(
  <Provider store={configureStore()} history={history}>
    <App />
  {/* <Router>
  <App />
  <Switch>
  <Route exact path="/" component={App}/>
  <Route path="/counter" component={Counter}/>
  <Route path="/search" component={Search}/>
  </Switch>
  </Router> */}
  </Provider>,
  
  document.getElementById('root')
  );
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

  