import {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { countries } from '../../data/countries'
// import common from '../../helpers/common';
import MetaHelmet from '../partials/widgets/MetaHelmet';

class AuctionSystem extends Component{
    componentDidMount() {
        // common.appendScript('assets/js/main.js')
    }
    render() {
        let metaHelmet = {
            title: `Auction System`,
            description: ''
          }
        return (
          <>
            <MetaHelmet {...metaHelmet} />
              <div className="container">
              <div className="col-md-12 col-sm-12 col-xs-12 bank-detail">
              <h3>Japanese Used Cars Auction</h3>
              <p>When you become a registered member of CFBA Japan .You will get access to more than 100 auctions, and can choose your favorite car from stock available every day. You can find a car that you like, view its details, and even place a bid on a car from your home or office without going anywhere.</p>
              <h4>Start Your Car Business With CFBA Japan In 7 Easy Steps To Participate In All Auctions.</h4>
                </div>
                  <div className="wo-widget" id="faq">
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse1" aria-expanded="true">
                            <h2>Step-1- Registration:</h2>
                        </div>
                        <div className="collapse show" id="collapse1" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>Register to become a member of CFBA Japan and get access to all the cars available at multiple auction houses in Japan.</li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse2" aria-expanded="false">
                            <h2>Step-2- Deposit :</h2>
                        </div>
                        <div className="collapse" id="collapse2" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>You need to submit the initial amount of money before you participate in car
                                        auctions. (See below). Without the deposit, we cannot bid on any of the cars at
                                        the auctions in place of you. If bid is not successful, initial amount will be
                                        fully refunded or allocated to the payment for reserved/ordered car(s) based on
                                        the customer's request.
                                    </li>

                                </ul>
                                <div className="verticle">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Packages</th>
                                            <th scope="col">Deposit Amount -USD</th>
                                            <th scope="col">Buying Budget -USD</th>
                                            <th scope="col">Deposit Amount -JPY</th>
                                            <th scope="col">Buying Budget -JPY</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th scope="row">Bronze</th>
                                            <td>$1,000</td>
                                            <td>$10,000</td>
                                            <td>@¥100,000</td>
                                            <td>¥1,000,000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Silver</th>
                                            <td>$3,000</td>
                                            <td>$30,000</td>
                                            <td>¥300,000</td>
                                            <td>¥3,000,000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Gold</th>
                                            <td>$5,000</td>
                                            <td>$50,000</td>
                                            <td>¥500,000</td>
                                            <td>¥5,000,000</td>
                                        </tr>

                                        <tr>
                                            <th scope="row">Premium</th>
                                            <td>$10,000</td>
                                            <td>$100,000</td>
                                            <td>¥1,000,000</td>
                                            <td>¥10,000,000</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse3" aria-expanded="false">
                            <h2>Step-3- Bidding Cars At The Auctions :</h2>
                        </div>
                        <div className="collapse" id="collapse3" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>You can place your bid on selected cars at CFBA Japan, and our staff will bid on your selected cars in place of you.
                                    </li>
                                    <li>We will charge you only the price of the successful bid. So try to place as high bid as you can in order to increase the chance of successful bid.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse4" aria-expanded="false">
                            <h2>Step-4- Bidding Cars At The Auctions :</h2>
                        </div>
                        <div className="collapse" id="collapse4" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>We will notify result of your bid. Once you win the bid, we will send you a CFBA Japan invoice that tells you the total price including the price of car(s) and FOB or C&F. The total price will be calculated based on the original buying price at the auction.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse5" aria-expanded="false">
                            <h2>Step-5- Final Payment :</h2>
                        </div>
                        <div className="collapse" id="collapse5" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>The final payment will be required within 7 working days. Payment as scheduled
                                        saves your order from cancellation.
                                    </li>
                                    <li>In case of order cancellation:</li>
                                    <li>Cancellation while the auction is underway, there will be a minimum cancellation
                                        fee of 100,000 JPY.
                                    </li>
                                    <li>Cancellation after the auction has closed will result in a minimum cancellation
                                        fee of 100,000 JPY as handling/service charge.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse6" aria-expanded="false">
                            <h2>Step-6- Shipping :</h2>
                        </div>
                        <div className="collapse" id="collapse6" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>The shipping will be arranged as soon as the payment is confirmed.</li>

                                    <li>Note: CFBA Japan offers you the best shipping services with the lowest
                                        freight charges.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse7" aria-expanded="false">
                            <h2>Step -7- Documents :</h2>
                        </div>
                        <div className="collapse" id="collapse7" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>After the ship leaves the loading port, we will send all the necessary documents
                                        by EMS, DHL or FedEx. The documents include:
                                    </li>
                                    <li>Export Certificate</li>
                                    <li>Bill Of Lading</li>

                                    <li>Final Invoice</li>
                                    <li>Marine Insurance (if required)</li>
                                    <li>Inspection Certificate from Japan Auto Appraisal Institute (JAAI) / Japan
                                        Vehicle Inspection Association (JAVIC) and other inspections (if required)
                                    </li>

                                    <li>Note: There are no extra or hidden charges other than C&amp;F or CIF charges.</li>
                                    <li>Cancellation after the auction has closed will result in a minimum cancellation
                                        fee of 100,000 JPY as handling/service charge.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse8" aria-expanded="false">
                            <h2>Payment Method & Bank Charges</h2>
                        </div>
                        <div className="collapse" id="collapse8" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                            <ul>
                                    <li>You can make payment by telegraphic transfer from your bank. All bank charges
                                        must be paid by the sender. Please ensure that payment must be made in US Dollar
                                        / Japanese Yen, and set forth your Invoice &amp; stock number in the remarks column.
                                        As soon as you make payment, please, send us a copy of the confirmation of
                                        payment by Email inquiry@cfbajapan.com or Phone No: +81-368-514-321.
                                    </li>
                                    <li>Full payment decided based on the mutual agreement must be made for us to ship
                                        your car(s). We will not start to arrange the shipping unless the payment is
                                        confirmed. For further information about shipping and payment, please read our
                                        Terms &amp; Conditions . or contact inquiry@cfbajapan.com
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
             </div>
          </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state,
})

export default connect(mapStateToProps, mapDispatchToProps)(AuctionSystem);
