import { createStore, applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../store/reducers/rootReducer';


const storeEnhancers = process.env.REACT_APP_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : (null || compose);
let store =  createStore(rootReducer,storeEnhancers(applyMiddleware(thunk)));
 
export default function configureStore(initialstate={}) {    
  return store
}