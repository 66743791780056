import {customer} from '../constants/index'
import axiosConfig from '../axiosConfig'
import common from '../helpers/common'

const loginAction = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/customer/login',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.LOGIN,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.LOGIN_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.LOGIN_ERROR,
            payload: e,
        })
    }
}

const verifyEmailAction = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/email/verify',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.LOGIN,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.LOGIN_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.LOGIN_ERROR,
            payload: e,
        })
    }
}

const verifyOTPAction = (jsonobj) => async dispatch => {
    try{
        let queryString = common.objToQueryString(jsonobj);
        let url = `/customer/otp?`+queryString
        const res = await axiosConfig.get(url);
        console.log(res);
        if(res.status === 200){
            dispatch( {
                type: customer.OTP_VERIFY,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.OTP_VERIFY,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.OTP_VERIFY_ERROR,
            payload: e,
        })
    }
}

const generateOTPAction = (jsonobj) => async dispatch => {
    try{
        // let queryString = common.objToQueryString(jsonobj);
        let url = `/customer/otp`
        const res = await axiosConfig.post(url,jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.OTP_GENERATE,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.OTP_GENERATE_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.OTP_GENERATE_ERROR,
            payload: console.log(e),
        })
    }
}

const setLoginInfo = (logindata) => async dispatch => {
    try{
        dispatch( {
            type: customer.LOGIN,
            payload: logindata
        })
    }
    catch(e){
        dispatch( {
            type: customer.LOGIN_ERROR,
            payload: e,
        })
    }
}
export {loginAction, setLoginInfo,generateOTPAction,verifyOTPAction,verifyEmailAction};