import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    let isLogin = (e) => {
        let loginSession = JSON.parse(localStorage.getItem('login-session'));
        if(loginSession.isLoggedIn === false){
            return false
        }else{
            return true
        }
    }
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
            : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;