import React, { Component } from 'react'
import { withRouter } from "react-router";
import {connect} from "react-redux";
import {getClientRemittance,getClientDeals} from "../../actions/customerAction";
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import common from '../../helpers/common'

export class MyDeals extends Component {
    componentDidMount() {
        let loginSession = this.props.loginresp //JSON.parse(localStorage.getItem('login-session'));
        this.props.getClientDeals(loginSession.client_id,1,15);
    }

    render() {
        let baseImageUrl = process.env.REACT_APP_BASE_IMG;
        let columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
            },
            {
                name: 'Chassis #',
                cell: row => row.bid.chassis_no,
                sortable: true,
            },
            {
                name : 'Image',
                cell : row => ((row.bid.media.length) > 0 ?
                     <img src={baseImageUrl + common.imageFullPath(row.bid.media[0])}
                            alt={row.bid.car_maker.title + " " + row.bid.year_month} />
                : <img src="https://www.carfitexperts.com/car-models/wp-content/uploads/2019/01/zen-1.jpg"
                            alt="no image available" />
                    )
        
            },
        {
            name: 'Amount ($)',
            cell: row => <NumberFormat value={row.bid.attributes.dummy_price ?? row.clientTotal} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={''} />,
            sortable: true,
        },
        {
            name: 'Amount (¥)',
            cell: row => <NumberFormat value={row.CustomPriceJPY ?? row.totalJPY} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={''} />,
            sortable: true,
        },
        {
            name: 'Booking Date',
            cell: row => row.dealDate,
            sortable: true,
        },
        {
            name: 'Loading Port',
            cell: row => row.logistic_info.port.title,
            sortable: true,
        },
        {
            name: 'Discharge Port',
            cell: row => row.logistic_info.destination_port.title,
            sortable: true,
        },
        {
            name: 'Shipping Line',
            cell: row => row.logistic_info.shipper_line.name,
            sortable: true,
        },
        {
            name: 'Vessel',
            cell: row => row.logistic_info.vessel.name,
            sortable: true,
        },
        {
            name: 'ETA',
            cell: row => row.logistic_info.ETA,
            sortable: true,
        },
        {
            name: 'ETD',
            cell: row => row.logistic_info.ETD,
            sortable: true,
        },
        {
            name: 'Courier',
            cell: row => row.logistic_info.intlTrackingNo,
            sortable: true,
        },
        {
            name: 'BL',
            cell: row => row.BL != null ? <a target="_blank" href={baseImageUrl +common.imageFullPath(row.BL,'deals')} className="btn btn-sm btn-info">BL</a> : '--',
            sortable: true,
        },
        {
            name: 'Invoice',
            cell: row => (row.PerfomaInvoice != null || row.CustomPerfomaInvoice != null) ? <a target="_blank" href={baseImageUrl +common.imageFullPath(row.CustomPerfomaInvoice ?? row.PerfomaInvoice,'deals')} className="btn btn-sm btn-info">Invoice</a> : '--',
            sortable: true,
        },
        // {
        //     name: 'Amount (¥)',
        //     cell: row => <NumberFormat value={row.amount_JPY} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={''} />,
        //     sortable: true,
        // },
        // {
        //     name: 'Bank',
        //     cell: row => <div>{row.bank.bank_name} - {row.bank.account_no}</div>,
        //     sortable: true,
            
        // }
        ];
        const deals   = this.props.deals;

        return (
                <div className="col-lg-10 my-deals">
                    <div className="verticle">
                         <DataTable
                            pagination
                            title="MY DEALS"
                            columns={columns}
                            data={deals.data}
                        />
                    </div>

                </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getClientDeals : (id,page,limit) => dispatch(getClientDeals(id,page,limit)),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        clientRemittances  : state.customerReducer.remittances,
        deals  : state.customerReducer.clientDeals,
        loginresp : state.customerReducer.loginresp,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyDeals));
