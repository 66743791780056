import {React ,useEffect} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import { getMakerCount } from "../../../actions/homeAction"

function PriceRange(props) {

    // useEffect(() => {
    //     props.getMakerCount();
    // }, [])
    let prices = [
        {
            label :'Under $500',
            value : '0-500'
        },
        {
            label :'$500 - $1,000',
            value : '500-1000'
        },
        {
            label :'$1,000 - $1,500',
            value : '1000-1500'
        },
        {
            label :'$1,500 - $2,000',
            value : '1500-2000'
        },
        {
            label :'$2,000 - $2,500',
            value : '2000-2500'
        },
        {
            label :'$2,500 - $3,000',
            value : '2500-3000'
        },
        {
            label :'$3,000 - $4,000',
            value : '3000-4000'
        },
        {
            label :'Above $4,000',
            value : '4000-99999999'
        },
    ];
    let priceRange = [];
    prices.map((price,key) => 
        priceRange.push(
            <li key={key}><Link to={"/prices/" + price.value}><i className={''}></i>{price.label}</Link></li>
        ),
    );
    return (
        <div className="wo-widget" id="left-side">
            <div className="wo-widget__title wo-collapse-title" role="button" id="title2" data-toggle="collapse" data-target="#priceRange" aria-expanded="true">
                <h2>By Price:</h2>
            </div>
            <div className="collapse show" id="priceRange" aria-labelledby="title2">
                <div className="wo-widget__content wo-widget-categories">
                    <ul>
                        {priceRange}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PriceRange;
