export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE'    
};

export const dealConstants = {
    LISTING : 'DEALS_LISTING',
    LIST    : 'DEALS_LIST',
    SEARCH  : 'DEALS_SEARCH',
    ERROR   : 'DEALS_ERROR',

    USED_CAR_SEARCH           : 'USED_CAR_SEARCH',
    USED_CAR_SEARCH_ERROR     : 'USED_CAR_SEARCH_ERROR',
    GET_USED_CAR_SEARCH       : 'GET_USED_CAR_SEARCH',
    GET_USED_CAR_SEARCH_ERROR : 'GET_USED_CAR_SEARCH_ERROR',

    TYPE_SEARCH           : 'TYPE_SEARCH',
    TYPE_SEARCH_ERROR     : 'TYPE_SEARCH_ERROR',

    KEYWORD_SEARCH           : 'KEYWORD_SEARCH',
    KEYWORD_SEARCH_ERROR     : 'KEYWORD_SEARCH_ERROR',

    GET_KEYWORD_SEARCH       : 'GET_KEYWORD_SEARCH',
    GET_KEYWORD_SEARCH_ERROR : 'GET_KEYWORD_SEARCH_ERROR',

    KEY_SEARCH_URL_RES       : 'KEY_SEARCH_URL_RES',
    KEY_SEARCH_URL_RES_ERROR : 'KEY_SEARCH_URL_RES_ERROR',

    PREMIUM_CLASS : 'PREMIUM_CLASS',
    NEW_ARRIVALS  : 'NEW_ARRIVALS',
    CHEAP_DEALS   : 'CHEAP_DEALS',
    SIMILAR_DEALS : 'SIMILAR_DEALS',

    MAKER_LISTING         : 'MAKER_LISTING',
    MAKER_LISTING_ERROR   : 'MAKER_LISTING_ERROR',

    COUNTRY_LISTING       : 'COUNTRY_LISTING',
    COUNTRY_LISTING_ERROR : 'COUNTRY_LISTING_ERROR',
};

export const carDetails = {
    CAR_DETAILS_DATA  : 'CAR_DETAILS_DATA',
    CAR_DETAILS_ERROR : 'CAR_DETAILS_ERROR'
};

export const dealQuote = {
    QUOTE               : 'QUOTE',
    QUOTE_ERROR         : 'QUOTE_ERROR',
    LISTING_QUOTE       : 'LISTING_QUOTE',
    LISTING_QUOTE_ERROR : 'LISTING_QUOTE_ERROR'
};

export const customer = {
    LOGIN               : 'LOGIN',
    LOGIN_UPDATE        : 'LOGIN_UPDATE',
    LOGIN_ERROR         : 'LOGIN_ERROR',
    REMITTANCES         : 'REMITTANCES',
    REMITTANCES_ERROR   : 'REMITTANCES_ERROR',
    DASHBOARD              : 'DASHBOARD',
    DASHBOARD_ERROR        : 'DASHBOARD_ERROR',
    CLIENT_DEAL         : 'CLIENT_DEAL',
    CLIENT_DEAL_ERROR   : 'CLIENT_DEAL_ERROR',
    STATES              : 'STATES',
    STATES_ERROR        : 'STATES_ERROR',
    COUNTRIES           : 'COUNTRIES',
    COUNTRIES_ERROR     : 'COUNTRIES_ERROR',
    CITIES              : 'CITIES',
    CITIES_ERROR        : 'CITIES_ERROR',
    UPDATE_PROFILE      : 'UPDATE_PROFILE',
    UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',
    STORE_REVIEW        : 'STORE_REVIEW',
    STORE_REVIEW_ERROR  : 'STORE_REVIEW_ERROR',
    REVIEW              : 'REVIEW',
    REVIEW_ERROR        : 'REVIEW_ERROR',
    STORE_FEEDBACK      : 'STORE_FEEDBACK',
    STORE_FEEDBACK_ERROR: 'STORE_FEEDBACK_ERROR',
    FEEBACK             : 'FEEBACK',
    FEEBACK_ERROR       : 'FEEBACK_ERROR',
    REGISTER            : 'REGISTER',
    REGISTER_ERROR      : 'REGISTER_ERROR',
    OTP_GENERATE        : 'OTP_GENERATE',
    OTP_GENERATE_ERROR  : 'OTP_GENERATE_ERROR',
    OTP_VERIFY          : 'OTP_VERIFY',
    OTP_VERIFY_ERROR    : 'OTP_VERIFY_ERROR',
    NOTIFY_PARTY        : 'NOTIFY_PARTY',
    NOTIFY_PARTY_ERROR  : 'NOTIFY_PARTY_ERROR',
    CONSIGNEE           : 'CONSIGNEE',
    CONSIGNEE_ERROR     : 'CONSIGNEE_ERROR',
    UPDATE_NOTIFY_PARTY         : 'UPDATE_NOTIFY_PARTY',
    UPDATE_NOTIFY_PARTY_ERROR   : 'UPDATE_NOTIFY_PARTY_ERROR',
    UPDATE_CONSIGNEE            : 'UPDATE_CONSIGNEE',
    UPDATE_CONSIGNEE_ERROR      : 'UPDATE_CONSIGNEE_ERROR',
};

export const homeConstants = {
    TOP_NEW             : 'TOP_NEW',
    TOP_NEW_ERROR       : 'TOP_NEW_ERROR',
    FEATURED            : 'FEATURED',
    FEATURED_ERROR      : 'FEATURED_ERROR',
    MAKER_COUNT         : 'MAKER_COUNT',
    MAKER_COUNT_ERROR   : 'MAKER_COUNT_ERROR',
    COUNTRY_COUNT       : 'COUNTRY_COUNT',
    COUNTRY_COUNT_ERROR : 'COUNTRY_COUNT_ERROR',
    LIST                : 'HOME_LIST',
    SEARCH              : 'HOME_SEARCH',
    ERROR               : 'HOME_ERROR',
    PREMIUM_CLASS       : 'PREMIUM_CLASS',
    NEW_ARRIVALS        : 'NEW_ARRIVALS',
    CHEAP_DEALS         : 'CHEAP_DEALS',
    MAKERS              : 'MAKERS',
    MAKERS_ERROR        : 'MAKERS_ERROR',
    MODELS              : 'MODELS',
    MODELS_ERROR        : 'MODELS_ERROR',
    ZONE                : 'ZONE',
    ZONE_ERROR          : 'ZONE_ERROR',
    TRUSTED_CLIENT      : 'TRUSTED_CLIENT',
    TRUSTED_CLIENT_ERROR: 'TRUSTED_CLIENT_ERROR',
};

export const COUNTRIES_LIST = 'COUNTRIES_LIST'
export const COUNTRIES_LIST_ERROR = 'COUNTRIES_LIST_ERROR'

export const SHIPPING_SCHEDULE       = 'SHIPPING_SCHEDULE'
export const SHIPPING_SCHEDULE_ERROR = 'SHIPPING_SCHEDULE_ERROR'

export const CLIENT_REVIEW_EXISTS       = 'CLIENT_REVIEW_EXISTS'
export const CLIENT_REVIEW_EXISTS_ERROR = 'CLIENT_REVIEW_EXISTS_ERROR'

export const TEST_ACTION    = 'TEST_ACTION'
export const TEST_ACTION2   = 'TEST_CONST2'
export const STOCK_LIST     = 'STOCK_LIST'
export const GET_STOCK      = 'GET_STOCK'
export const STOCK_ERROR    = 'STOCK_ERROR'
export const INCREMENT      = 'INCREMENT'
export const DECREMENT      = 'DECREMENT'

export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR   = 'FETCH_PRODUCTS_ERROR';
export const ENV                    =  'production';