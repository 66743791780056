import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { countries } from '../../data/countries'
// import common from '../../helpers/common';
import MetaHelmet from '../partials/widgets/MetaHelmet';

class Faqs extends Component {
    componentDidMount() {
        // common.appendScript('assets/js/main.js')
    }
    render() {
        let metaHelmet = {
            title: `FAQ's`,
            description: ''
        }
        return (
            <>
                <MetaHelmet {...metaHelmet} />
                <div className="container">
                    <div className="wo-widget" id="faq">
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse1" aria-expanded="true">
                            <h2>How Do I Buy A Car From CFBA JAPAN:</h2>
                        </div>
                        <div className="collapse show" id="collapse1" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>1. Register an CFBA JAPAN website,</li>
                                    <li>2. Choose the car you like,</li>
                                    <li>3. Click [Buy Now] - then our sales staff will contact you for further
                                        assistances.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse2" aria-expanded="false">
                            <h2>How To Buy</h2>
                        </div>
                        <div className="collapse" id="collapse2" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>1. Is there a fee for registration or membership fee?</li>
                                    <li>2. No, registration and membership fee.</li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse3" aria-expanded="false">
                            <h2>How Can I Know A Car's Condition?</h2>
                        </div>
                        <div className="collapse" id="collapse3" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>Used cars are offered "as is" and there are some scratches or dents. CFBA
                                        JAPAN check mechanical condition before we ship the vehicle but we cannot
                                        guarantee the condition 100 percent because sometimes trouble happens on the way
                                        from Japan. To minimize those risk, CFBA JAPAN recommend you to take car
                                        inspection from third party (JAVIC &amp; JAI)
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse4" aria-expanded="false">
                            <h2>Where Do I Collect My Car?</h2>
                        </div>
                        <div className="collapse" id="collapse4" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>You can collect your car the port your selected. Delivery &amp; Clearing service is
                                        available at up to your door step or at your city.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse5" aria-expanded="false">
                            <h2>How To Buy</h2>
                        </div>
                        <div className="collapse" id="collapse5" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>Step 1. Select your vehicle.</li>
                                    <li>Step 2. Get “Free Quote” or “Buy Now”</li>
                                    <li>Step 3. Make Payment</li>
                                    <li>Step 4: Track Your Shipment</li>
                                    <li>Step 5: Complete Customs Clearance</li>
                                    <li>Choose the vehicle you want to purchase. Our car search engine will help you
                                        search through our inventory. You can also customize the search depending on
                                        your requirements and preferences. Detailed photos and specifications can be
                                        seen for each inventory.
                                    </li>
                                    <li>Car search engine is here: https://www.cfbajapan.com</li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse6" aria-expanded="false">
                            <h2>CFBA Japan</h2>
                        </div>
                        <div className="collapse" id="collapse6" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>https://www.cfbajapan.com</li>
                                    <li>Step 2. Get “Free Quote” or “Buy Now”</li>
                                    <li>Once you have chosen the vehicle, you can choose</li>

                                    <li>“Free Quote”: For clients who have registered but has no purchase history with
                                        CFBA JAPAN and new clients who are not yet registered
                                    </li>
                                    <li>For registered clients: you will be required to log in to your account</li>
                                    <li>For new clients: you will be asked to enter your details in the separate window
                                        for registration.
                                    </li>

                                    <li>“Buy Now”: For returning clients who has a purchase history or a money deposit
                                        with CFBA Japan.
                                    </li>
                                    <li>You will be required to log in with your account, AFTER pressing “Buy Now”. Make
                                        sure to input correct details such as “destination country” or “port” as the
                                        shipping fee will be adjusted accordingly. Once all details are confirmed, you
                                        will receive the Proforma Invoice.
                                    </li>
                                    <li>Notes: Your reservation will be reviewed by our sales agents before Proforma
                                        Invoice is sent. We will be contacting you via email or phone call, if there are
                                        other necessary details that we need or if we see errors in your reservation.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse7" aria-expanded="false">
                            <h2>Step 3. Make Payment</h2>
                        </div>
                        <div className="collapse" id="collapse7" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>1. Receive your Proforma Invoice.</li>
                                    <li>Receive your Proforma Invoice in your email and print it out.</li>
                                    <li>Warning!</li>

                                    <li>We strongly advise our customers to BEWARE OF FAKE EMAILS about the Payment.
                                    </li>
                                    <li>Make payment to ONLY INVOICE from our below E-mail address:</li>
                                    <li>csd@cfbajapan.com</li>

                                    <li>customer.support@cfbajapan.com</li>
                                    <li>Payment is DUE within 7 working days after you receive Proforma Invoice.</li>
                                    <li>Bank wire transfer (Telegraphic Transfer)</li>

                                    <li>All customers should send money only to CFBA JAPAN beneficiary accounts in
                                        Japan.
                                    </li>
                                    <li>It will be shown in the proforma invoice, to which one of the following
                                        accounts, that you need to pay:
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse8" aria-expanded="false">
                            <h2>Beneficiary Bank Account (Our Bank Details)</h2>
                        </div>
                        <div className="collapse" id="collapse8" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>As soon as you complete your payment, please scan your confirmation receipt of
                                        payment and send its copy to us by email or fax:
                                    </li>
                                    <li>csd@cfbajapan.com</li>
                                    <li>customer.support@cfbajapan.com</li>
                                    <li>We will not begin to process the shipping procedures until your payment is
                                        received. Full payment, based on the mutual agreement, must be made for you to
                                        receive your car.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse9" aria-expanded="false">
                            <h2>Step 4: Track Your Shipment</h2>
                        </div>
                        <div className="collapse" id="collapse9" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>You will receive the information about booking details of your shipment and
                                        other shipping information to your email.
                                    </li>
                                    <li>Please login to your account page in CFBA JAPAN and confirm the date of
                                        Departure and Arrival for your shipment through “My Account” page on our
                                        website.
                                    </li>
                                    <li>After we ship your car to your port, we will send all necessary original
                                        documents to you by DHL carrier.
                                    </li>
                                    <li>Please be ready OR arrange to receive your car at your Port of Destination.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title clr" role="button" id="title3" data-toggle="collapse" data-target="#collapse10" aria-expanded="false">
                            <h2>Important Documents - The Documents You Need To Receive For Your Car:</h2>
                        </div>
                        <div className="collapse" id="collapse10" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>Export Certificate:</li>
                                    <li>The original Export Certificate (Japanese)</li>
                                    <li>Customs Invoice:</li>
                                    <li>This document is for your local customs department to verify the value of your
                                        vehicle.
                                    </li>
                                    <li>Bill of Lading (BL):</li>
                                    <li>You need Bill of Lading (BL) receive your vehicle at the Port of Destination
                                        (POD).
                                    </li>
                                    <li>Other documents may be required depending on your country's regulations. Please
                                        our sales representatives for additional documents when they are required.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="wo-widget__title wo-collapse-title" role="button" id="title3" data-toggle="collapse" data-target="#collapse11" aria-expanded="false">
                            <h2>Step 5: Complete Customs Clearance</h2>
                        </div>
                        <div className="collapse" id="collapse11" aria-labelledby="title3">
                            <div className="wo-widget__content wo-widget-archives">
                                <ul>
                                    <li>Please contact your local Customs office or clearing agent to make clearing
                                        procedures, and finally receive your car!
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({

})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
})

export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
