import { combineReducers } from 'redux';
// import simpleReducer from './simpleReducer';
import stockReducer from './stockReducer';
import customerReducer from './customerReducer';
// import counterReducer from './counterReducer';
import dealReducer from './dealReducer';
import homeReducer from "./homeReducer";

export default combineReducers({
  stockReducer,
  dealReducer,
  homeReducer,
  customerReducer,
});