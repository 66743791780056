import React, {Component, useCallback} from 'react'
import {connect} from "react-redux";
import { withRouter } from "react-router";
import {toast, ToastContainer} from "react-toastify";
import {
    getNotifyParty,
    updateNotifyPartyAction,
} from "../../actions/customerAction";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import {confirmAlert} from "react-confirm-alert";

export class NotifyParty extends Component {
    constructor(props) {
        super(props)
        let loginSession  = JSON.parse(localStorage.getItem('login-session'));

        this.state = {
            id              : '',
            title           : '',
            nick_name       : '',
            phone           : '',
            address         : '',
            email           : '',
            client_id       : loginSession.client_id,
            titleError      : '',
            nickNameError   : '',
            emailError      : '',
            phoneError      : '',
            addressError    : '',
            success         : '',
            loginSession    : loginSession,
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    handleUpdatePhone = (phone) => {
        this.setState({ phone: phone })
    }

    editForm     = (id,title,nick_name,email,phone,address,type) => {
        let titleError      = ''
        let nickNameError   = ''
        let emailError      = ''
        let phoneError      = ''
        let addressError    = ''
        if(type === 'edit'){
            this.setState({
                id              : id,
                title           : title,
                nick_name       : nick_name,
                email           : email,
                phone           : phone,
                address         : address,
            })
        }else if(type === "add"){
            this.setState({
                id              : '',
                title           : title,
                nick_name       : '',
                email           : email,
                phone           : phone,
                address         : '',
            })
        }else{
            titleError      = this.state.titleError
            nickNameError   = this.state.nickNameError
            emailError      = this.state.emailError
            phoneError      = this.state.phoneError
            addressError    = this.state.addressError
        }
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Notify Party</h4>
                                <a ref={input => this.closeModal = input} onClick={() => { onClose() }} className="close"><i className="ti-close" ></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput"> Title:</label>
                                            <input name={'title'} defaultValue={title} placeholder={"tile"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{titleError}</p>
                                        </div>

                                        <div className="form-group">
                                            <label className="wo-titleinput"> Nick Name:</label>
                                            <input name={'nick_name'} defaultValue={nick_name} placeholder={"Nick Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{nickNameError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Phone:</label>
                                            <input name={'phone'} defaultValue={phone} placeholder={"phone"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{phoneError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Email:</label>
                                            <input name={'email'} defaultValue={email} placeholder={"email"} type='email' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{emailError}</p>
                                        </div>

                                        <div className="form-group">
                                            <label className="wo-titleinput">Address:</label>
                                            <input name={'address'} defaultValue={address} placeholder={"address"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{addressError}</p>
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-success'}>{this.state.success}</p>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <button onClick={(e) => this.onSubmit(e)} className='wo-btn'>Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            'title'       : this.state.title,
            'nick_name'   : this.state.nick_name,
            'email'       : this.state.email,
            'phone_number': this.state.phone,
            'address'     : this.state.address,
            'client_id'   : this.state.client_id,
            'id'          : this.state.id,
        }
        this.props.updateNotifyparty(data)
    }

    componentDidMount() {
        this.props.getNotifyParty(this.state.client_id);
    }

    componentWillReceiveProps(nextProps){
        this.setState(
            {
                ...nextProps.match.params,
                updateNotifypartyRes :nextProps.updateNotifypartyRes,
            },()=>{
                const notifypartyRes = this.state.updateNotifypartyRes
                if(notifypartyRes !== undefined){
                    if(notifypartyRes.status === true){
                        let notification = ''
                        notification = <ToastContainer>
                            {
                                toast.success(notifypartyRes.message, {
                                    position    : "top-right",
                                    autoClose   : 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable   : true,
                                    progress    : undefined,
                                })
                            }
                        </ToastContainer>
                        this.setState({
                            success         : notification,
                            emailError      : '',
                            phoneError      : '',
                            addressError    : '',
                            titleError      : '',
                            nickNameError   : '',
                        },()=> {
                            this.closeModal.click()
                        })
                    }else{
                        this.setState({
                            titleError      : notifypartyRes.errors.title,
                            nickNameError   : notifypartyRes.errors.nick_name,
                            emailError      : notifypartyRes.errors.email,
                            phoneError      : notifypartyRes.errors.phone_number,
                            addressError    : notifypartyRes.errors.address,
                            success         : '',
                        },()=>{
                            this.editForm()
                        })
                    }
                }
            });

    }

    render() {
        let notifyParty0ldData = this.props.notifypartyRes;
        let loginSession = this.state.loginSession;
        let columns = [
            {
                name: 'Created_at',
                selector: 'created_at',
                sortable: true,
            },
            {
                name: 'Title',
                selector: 'title',
                sortable: true,
            },
            {
                name: 'Nick Name',
                selector: 'nick_name',
                sortable: true,
            },
            {
                name: 'Phone Number',
                cell: row => row.phone_number,
                sortable: true,
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
            },
            {
                name: 'Address',
                selector: 'address',
                sortable: true,
            },
            {
                name: 'Edit',
                sortable: true,
                cell: row => <div><button className="fa-edit" onClick={(e) => this.editForm(row.id,row.title,row.nick_name,row.email,row.phone_number,row.address,'edit')} >Edit</button></div>,
            },
        ];

        return (
            <>

                <div className="col-lg-8">
                    <div className="wo-settingsave">
                        <div className="wo-settingsave__btns">
                            <button onClick={(e) => this.editForm('',loginSession.name,'',loginSession.email,loginSession.user_detail.phone,'','add')} className='wo-btn'>Add Now</button>
                        </div>
                    </div>
                    {this.state.success}
                    <div className="verticle">
                        <DataTable
                            title="Notify Party"
                            columns={columns}
                            data={notifyParty0ldData}
                        />
                    </div>
                </div>

            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getNotifyParty     : (id) => dispatch(getNotifyParty(id)),
    updateNotifyparty  : (jsondata) => dispatch(updateNotifyPartyAction(jsondata))

})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        notifypartyRes      : state.customerReducer.notifypartyRes,
        updateNotifypartyRes: state.customerReducer.updateNotifypartyRes,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotifyParty));







