import { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { getSimilar } from "../../actions/dealAction"
import DealItem from "../partials/Listings/items/DealItem"
import Loader from "../../components/partials/Loader"

export class Similar extends Component {
    constructor(props){
        super(props)
        this.state = {
            data : []
        }
    }
    componentDidMount() {
        const { car_maker , car_model , attributes,sold_price } = this.props.cardata.bid
        const sellingPrice = attributes.selling_price ?? this.props.cardata.clientTotal
        const pricePercent = sellingPrice / 100;
        const similarFilter = {
            // 'type' : attributes.bid_type,
            // 'maker' : car_maker.title,
            // 'prices' : sellingPrice - (pricePercent * 10) +'-'+ (sellingPrice + (pricePercent * 10))
        };

        this.props.getSimilar(similarFilter);
    }
    render() {
        const { data, meta, loading } = this.props.Similar

        let result = []

        data.map((deal,key) => (
            result.push( 
                <div className="col-lg-3 col-md-6" key={key}>
                    <DealItem deal={deal} />
                </div>
            )
        ))
        return (
            <>
                {result}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getSimilar : (jsondata) => dispatch(getSimilar(jsondata)),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        Similar : state.homeReducer.similardeals,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Similar);
