import {Component, Fragment} from "react";
import { connect } from "react-redux";
// import { Link } from 'react-router-dom';
// import common from "../../helpers/common"
import { getDeals,getMakerListing } from "../../actions/dealAction"
import {getMakerCount} from "../../actions/homeAction"
import { getcustomerReview } from "../../actions/customerAction";
import NewArrival from "../../components/partials/NewArrival"
import PremiumClass from "../../components/partials/PremiumClass"
import CheapDeals from "../../components/partials/CheapDeals"
import {getMakers , getModels} from "../../actions/commonAction";
import Loader from "../../components/partials/Loader"
import ReviewList from "../partials/ReviewList";
import Brands from '../partials/widgets/Brands'
import Countries from "../partials/widgets/Countries";
// import ReactPlayer from "react-player";
import {Link} from 'react-router-dom'
import PriceRange from "../partials/widgets/PriceRange";
// import {confirmAlert} from 'react-confirm-alert'
import {confirmAlert} from 'react-confirm-alert'
import { withRouter } from "react-router";
import {serachByType} from "../../actions/searchAction";
import MetaHelmet from "../partials/widgets/MetaHelmet";

import Parser from 'html-react-parser';
import ReactGa from 'react-ga'


class HomeBase extends Component {

    constructor(props) {
        super(props);
        // Set initial state
        // this.state = {dealData : []}
        this.state = {
            maker       : '',
            type        : '',
            priceMin    : '',
            priceMax    : '',
            model       : '',
            yearMin     : '',
            yearMax     : '',
            freighttype : ''
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let prices = '';
        let years = '';
        if(this.state.priceMin === '' && this.state.priceMax === ''){
            prices  = '';
        }else{
            prices  = this.state.priceMin+'-'+this.state.priceMax;
        }
        if(this.state.yearMin === '' && this.state.yearMax === ''){
            years  = '';
        }else{
            years  = this.state.yearMin +'-'+this.state.yearMax;
        }

        if(this.state.maker !== '' || this.state.type !== '' || prices !== '' || this.state.model !== '' || this.state.freighttype !== '' || years !== ''){
            this.props.history.push(`/main-search?maker=`+this.state.maker+`&sort=`+''+`&type=`+this.state.type +`&prices=`+prices +`&model=`+this.state.model +`&years=`+years +`&freighttype=`+this.state.freighttype +
                `&steering_type=`+'' +`&fuel_type=`+'' +`&transmission=`+'' +`&mileage=`+'' +`&engine_cc=`+''+`&cd_player=`+'' +
                `&sun_roof=`+'' +`&leather_seat=`+'' +`&alloy_wheels=`+'' +`&power_steering=`+'' +`&power_window=`+'' +`&a_c=`+'' +`&abs=`+'' +`&air_bag=`+'' +
                `&radio=`+'' +`&cd_changer=`+'' +`&dvd=`+'' +`&tv=`+'' +`&power_seat=`+'' +`&back_tire=`+'' +`&grill_guard=`+'' +`&rear_spoiler=`+'' +
                `&central_locking=`+'' +`&jack=`+'' +`&spare_tire=`+'' +`&degree_camera=`+'' +`&wheel_spanner=`+'' +`&back_camera=`+'' +`&push_start=`+'' +`&keyless_entry=`+'' +`&esc=`+'' +
                `&limit=`+15 +`&page=`+1);
        }
    }

    onClickTpye =(result)=>{
        this.props.history.push(`/bid-type?search=`+result +`&limit=`+15 +`&page=`+1);
    }

    componentDidMount() {
        ReactGa.pageview(window.location.pathname + window.location.search);
        this.props.customerReviews()
        this.props.getMakerCount();
        this.props.getMakers();
        this.props.getModels();
        this.props.searchByType();
    }

    render() {
        const carIcon = (result) => {
            switch (result) {
                case 'suv':
                    return 'icn-1';
                case 'van':
                    return 'icn-2';
                case 'sedan':
                    return 'icn-3';
                case 'mini_van':
                    return 'icn-4';
                case 'truck':
                    return 'icn-5';
                case 'hatchback':
                    return 'icn-6';
                case 'wagon':
                    return 'icn-7';
                case 'coupe':
                    return 'icn-8';
                default:
                    return '';
            }
        }

        // const categories = this.props.categories
        const selectMakers    = this.props.makers
        const selectModels    = this.props.models
        let customerReviews   = this.props.reviews.data
        let typeSearchRes     = this.props.searchTypeRes

        let searchType = []
        for (const [type, makerModels] of Object.entries(typeSearchRes)) {
            let makerCount = [];
            for (const [name, count] of Object.entries(makerModels)) {
                    makerCount.push(
                        <li> <a href='#' onClick={(e) => this.onClickTpye(name)}>{name} ({count})</a></li>
                    )
            }

            {searchType.push(
                    <>
                    <div className="col-md-3 tpbtm">
                    <div className="seller icon">
                        <a>
                            <i className={carIcon(type)}></i>
                            <h3 className="type-item-headline">{type}
                            </h3>
                        </a>
                    </div>
                    <div className="">
                        <ul>
                            {makerCount}
                        </ul>
                    </div>
                    </div>
                    </>
            )}
        }

        let makers      = []
        let models      = []
        let years       = []
        let freighttype = []
        let types = [{'title':'FOB'},{'title':'CnF'}]
        let currentYear = (new Date().getFullYear())

        for (var i = 1990; i <= currentYear; i++) {
            years.push(
                <option value={i} key={i}>{i}</option>
            )
        }
        selectMakers.map((maker,key) =>{
            makers.push(
                <option value={maker.title} key={key}>{maker.title}</option>
            )
        })
        selectModels.map((model,key) =>{
            models.push(
                <option value={model.title} key={key}>{model.title}</option>
            )
        })

        types.map((types,key) =>{
            freighttype.push(
                <option value={types.title} key={key}>{types.title}</option>
            )
        })
        let metaHelmet = {
            title: `Home`,
            description: ''
          }

        return (
            <main className="wo-main overflow-hidden">
                <MetaHelmet {...metaHelmet} />
                {/*Search Result START */}
                <section className="wo-main-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2" id="left-side">
                                <Brands/>
                                <hr/>
                                <PriceRange/>
                            </div>
                            <div className="col-md-8 indexCss">
                             <div className="wo-innerbanner mb-5">
                                   <div className="wo-searchbar">
                                       <form className="wo-themeform wo-bannerform" >
                                           <fieldset>
                                               <div className="wo-group-wrap">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <div className="wo-select">
                                                                <select name={"maker"} placeholder="Makers" onChange={e => this.handleChange(e)} className="form-control">
                                                                    <option value=''>Makers</option>
                                                                    {makers}
                                                                </select>
                                                            </div>
                                                        </div>
                                                   </div>
                                                   <div className="col-md-4">
                                                        <div className="form-group">
                                                        <div className="wo-select">
                                                                <select name={"type"} onChange={e => this.handleChange(e)} className="form-control">
                                                                    <option value="">Type</option>
                                                                    <option value={'sedan'}>Sedan</option>
                                                                    <option value={'hatchback'}>Hatchback</option>
                                                                    <option value={'suv'}>SUV</option>
                                                                    <option value={'mini_van'}>Mini Van</option>
                                                                    <option value={'van'}>Van</option>
                                                                    <option value={'truck'}>Truck</option>
                                                                    <option value={'wagon'}>Wagon</option>
                                                                    <option value={'coupe'}>Coupe</option>
                                                                    <option value={'mini_vehicle'}>Mini Vehicle</option>
                                                                    <option value={'bus'}>Bus</option>
                                                                    <option value={'mini_bus'}>Mini Bus</option>
                                                                    <option value={'pick_up'}>Pick Up</option>
                                                                    <option value={'convertible'}>Convertible</option>
                                                                    <option value={'tractor'}>Tractor</option>
                                                                    <option value={'forklift'}>Forklift</option>
                                                                    <option value={'Machinery'}>Machinery</option>
                                                                    <option value={'bus_20_seats'}>Bus 20 Seats</option>
                                                                    <option value={'unspecified'}>Unspecified</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                   </div>
                                                   <div className="col-md-4">
                                                        <div className="form-group">
                                                            <div className="wo-select">
                                                                <select name={"model"}  onChange={e => this.handleChange(e)} className="form-control">
                                                                    <option value="">Models</option>
                                                                    {models}
                                                                </select>
                                                            </div>
                                                        </div>
                                                   </div>
                                                   <div className="col-lg-2">
                                                        <div className="form-group">
                                                            <input name={'priceMin'} placeholder={"Min Price"} type='number' onChange={e => this.handleChange(e)} className="form-control" />
                                                        </div>
                                                   </div>
                                                   <div className="col-md-2">
                                                        <div className="form-group">
                                                            <input name={'priceMax'} placeholder={"Max Price"} type='number' onChange={e => this.handleChange(e)} className="form-control" />
                                                        </div>
                                                   </div>
                                                   
                                                   <div className="col-md-2">
                                                        <div className="form-group">
                                                            <div className="wo-select">
                                                                <select name={"yearMin"} onChange={e => this.handleChange(e)} className="form-control">
                                                                    <option value="">Min Year</option>
                                                                    {years}
                                                                </select>
                                                            </div>
                                                        </div>
                                                   </div>
                                                   <div className="col-md-2">
                                                        <div className="form-group">
                                                            <div className="wo-select">
                                                                <select name={"yearMax"} onChange={e => this.handleChange(e)} className="form-control">
                                                                    <option value="">Max Year</option>
                                                                    {years}
                                                                </select>
                                                            </div>
                                                        </div>
                                                   </div>
                                                   <div className="col-md-1" id="check">
                                                        <div className="form-group form-check">
                                                            <input type="radio" onChange={e => this.handleChange(e)} className="form-check-input" value="fob" name="freighttype" id="freighttype-fob"></input>
                                                            <label className="form-check-label" htmlFor="freighttype-fob">FOB</label>
                                                        </div>
                                                        <div className="form-group form-check">
                                                            <input type="radio" onChange={e => this.handleChange(e)} className="form-check-input" value="cnf" name="freighttype" id="freighttype-Cnf"></input>
                                                            <label className="form-check-label" htmlFor="freighttype-Cnf">C&F</label>
                                                        </div>
                                                       
                                                   </div>

                                                   <div className="col-md-3">
                                                        <div className="form-group wo-form-btn typesearch">
                                                            {/*<a href="/" className="wo-searchntn">SEARCH</a>*/}
                                                            <button onClick={(e) => this.onSubmit(e)} className='wo-searchntn'>SEARCH</button>
                                                        </div>
                                                   </div>
                                               </div>
                                           </fieldset>
                                       </form>
                                   </div>
                               </div>
                                <div className="wo-searchresult-grid">
                                    <div className="tittles">
                                        <h2><a href="">New Arrival</a></h2>
                                    </div>
                                    <NewArrival />

                                    <div className="tittles">
                                        <h2><a href="">Premium Class</a></h2>
                                    </div>
                                    <PremiumClass />

                                    <div className="tittles">
                                        <h2><a href="">Cheap Deals</a></h2>
                                    </div>
                                    <CheapDeals />
                                    {/* <Pagination data={meta} onclick={this.props.getDeals.bind(5)} /> */}
                                    <div className="wo-col-grid wo-col-full">
                                        <Link to={'/shipping-schedule'}>
                                            <figure className="wo-addimg">
                                                <img src="/assets/images/index/banner/shippingschedule.png" alt="shipping schedule"></img>
                                            </figure>
                                        </Link>
                                    </div>
                                    
                                </div>
                                <div className="tittles typ">
                                    <h2><a href="">Search by Type</a></h2>
                                </div>
                                <div id="bestSeller">
                                    <div className="row">
                                    {searchType}
                                    </div>
                                </div>
                                
                                <div className="col-12" >
                                    <div className="tittles typ">
                                        <h2><a href="">Customer Reviews and Feedback</a></h2>
                                        <span className="float-right"> <Link to={'/reviews'} >Show All! </Link></span>
                                    </div>
                                    <ReviewList   data={customerReviews} />
                                </div></div>
                            <div className="col-md-2">
                                <div>
                                    <img src="/assets/images/ads/stock.jpg" alt="img description"></img>
                                </div>
                                <Countries/>
                                <div style={{marginTop:'20px'}}>
                                    <img src="/assets/images/ads/spare-parts.jpg" alt="img description"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Search Result END */}
                {/*Sale My Vehicle START*/}
                <section className="d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="wo-salevehicle">
                                    <figure className="wo-salevehicle__img">
                                        <img src="/assets/images/search/img-01.png" alt="img description"></img>
                                    </figure>
                                    <div className="wo-salevehicle__content">
                                        <img src="/assets/images/search/img-01.jpg" alt="img description"></img>
                                        <h2>I Want To Sale My Vehicle</h2>
                                        <p>Consectetur adipisicing elit sedatam eiusmod tempor incididunt ut labore etnalok dolore magna aliqua enim minim sed do eiusmod</p>
                                        <a href="/" className="wo-btn">Let’s Do This</a>
                                    </div>
                                    <div id="particles-js" className="wo-particles particles-js"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Sale My Vehicle END */}
                {/*Purchasing Safety START */}
                <section className="wo-main-section d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">wo-themeform wo-bannerform
                                <div className="wo-safetyrole">
                                    <div className="wo-safetyrole__title">
                                        <h2>Purchasing Vehicles Safety</h2>
                                    </div>
                                    <div className="wo-safetyrole__content">
                                        <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum ut <a href="/">perspiciatis unde.</a></p>
                                    </div>
                                </div>
                                <div className="wo-safetyrole">
                                    <div className="wo-safetyrole__title">
                                        <h2>Checking Security Precautions</h2>
                                    </div>
                                    <div className="wo-safetyrole__content">
                                        <img src="assets/images/search/img-02.jpg" className="wo-safetyroleimg" alt="img description"></img>
                                        <p>Domnis iste natus error sit voluptatem <a href="/">accusantium doloremque</a> laudantium, totam rem aperiam, eaqueipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                                    Nemo enimnation ipsam voluptatem quia voluptas sit aspernatur.</p>
                                        <ul className="wo-safetyrole-list">
                                            <li><span>Aut odit aut fugit sed quia consequuntur magni</span></li>
                                            <li><span>Dolores eos quistan miki ratione voluptatem sequi nesciuntiu</span></li>
                                            <li><span>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet</span></li>
                                            <li><span>Consectetur adipisci velite sed quia none numquam</span></li>
                                            <li><span>Deius modi tempora incidunt utnalo labore</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wo-safetyrole">
                                    <div className="wo-safetyrole__title">
                                        <h2>Professional Advice</h2>
                                    </div>
                                    <div className="wo-safetyrole__content">
                                        <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                                                in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur <a href="/">sint occaecat</a> cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                                                laborum.
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Purchasing Safety End */}
            </main>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getDeals        : () => dispatch(getDeals()),
    getMakerCount   : () => dispatch(getMakerCount()),
    getMakers       : () => dispatch(getMakers()),
    getModels       : () => dispatch(getModels()),
    getMakerListing : (name,page,limit) => dispatch(getMakerListing(name,page,limit)),
    customerReviews : (id)              => dispatch(getcustomerReview({},3)),
    searchByType    : ()                => dispatch(serachByType()),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        deals           : state.dealReducer.deals,
        categories      : state.homeReducer.categories,
        makerListing    : state.homeReducer.deals,
        makers          : state.homeReducer.makers,
        models          : state.homeReducer.models,
        mainSearchData  : state.dealReducer.mainSearch,
        reviews         : state.customerReducer.customerReviews,
        searchTypeRes   : state.dealReducer.searchTypeRes,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeBase));
