import React from 'react'
import { Helmet } from 'react-helmet'
import { useState,useEffect } from 'react'

export default function MetaHelmet(props) {
    const init = {
            title : 'Home',
            description : 'CFBA Japan, based in Japan, is a highly acclaimed and famous exporter of Japanese used cars.'
    }

    const { title , description } = props

    const [Stitle, settitle] = useState(init.title)
    const [Sdescription, setdescription] = useState(init.description)

    useEffect(() => {
        settitle(props.title)
        setdescription(props.description)
    }, [title,description])

    return (
        <Helmet>
        <meta charSet="utf-8" />
        <title>{Stitle} - CFBAJapan.com</title>
        <meta name="description" content={Sdescription} />
        </Helmet>
    )
}
