import React, {Component, useCallback} from 'react'
import {connect} from "react-redux";

import { withRouter } from "react-router";
import {toast, ToastContainer} from "react-toastify";
import {getConsignee, updateConsigneeAction} from "../../actions/customerAction";
import NumberFormat from "react-number-format";
import DataTable from "react-data-table-component";
import {confirmAlert} from "react-confirm-alert";

export class Consignee extends Component {
    constructor(props) {
        super(props)
        let loginSession  = JSON.parse(localStorage.getItem('login-session'));

        this.state = {
            id              : '',
            party_name      : '',
            nick_name       : '',
            phone           : '',
            address         : '',
            client_id       : loginSession.client_id,
            partyNameError  : '',
            nickNameError   : '',
            phoneError      : '',
            addressError    : '',
            success         : '',
            loginSession    : loginSession,

        }
    }

    componentDidMount() {
        this.props.getConsignee(this.state.client_id);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleUpdatePhone = (phone) => {
        this.setState({ phone: phone })
      }


    dropDown = selectedOption => {
        if(selectedOption[0]['label'] !== undefined){
            this.setState({
                'country_id' : selectedOption[0]['value']
            })
        }
    };

    editForm = (id,part_name,nick_name,phone,address,type) => {
        let partyNameError  = ''
        let nickNameError   = ''
        let phoneError      = ''
        let addressError    = ''
        if(type === 'edit'){
            this.setState({
                id              : id,
                party_name      : part_name,
                nick_name       : nick_name,
                phone           : phone,
                address         : address,
            })
        }else if(type === "add"){
            this.setState({
                id              : '',
                party_name      : '',
                nick_name       : '',
                phone           : phone,
                address         : '',
            })
        }else{
            partyNameError  = this.state.partyNameError
            nickNameError   = this.state.nickNameError
            phoneError      = this.state.phoneError
            addressError    = this.state.addressError
        }
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Consignee</h4>
                                <a ref={input => this.closeModal = input} onClick={() => { onClose() }} className="close"><i className="ti-close" ></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput"> Party Name:</label>
                                            <input name={'party_name'} defaultValue={part_name} placeholder={"part_name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{partyNameError}</p>
                                        </div>

                                        <div className="form-group">
                                            <label className="wo-titleinput"> Nick Name:</label>
                                            <input name={'nick_name'} defaultValue={nick_name} placeholder={"Nick Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{nickNameError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Phone:</label>
                                            <input name={'phone'} defaultValue={phone} placeholder={"phone"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{phoneError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Address:</label>
                                            <input name={'address'} defaultValue={address} placeholder={"address"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{addressError}</p>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <button onClick={(e) => this.onSubmit(e)} className='wo-btn'>Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            'party_name'  : this.state.party_name,
            'nick_name'   : this.state.nick_name,
            'phone'       : this.state.phone,
            'address'     : this.state.address,
            'client_id'   : this.state.client_id,
            'id'          : this.state.id,
        }
        this.props.updateConsignee(data)
    }

    componentWillReceiveProps(nextProps){
        this.setState(
            {
                ...nextProps.match.params,
                updateConsigneeRes       :nextProps.updateConsigneeRes,
            },()=>{
                const consigneeRes = this.state.updateConsigneeRes
                if(consigneeRes !== undefined){
                    if(consigneeRes.status === true){
                        let notification = ''
                        notification = <ToastContainer>
                            {
                                toast.success(consigneeRes.message, {
                                    position    : "top-right",
                                    autoClose   : 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable   : true,
                                    progress    : undefined,
                                })
                            }
                        </ToastContainer>
                        this.setState({
                            success         : notification,
                            partyNameError  : '',
                            nickNameError   : '',
                            phoneError      : '',
                        },()=> {
                            this.closeModal.click()
                        })
                    }else{
                        this.setState({
                            partyNameError  : consigneeRes.errors.party_name,
                            nickNameError   : consigneeRes.errors.nick_name,
                            phoneError      : consigneeRes.errors.phone,
                            addressError    : consigneeRes.errors.address,
                            success         : '',
                        },()=>{
                            this.editForm()
                        })
                    }
                }
            });
    }


    render() {
        let consignee0ldData = this.props.consigneeRes;
        let loginSession = this.state.loginSession;
        let columns = [
            {
                name: 'Created_at',
                selector: 'created_at',
                sortable: true,
            },
            {
                name: 'Party Name',
                selector: 'party_name',
                sortable: true,
            },
            {
                name: 'Nick Name',
                selector: 'nick_name',
                sortable: true,
            },
            {
                name: 'Phone Number',
                cell: row => row.phone,
                sortable: true,
            },
            {
                name: 'Address',
                selector: 'address',
                sortable: true,
            },
            {
                name: 'Edit',
                sortable: true,
                cell: row => <div><button className="fa-edit" onClick={(e) => this.editForm(row.id,row.party_name,row.nick_name,row.phone,row.address,'edit')} >Edit</button></div>,
            },
        ];

        return (
            <>
                <div className="col-lg-8">
                    <div className="wo-settingsave">
                        <div className="wo-settingsave__btns">
                            <button onClick={(e) => this.editForm('','','',loginSession.user_detail.phone,'','add')} className='wo-btn'>Add Now</button>
                        </div>
                    </div>
                    {this.state.success}
                    <div className="verticle">
                        <DataTable
                            title="Consignee"
                            columns={columns}
                            data={consignee0ldData}
                        />
                    </div>
                </div>

            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    updateConsignee    : (jsondata) => dispatch(updateConsigneeAction(jsondata)),
    getConsignee       : (id) => dispatch(getConsignee(id)),

})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    consigneeRes      : state.customerReducer.consigneeRes,
    updateConsigneeRes: state.customerReducer.updateConsigneeRes,


})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Consignee));
