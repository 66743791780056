import React, {Component, useCallback} from 'react'
import Select from "react-dropdown-select";
import {connect} from "react-redux";
import {getCountry,registerAction} from "../../actions/customerAction";
import { withRouter } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneField from '../partials/widgets/PhoneField'
import { verifyOTPAction,generateOTPAction } from '../../actions/loginAction';
import { updateLoginResp } from '../../actions/commonAction';

class OTP extends Component {
    constructor(props) {
        super(props)

        this.state = {
            client_id : '',
            phone       : '',
            otp       : '',
            country_id  : '',
            country  : {
                code : ''
            },
            message  : '',
            success     : '',
        }
    }

    componentDidMount() {
        let customerData = this.props.customerData

        this.setState({
            phone : customerData.client_detail.mobile,
            country : customerData.country,
            client_id : customerData.client_id
        },
        ()=>console.log(this.state)
        );
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    generateOTP = (e) => {
        e.preventDefault();
        const data = {
            'client_id' : this.state.client_id,
            'phone'    : this.state.phone
        }
        if(this.state.phone == undefined){
            alert('Contact no is required!');
            return;
        }
        // this.props.updateLoginResp({client_detail:{mobile:this.state.phone}})
        this.props.generateOTP(data)
        localStorage.setItem('login-session', JSON.stringify(this.props.loginresp));
        this.setState({
            success    : '',
            message : ''
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        let customerData = this.props.customerData
        const data = {
            'client_id' : this.state.client_id,
            'phone'    : this.state.phone,
            'otp'    : this.state.otp,
        }
        this.props.verifyOTP(data)
        this.setState({
            success    : '',
            message : ''
        })
    }
    componentWillReceiveProps(nextProps){
            const loginresp = nextProps.loginresp
            if(this.state.phone != nextProps.loginresp.client_detail.mobile){
                this.setState({phone:nextProps.loginresp.client_detail.mobile})
            }
    
            if(this.state.message != nextProps.customerReducer.message){
                this.setState({message:nextProps.customerReducer.message})
            }
            if(loginresp.client_detail.phone_verified_at != null){
                localStorage.setItem('login-session', JSON.stringify(this.props.loginresp));
                this.props.history.push('/user/profile');
              }


        // if(loginresp !== undefined){
        //     if(loginresp.code === 200){
        //         this.setState({
        //             success    : loginresp.message,
        //             otpError : '',
        //             phoneError : '',
        //             phone  : '',
        //             otp : '',
        //         },()=>{
        //         })
        //         localStorage.setItem('login-session', JSON.stringify(loginresp));
        //         loginresp.message = ''
        //         // this.props.history.push('/user/profile');

        //     }else{
        //         this.setState({
        //             success    :'',
        //             message :loginresp.message
        //         })
        //     }
        // }
    }

    render() {
        let customerData = this.props.customerData
        // let notification = ''
        // if(this.state.success !== ''){
        //    notification = <ToastContainer>
        //         {
        //             toast.success(this.state.success, {
        //                 position    : "top-right",
        //                 autoClose   : 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable   : true,
        //                 progress    : undefined,
        //             })
        //         }
        //     </ToastContainer>
        // }
        return (
            <>
            {/* {notification} */}
                 <div className="container ">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8">
                            <div className="wo-settingsave">
                                <div className="wo-settingsave__title">
                                    <h2>Enter Your OTP!</h2>
                                </div>

                            </div>
                            <div className="wo-dashboardbox">
                                <form className="wo-themeform wo-profileform wo-profileformvtwo">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Contact No*:</label>
                                            {/* <PhoneField phone={this.state.phone} country={this.state.country_id} name={'phone'}/> */}
                                            <PhoneField phone={customerData.client_detail.mobile} country={customerData.country}  countrySelectProps={{ unicodeFlags: true , disabled:true}} disabled={true} />
                                    
                                            {/* <input type="text" value={this.state.phone} className="form-control" onChange={e => this.handleChange(e)} name="phone" placeholder="+1 4256 578 68"/> */}

                                                <a href="javascript:void(0);" onClick={(e) => this.generateOTP(e)} className="wo-removelist">Send OTP</a>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Enter Your OTP*:</label>
                                            <input type="text" value={this.state.otp} className="form-control" onChange={e => this.handleChange(e)} name="otp" placeholder="Enter OTP*" />
                                        </div>
                                        <div className="form-group wo-locationtag uncompleted">
                                            <span className={'text-danger'}>{this.state.message}</span>
                                        </div>
                                    </fieldset>
                                    <div className="wo-settingsave__btns pull-right">
                                        <button onClick={(e) => this.onSubmit(e)} className='wo-btn'>Verify</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    register       : (jsondata) => dispatch(registerAction(jsondata)),
    generateOTP             : (jsondata) => dispatch(generateOTPAction(jsondata)),
    verifyOTP : (jsondata)=> dispatch(verifyOTPAction(jsondata)),
    // updateLoginResp : (jsondata) => dispatch(updateLoginResp(jsondata))
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    loginresp  : state.customerReducer.loginresp,
    customerData : state.customerReducer.loginresp,
    otpresp : state.customerReducer.loginresp.OTP,
    customerReducer : state.customerReducer

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OTP));
