import {React ,useEffect} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import { getMakerCount } from "../../../actions/homeAction"

function Brands(props) {
    function makerLogo(name) {
        switch(name) {
            case 'Daihatsu':
                return 'icn-daihatsu';
            case 'Ford':
                return 'icn-ford';
            case 'Hitachi':
                return 'icn-Hitachi';
            case 'Iseki':
                return 'icn-iseki';
            case 'Mazda':
                return 'icn-mazda';
            case 'Mercedes Benz':
                return 'icn-mercedesbenz';
            case 'Peugot':
                return 'icn-peugot';
            case 'Volkswagen':
                return 'icn-volkswagen';
            case 'Volvo':
                return 'icn-volvo';
            case 'Yamaha':
                return 'icn-yamaha';
            case 'Bmw':
                return 'icn-bmw';
            case 'Audi':
                return 'icn-audi';
            case 'Nissan':
                return 'icn-nissan';
            case 'Toyota':
                return 'icn-toyota';
            case 'Honda':
                return 'icn-honda';
            case 'Suzuki':
                return 'icn-suzuki';
            case 'Mitsubishi':
                return 'icn-mitsubishi';
            case 'Subaru':
                return 'icn-subaru';
            case 'Isuzu':
                return 'icn-isuzu';
            case 'Hino':
                return 'icn-hino';
            case 'Lexus':
                return 'icn-lexus';
            case 'Mercedesbenz':
                return 'icn-mercedesbenz';
            default:
                return '';
        }
    }
    useEffect(() => {
        props.getMakerCount();
    }, [])
    const categories = props.categories
    let makerCount = [];
    categories.map((category, key) =>
        makerCount.push(
            <li key={key}><Link to={"/category/" + category.title.toLowerCase()}><i className={makerLogo(category.title)}></i>{category.title}<em>({category.deals})</em></Link></li>
        ),
    );
    return (
        <div className="wo-widget" id="left-side">
            <div className="wo-widget__title wo-collapse-title" role="button" id="title2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true">
                <h2>By Brand:</h2>
            </div>
            <div className="collapse show" id="collapse2" aria-labelledby="title2">
                <div className="wo-widget__content wo-widget-categories">
                    <ul>
                        {makerCount}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    getMakerCount: () => dispatch(getMakerCount())
    // getMainSearch   : (jsondata,page,limit) => dispatch(getMainSearch(jsondata,page,limit))

})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        categories: state.homeReducer.categories
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
