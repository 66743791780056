import {homeConstants} from '../constants/index';
import axiosConfig from "../axiosConfig";

const getFeatured = (page =5,limit = 5) => async dispatch =>{

    try {
        const res  = await axiosConfig.get(`/deals/view?limit=`+limit+`&page=`+page);
        dispatch({
            type : homeConstants.FEATURED,
            payload: res.data
        })

    }catch (e){
        dispatch({
            type: homeConstants.FEATURED_ERROR,
            payload:console.log(e),
        })
    }
}

const getMakerCount = () => async dispatch =>{

    try {
        const res  = await axiosConfig.get('/home/maker-count');
        dispatch({
            type : homeConstants.MAKER_COUNT,
            payload: res.data
        })

    }catch (e){
        dispatch({
            type: homeConstants.MAKER_COUNT_ERROR,
            payload:console.log(e),
        })
    }
}

const getCountryCount = () => async dispatch =>{

    try {
        const res  = await axiosConfig.get('/home/country-count');
        dispatch({
            type : homeConstants.COUNTRY_COUNT,
            payload: res.data
        })

    }catch (e){
        dispatch({
            type: homeConstants.COUNTRY_COUNT_ERROR,
            payload:console.log(e),
        })
    }
}

export {getFeatured , getMakerCount,getCountryCount};