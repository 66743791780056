export const countries = [
    {name : 'Antigua And Barbuda'},
    {name : 'Aruba'},
    {name : 'Bahamas The'},
    {name : 'Barbados'},
    {name : 'Botswana'},
    {name : 'Burundi'},
    {name : 'Cayman Islands'},
    {name : 'Comoros'},
    {name : 'Congo'},
    // {name : 'C-The Democratic Republic Of The'},
    {name : 'Cyprus'},
    {name : 'Dominican Republic'},
    {name : 'Fiji Islands'},
    {name : 'Grenada'},
    {name : 'Guyana'},
    {name : 'Jamaica'},
    {name : 'Japan'},
    {name : 'Kenya'},
    {name : 'Korea North'},
    {name : 'Korea South'},
    {name : 'Malawi'},
    {name : 'Mauritius'},
    {name : 'New Zealand'},
    {name : 'Pakistan'},
    {name : 'Palau'},
    // {name : 'Saint Kitts And Nevis'},
    {name : 'Saint Lucia'},
    // {name : 'Saint Vincent And The Grenadines'},
    {name : 'Samoa'},
    {name : 'South Sudan'},
    {name : 'Suriname'},
    {name : 'Tanzania'},
    {name : 'Tonga'},
    {name : 'Trinidad And Tobago'},
    {name : 'Uganda'},
    {name : 'United Arab Emirates'},
    {name : 'Zambia'}
];