import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";


class PaginationItem extends Component {
    constructor(props)
    {
        super(props);
    }
    paginate(e) {
        e.preventDefault();
        this.props.onclick(this.props.page);
    }
    render() {
        return this.props.show ? (                             
            <li className={this.props.active ? 'wo-active' : ''}>
                <a  onClick={this.paginate.bind(this) } style={{cursor : "pointer"}}>{this.props.title}</a>
            </li>
        ) : null;
    }
}

const mapDispatchToProps = dispatch => ({
    
  })
  
  /* 
   * mapStateToProps
  */
  const mapStateToProps = state => ({
    ...state,
  })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginationItem));