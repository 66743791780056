import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { countries } from '../../data/countries'
// import common from '../../helpers/common';
import QuoteModels from "../models/QuoteModels";
import ZendeskChat from '../partials/ZendeskChat';
import FloatingWhatsApp from 'react-floating-whatsapp'

const mapDispatchToProps = dispatch => ({

})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
})


class Footer extends Component {
    componentDidMount() {

        // common.appendScript('assets/js/main.js')
    }
    render() {

        const countrieslist = []
        countries.map((data, key) =>
            countrieslist.push(
                <li key={data.name}>
                    <Link to={"/country/" + data.name}>{data.name}</Link>
                </li>
            )
        );
        return (
            <>
                <FloatingWhatsApp
                    phoneNumber="+81345720332"
                    accountName="CFBAJapan - CS"
                    allowClickAway
                    styles={{ right: '150px', bottom: '10px' }}
                />


                {/*zendesk chat disable */}
                {/*<ZendeskChat />*/}



                {/*FOOTER START */}
                <footer className="wo-footer-wrap">

                    <div className="wo-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="wo-fpostwrap d-none">
                                        <div className="wo-fpostwrap__title">
                                            <h4>Planning To Upgrade Your Car?</h4>
                                            <h4>Its Easy Just <span>Search, Compare & Vrooom</span></h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="wo-fpost">
                                                    <img src="/assets/images/footer/img-03.jpg" alt="img description" />
                                                    <h4>Best Price To Offer</h4>
                                                    <span>Consectetur adipisicing elitem sed do eiusmod tempor incididunt ut labore et dolore</span>
                                                    <a href="/">Post Vehicle</a>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="wo-fpost">
                                                    <img src="/assets/images/footer/img-02.jpg" alt="img description" />
                                                    <h4>Quality of Vehicles</h4>
                                                    <span>Consectetur adipisicing elitem sed do eiusmod tempor incididunt ut labore et dolore</span>
                                                    <a href="/">Inspect Your Car</a>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="wo-fpost">
                                                    <img src="/assets/images/footer/img-01.jpg" alt="img description" />
                                                    <h4>Secure Way of Payment</h4>
                                                    <span>Consectetur adipisicing elitem sed do eiusmod tempor incididunt ut labore et dolore</span>
                                                    <a href="/">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wo-fjoinnow d-none">
                                        <div className="wo-fjoinnow__title">
                                            <h4>Looking To Sale Your Car? <span>Join Today & Get Best Offers</span></h4>
                                        </div>
                                        <div className="wo-fjoinnow__info">
                                            <p>Consectetur adipisicing elitem sed do eiusmod tempor incididunt bore et dolore aliqua enim ad minim veniam quis</p>
                                        </div>
                                        <div className="wo-fjoinnow__btn">
                                            <a href="signup.html" className="wo-btn">Join Now</a>
                                        </div>
                                        <div id="particlesfooter-js" className="wo-particles particles-js"></div>
                                    </div>
                                    <div className="wo-fjoinnow d-none" id="fmap">
                                        <div className="wo-fjoinnow__title">
                                            <h4>Our location </h4>
                                            <strong className="wo-f-logo">
                                                <Link to="/"><img src="https://cfbajapan.com/custom-theme/images/logo.png" alt="img description" /></Link>
                                            </strong>
                                        </div>
                                        <div className="wo-fjoinnow__info">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14478.355046686564!2d67.0686981!3d24.8778913!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70423858f50df22f!2sePlanet%20Communications%20Inc!5e0!3m2!1sen!2s!4v1629118404088!5m2!1sen!2s" allowFullScreen="" loading="lazy"></iframe>
                                        </div>
                                        <div id="particlesfooter-js" className="wo-particles particles-js"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <strong className="wo-f-logo">
                                        <Link to="/"><img src={process.env.REACT_APP_BASE_IMG + "logo.png"} alt="img description" /></Link>
                                    </strong>
                                    <div className="row no-gutters">
                                        <div className="col-sm-4 col-lg-3 pr-3">
                                            <div className="wo-fwidget addr">

                                                <div className="wo-fwidget__title">
                                                    <h5>Get In Touch</h5>
                                                </div>
                                                <ul className="wo-fwidget__content">
                                                    <li className=""><a href="mailto:customer.support@cfbajapan.com"><i className="fas fa-envelope"></i>  customer.support@cfbajapan.com</a></li>
                                                    <li className=""><a href="tel:+81-368-514-321"><i className="fas fa-phone-square-alt"></i>  +81-368-514-321</a>
                                                    </li>
                                                    <li ><i className="fas fa-map-marker-alt"></i> Ota Shi Obara Cho 385-51 Gunma Ken Japan</li>
                                                </ul>
                                            </div>
                                            <div className="wo-fwidget addr mt-3">
                                                <div className="wo-fwidget__title">
                                                    <h5>Working Hours</h5>
                                                </div>
                                                <ul className="wo-fwidget__content">
                                                    <li>24/7</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="customwidth">
                                            <div className="wo-fwidget addr">
                                                <div className="wo-fwidget__title">
                                                    <h5>Useful Links</h5>
                                                </div>

                                                <ul className="wo-fwidget__content">
                                                    <li><Link to={"/"} >Home</Link></li>
                                                    <li><Link to={"/about"}>About</Link></li>
                                                    <li><Link to={"/stock"}>Stock</Link></li>
                                                    {/* <li><a href="/">Search</a></li> */}
                                                    <li><Link to={"/sale-process"}>How to Buy</Link></li>
                                                    <li><Link to={"/auction-system"}>Auction</Link></li>
                                                    <li><Link to={"/auto-parts"}>Auto Parts</Link></li>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-6  col-lg-5">
                                            <div className="wo-fwidget__title ">
                                                <h5>Find Used & New Cars in JAPAN</h5>
                                            </div>
                                            <ul className="ul-call">
                                                <li>
                                                    <img src="/assets/images/footer/icon2.png" alt="img description" />
                                                    <span> Inspection of every vehicle</span>

                                                </li>

                                                <li>
                                                    <img src="/assets/images/footer/icon1.png" alt="img description" />
                                                    <span> Fast and Safe Delivery</span>

                                                </li>

                                                <li>
                                                    <img src="/assets/images/footer/icon3.png" alt="img description" />
                                                    <span>24/7 Customer Service</span>

                                                </li>

                                                <li>
                                                    <img src="/assets/images/footer/icon4.png" alt="img description" />
                                                    <span> Regularly Updated Stock</span>

                                                </li>
                                            </ul>
                                            <ul className="socialFooter">
                                                <li><a href="javascript:$zopim.livechat.window.show();"><img src="/assets/images/footer/expert.png" alt="img description" /> Chat With Expert</a></li>
                                                <li><Link to={{ pathname: "https://www.facebook.com/CFBAJapan-100466285436401" }} target="_blank"><img src="/assets/images/footer/facebook.png" alt="img description" /> Facebook</Link></li>
                                                <li><Link to={{ pathname: "https://api.whatsapp.com/send?phone=+81345720332&text=Hi%20!!" }} target="_blank"><img src="/assets/images/footer/whatsapp.png" alt="img description" /> WhatsApp Chat</Link></li>
                                                <li><Link to={{ pathname: "viber://chat?number=+818098746969" }} target="_blank"><img src="/assets/images/footer/viber.png" alt="img description" /> Viber</Link></li>

                                                <li><Link to={'#'}><img src="/assets/images/footer/quote.png" alt="img description" /> Request a Custom Quote</Link></li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-lg-2">
                                            <div className="wo-fwidget addr">
                                                <div className="wo-fwidget__title">
                                                    <h5>Map</h5>
                                                </div>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.2802955174902!2d139.28888721558735!3d36.35398900038826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601ee173a4571af3%3A0x5c7c2207e7ebeb73!2sAli%20Agha%20Trading%20Co.Ltd!5e0!3m2!1sen!2s!4v1630481809521!5m2!1sen!2s" allowFullScreen="" loading="lazy"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wo-footer-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="wo-footernav-content">
                                        <p className="wo-copyrights">Copyrights © 2021 by <a href="/">CFBAJapan</a> All Rights Reserved.</p>
                                        <nav className="wo-footernav">
                                            <ul>
                                                <li><a href="/">Legal Privacy</a></li>
                                                <li><a href="/">Terms &amp; Conditions</a></li>
                                                <li><a href="/">Support & Help</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/*FOOTER END */}
                {/*LOGIN POPUP START */}


                <div className="modal fade wo-loginpopup" tabIndex="-1" role="dialog" id="loginpopup" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Login Now</h4>
                                <a href="/" className="close"><i className="ti-close"
                                    data-dismiss="modal"></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Your Username:</label>
                                            <input type="text" className="form-control" name="name"
                                                placeholder="Enter usernam/email*" required="" />
                                        </div>
                                        <div className="form-group wt-eyeicon">
                                            <label className="wo-titleinput">Your Password:</label>
                                            <input type="password" id="typePassa" className="form-control" name="asd"
                                                placeholder="Enter password*" required="" />
                                            {/* <a href="/" onClick="passwordFunction('typePassa')"><i className="ti-eye"></i></a> */}
                                            <a href="/" ><i className="ti-eye"></i></a>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <div className="wo-checkbox">
                                                <input id="remember" type="checkbox" />
                                                <label htmlFor="remember"><span>Trust this device and save my login details</span></label>
                                            </div>
                                            <button type="submit" className="wo-btn">Login</button>
                                        </div>
                                    </fieldset>
                                </form>
                                <span className="wo-optionsbar"><em>or</em></span>
                                <div className="wo-loginicon">
                                    <label className="wo-titleinput">Login Via Social Network:</label>
                                    <ul>
                                        <li><a href="/" className="wo-facebookbox"><span><i
                                            className="fab fa-facebook-f"></i></span>Via facebook</a></li>
                                        <li><a href="/" className="wo-googlebox"><span><i
                                            className="fab fa-google"></i></span>Via google</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-footer wo-loginfooterinfo">
                                <a href="/"><em>Not a member?</em> Signup Now</a>
                                <a href="/"><em>Forgot Password?</em> Reset Now</a>
                            </div>
                        </div>
                    </div>
                </div>

                {/*LOGIN POPUP END */}
                {/*INQURY POPUP START */}

                {/*<QuoteModels />*/}

                {/*INQURY POPUP END */}
                {/*Inspection POPUP START */}

                <div className="modal fade wo-inspectionpopup" tabIndex="-1" role="dialog" id="inspectionpopup" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Get Your Free Car Inspection</h4>
                                <a href="/" className="close"><i className="ti-close"
                                    data-dismiss="modal"></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-inspectionform">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Vehicle Model Year:</label>
                                            <div className="wo-select">
                                                <select id="vehicle-condition9" className="form-control">
                                                    <option hidden="">Select year:</option>
                                                    <option>2002</option>
                                                    <option>2003</option>
                                                    <option>2004</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Vehicle Info:</label>
                                            <div className="wo-rangeselect">
                                                <div className="wo-select">
                                                    <select id="vehicle-condition10" className="form-control">
                                                        <option hidden="">Choose make</option>
                                                        <option>Wondai City</option>
                                                        <option>Karuti</option>
                                                        <option>Wagoz R VXL</option>
                                                    </select>
                                                </div>
                                                <div className="wo-select">
                                                    <select id="vehicle-condition11" className="form-control">
                                                        <option hidden="">Choose model</option>
                                                        <option>Family</option>
                                                        <option>Big</option>
                                                        <option>Small</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Your Full Name:</label>
                                            <input type="text" className="form-control" name="name"
                                                placeholder="Enter Your Name" required="" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Your Phone No.:</label>
                                            <input type="number" className="form-control" name="num"
                                                placeholder="Enter phone number" required="" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Your Email ID.:</label>
                                            <input type="email" className="form-control" name="email"
                                                placeholder="Enter email address" required="" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Your Address:</label>
                                            <div className="wo-form-icon">
                                                <input type="email" className="form-control" name="address"
                                                    placeholder="Add your location" />
                                                <a href="/"
                                                    className="wo-right-icon wo-distanceicon"><i
                                                        className="ti-angle-down"></i></a>
                                                <a href="/" className="wo-right-icon"><i
                                                    className="ti-target"></i></a>
                                                <span className="wo-distance">
                                                    <span className="wo-distance__description">
                                                        <label htmlFor="formControlRanged">Distance:</label>
                                                        <input className="form-control-range input-range" id="formControlRanged"
                                                            data-slider-id='ex1Slider1' type="text" data-slider-min="0"
                                                            data-slider-tooltip="always" data-slider-max="100"
                                                            data-slider-step="1" data-slider-value="50" required="" />
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <div className="wo-checkbox">
                                                <input id="terms" type="checkbox" />
                                                <label
                                                    htmlFor="terms"><span>Details I provided above are all real &amp; correct and <a
                                                        href="/">I agree wtih all the terms</a> </span></label>
                                            </div>
                                            <button type="submit" className="wo-btn">Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Inspection POPUP END */}
                {/* VIDEO POPUP START*/}
                <div className="modal fade wo-videopopup" tabIndex="-1" role="dialog" id="videopopup">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="modal-body">
                                <a href="/" className="close"><i className="ti-close"
                                    data-dismiss="modal"></i></a>
                                {/* <iframe width="640" height="360"
                                      src="https://www.youtube.com/embed/XxxIEGzhIG8?autoplay=1"
                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/*VIDEO POPUP END*/}
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
