import {Component} from "react";
import {connect} from "react-redux";

class PageNotFound404 extends Component{
    constructor(props){
        super(props);

    }
    render() {
       return (
            <h1>PAGE NOT FOUND!</h1>
       );
    }
}

const mapDispatchToProps = dispatch => ({
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
})
export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound404);