import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { countries } from '../../data/countries'
// import common from '../../helpers/common';
import MetaHelmet from '../partials/widgets/MetaHelmet';

class BankDetails extends Component {
    componentDidMount() {
        // common.appendScript('assets/js/main.js')
    }
    
    render() {
        let metaHelmet = {
            title: `Bank Details`,
            description: ''
          }
        return (
            <>
                <MetaHelmet {...metaHelmet} />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 bank-detail">
                            <p>CFBA Japan provides the most flexible payment options in the industry. To proceed forward, you
                                need to make the payment as per the invoice sent to you. Listed below is the official Bank Account
                                information for CFBA Japan.</p>
                        </div>


                        <div className="col-md-12 col-sm-12 col-xs-12 mb20">
                            <div className="main-bank-detail">
                                <h3>Banking Details</h3>
                            </div>
                            <div className="row no-gutters ">
                                <div className="col-md-2 col-sm-4 col-xs-12 bank-tab">
                                    <h3>ACCOUNT TITLE</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 details-tab">
                                    <h3>ALI AGHA TRADING LTD</h3>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-2 col-sm-4 col-xs-12 bank-tab">
                                    <h3>ACCOUNT NUMBER</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 details-tab">
                                    <h3>4252820</h3>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-2 col-sm-4 col-xs-12 bank-tab">
                                    <h3>NAME OF BANK</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 details-tab">
                                    <h3>SUMITOMO MITSUI BANKING CORPORATION (SMBC)</h3>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-2 col-sm-4 col-xs-12 bank-tab">
                                    <h3>SWIFT CODE</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 details-tab">
                                    <h3>SMBCJPJT</h3>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-md-2 col-sm-4 col-xs-12 bank-tab">
                                    <h3>BRANCH</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 details-tab">
                                    <h3>OTA BRANCH</h3>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-2 col-sm-4 col-xs-12 bank-tab">
                                    <h3>BRANCH CODE</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 details-tab">
                                    <h3>032</h3>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-md-2 col-sm-4 col-xs-12 bank-tab">
                                    <h3>BANK ADDRESS</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 details-tab">
                                    <h3>1386, DA-CHO OTA-CITY GUNMA JAPAN</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12 payment-note">
                            <h3>Payment Note</h3>
                            <p>ALL BANK CHARGES WILL BE PAID BY CUSTOMER. PLEASE MAKE THE TRANSACTION WITHIN 2 DAYS AFTER RECEIVING
                                THE INVOICE.</p>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({

})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
})

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);
