import {
    homeConstants,
    COUNTRIES_LIST,
    COUNTRIES_LIST_ERROR,
    SHIPPING_SCHEDULE,
    SHIPPING_SCHEDULE_ERROR,
    customer, CLIENT_REVIEW_EXISTS, CLIENT_REVIEW_EXISTS_ERROR
} from '../constants/index'
import axiosConfig from '../axiosConfig'


const getMakers = () => async dispatch => {
    
    try{
        const res = await axiosConfig.get(`/search/makers`)
        dispatch( {
            type    : homeConstants.MAKERS,
            payload : res.data
        })
    }
    catch(e){
        dispatch( {
            type    : homeConstants.MAKERS_ERROR,
            payload : console.log(e),
        })
    }

}

const getModels = () => async dispatch => {
    
    try{
        const res = await axiosConfig.get(`/search/models`)
        dispatch( {
            type    : homeConstants.MODELS,
            payload : res.data
        })
    }
    catch(e){
        dispatch( {
            type    : homeConstants.MODELS_ERROR,
            payload : console.log(e),
        })
    }

}


const getCountries = () => async dispatch => {
    
    try{
        const res = await axiosConfig.get(`/common/countries`)
        dispatch( {
            type    : COUNTRIES_LIST,
            payload : res.data
        })
    }
    catch(e){
        dispatch( {
            type    : COUNTRIES_LIST_ERROR,
            payload : console.log(e),
        })
    }

}

const getShippinSchedule = (id) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/common/shipping-schedule?zone_id=${id}`)
        dispatch( {
            type    : SHIPPING_SCHEDULE,
            payload : res.data
        })
    }
    catch(e){
        dispatch( {
            type    : SHIPPING_SCHEDULE_ERROR,
            payload : console.log(e),
        })
    }

}

const getZone = () => async dispatch => {

    try{
        const res = await axiosConfig.get(`/common/zone`)
        dispatch( {
            type    : homeConstants.ZONE,
            payload : res.data
        })
    }
    catch(e){
        dispatch( {
            type    : homeConstants.ZONE_ERROR,
            payload : console.log(e),
        })
    }

}

const getTrustedClient = () => async dispatch => {

    try{
        const res = await axiosConfig.get(`/common/client-trusted-rate`)
        dispatch( {
            type    : homeConstants.TRUSTED_CLIENT,
            payload : res.data
        })
    }
    catch(e){
        dispatch( {
            type    : homeConstants.TRUSTED_CLIENT_ERROR,
            payload : console.log(e),
        })
    }

}


const checkClientReviewExists = (car_id,client_id) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/common/check-client-review?car_id=`+car_id+`&client_id=`+client_id)
        dispatch( {
            type    : CLIENT_REVIEW_EXISTS,
            payload : res.data
        })
    }
    catch(e){
        dispatch( {
            type    : CLIENT_REVIEW_EXISTS_ERROR,
            payload : console.log(e),
        })
    }

}


const updateLoginResp = (payload) => async dispatch =>  {
    return dispatch({ type: customer.LOGIN_UPDATE, payload })
}


export {getMakers , getModels, getCountries,getShippinSchedule,updateLoginResp,getZone,checkClientReviewExists,getTrustedClient};