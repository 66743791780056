export const detailPayload =
    {
        client: {
          fullname: '',
          country: {
            name: ''
          }
        },
        serial : '',
        bid: {
          media: [],
          car_maker: {
            title : ''
          },
          car_model: {
            title : ''
          },
          car_color: {
            title : ''
          },
          attributes: {
            selling_price :''
          },
          year_month : ''
        },
        logistic_info:{
          destination_port : {
            title : ''
          },
          yard: {
            title : ''
          }
        }
      };