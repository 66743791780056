import React, {Component} from 'react'
import Select from "react-dropdown-select";
import {connect} from "react-redux";
import {getCountry,registerAction,otpAction} from "../../actions/customerAction";
import { withRouter } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneField from '../partials/widgets/PhoneField'

class Register extends Component {
    constructor(props) {
        super(props)
        let loginSession  = this.props.loginresp
        // let loginSession = JSON.parse(localStorage.getItem('login-session'));

        if(loginSession != undefined && loginSession.isLoggedIn === true){
            this.props.history.push('/user/profile');
        }

        this.state = {
            fullname    : '',
            password    : '',
            email       : '',
            phone       : loginSession.client_detail.telephone,
            country_id  : '',
            client_detail  : {
                country :{
                    code : 'JP'
                }
            },
            address     : '',
            nameError   : '',
            passError   : '',
            emailError  : '',
            phoneError  : '',
            success     : '',
        }
    }

    componentDidMount() {
        this.props.getCountries();
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    dropDown = selectedOption => {
        if(selectedOption[0]['label'] !== undefined){
            this.setState(state => {
                state.country_id = selectedOption[0]['value']
                state.client_detail.country.code = selectedOption[0]['label'].split(" - ")[1]
                return state
            },() => console.log())
        }
    };

    enterKeySubmit = (e) => {
        e.preventDefault();
        let loginSession  = this.props.loginresp
        const data = {
            'fullname'    : this.state.fullname,
            'password'    : this.state.password,
            'email'       : this.state.email,
            'phone'       : loginSession.client_detail.telephone,
            'country_id'  : this.state.country_id,
            'country_code' : this.state.client_detail.country.code,
            'address'     : this.state.address,
        }
        this.props.register(data)
        this.setState({
            success    : '',
            nameError  : '',
            passError  : '',
            emailError : '',
            phoneError : '',
        })

    }
    onSubmit = (e) => {
        e.preventDefault();
        let loginSession  = this.props.loginresp
        const data = {
            'fullname'    : this.state.fullname,
            'password'    : this.state.password,
            'email'       : this.state.email,
            'phone'       : loginSession.client_detail.telephone,
            'country_id'     : this.state.country_id,
            'address'     : this.state.address,
        }
        this.props.register(data)
        this.setState({
            success    : '',
            nameError  : '',
            passError  : '',
            emailError : '',
            phoneError : '',
        })
    }

    login(e) {
        e.preventDefault();
        this.props.history.push('/login');
    }
    componentWillReceiveProps(nextProps){
        const registerRes = nextProps.registerRes
        if(registerRes !== undefined){
            if(registerRes.status === true){
                this.setState({
                    success    : registerRes.message,
                    nameError  : '',
                    passError  : '',
                    emailError : '',
                    phoneError : '',
                    fullname   : '',
                    password   : '',
                    email      : '',
                    phone      : '',
                    country_id : '',

                },()=>{
                })
                registerRes.message = ''
                // this.props.history.push('/login');

            }else{
                if(registerRes.errors != undefined){
                    this.setState({
                        success    :'',
                        passError  :registerRes.errors.password,
                        nameError  :registerRes.errors.fullname,
                        emailError :registerRes.errors.email,
                        phoneError :registerRes.errors.phone,
                    })
                }else{
                    this.setState({
                        success    :'',
                        passError  :'Something went wrong . Please try again later!',
                        
                    })
                }
             
            }
        }
    }

    render() {
        let loginSession = this.state.loginSession;
        const countries  = this.props.countries;
        let notification = ''
        if(this.state.success !== ''){
           notification = <ToastContainer>
                {
                    toast.success(this.state.success, {
                        position    : "top-right",
                        autoClose   : 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable   : true,
                        progress    : undefined,
                    })
                }
            </ToastContainer>
        }
        return (
            <>
            {notification}
                 <div className="container ">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-12">
                            <div className="wo-settingsave">
                                <div className="wo-settingsave__title">
                                    <h2>Register</h2>
                                </div>
                            </div>
                            <div className="wo-dashboardbox">
                                <form className="wo-themeform wo-profileform wo-profileformvtwo">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Your Full Name*:</label>
                                            <input type="text"
                                                   onKeyPress={event => {
                                                       if (event.key === 'Enter') {
                                                           this.enterKeySubmit(event)
                                                       }
                                                   }}
                                                   value={this.state.fullname} className="form-control" onChange={e => this.handleChange(e)} name="fullname" placeholder="Enter name*" />
                                        </div>
                                        <div className="form-group wo-inputicon">
                                            <label className="wo-titleinput">Password:</label>
                                            {/*<i className="ti-info-alt tippy" />*/}
                                            <input type="password"
                                                   onKeyPress={event => {
                                                       if (event.key === 'Enter') {
                                                           this.enterKeySubmit(event)
                                                       }
                                                   }}
                                                   value={this.state.password} className="form-control" onChange={e => this.handleChange(e)} name="password" placeholder="Password" />
                                        </div>
                                        <div className="form-group wo-inputicon">
                                            <label className="wo-titleinput">Your Email ID*:</label>
                                            {/*<i className="ti-lock" />*/}
                                            <input type="email"
                                                   onKeyPress={event => {
                                                       if (event.key === 'Enter') {
                                                           this.enterKeySubmit(event)
                                                       }
                                                   }}
                                                   value={this.state.email} className="form-control" onChange={e => this.handleChange(e)} name="email" placeholder="christeen772@mail.com" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Country:</label>
                                            <Select className="form-control"
                                                    onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            this.enterKeySubmit(event)
                                                        }
                                                    }}
                                                    value={this.state.country_id} options={countries} name={"country_id"}  onChange={this.dropDown}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Mobile No*:</label>
                                            <PhoneField phone={this.state.phone} country={this.state.client_detail.country} countrySelectProps={{ unicodeFlags: true , disabled:true}}/>
                                            {/* <input type="number"
                                                   onKeyPress={event => {
                                                       if (event.key === 'Enter') {
                                                           this.enterKeySubmit(event)
                                                       }
                                                   }}
                                                   value={this.state.phone} className="form-control" onChange={e => this.handleChange(e)} name="phone" placeholder="+1 4256 578 68" />
                                            */}
                                         </div>
                                        <div className="form-group wo-locationtag">
                                            <label className="wo-titleinput">Address*:</label>
                                            <input type="text"
                                                   onKeyPress={event => {
                                                       if (event.key === 'Enter') {
                                                           this.enterKeySubmit(event)
                                                       }
                                                   }}
                                                   value={this.state.address} className="form-control" onChange={e => this.handleChange(e)} name="address" placeholder="Enter Address*" />

                                        </div>
                                        <div className="form-group wo-locationtag uncompleted">
                                            <span className={'text-danger'}>{this.state.nameError}{this.state.passError} {this.state.emailError} {this.state.phoneError}</span>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                        <button onClick={(e) => this.onSubmit(e)} className='wo-btn'>Register</button>
                                        </div>
                                    </fieldset>
                                    
                                </form>
                            </div>
                            {/*<div className="wo-dashboardbox">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label className="wo-titleinput">Profile Image:</label>*/}
                            {/*        <input type="file" className="form-control" name="profile_image" placeholder="Profile Image" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                     </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCountries   : () => dispatch(getCountry()),
    register       : (jsondata) => dispatch(registerAction(jsondata)),
    otp             : (jsondata) => dispatch(otpAction(jsondata))
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    countries    : state.customerReducer.countries,
    registerRes  : state.customerReducer.registerRes,
    loginresp  : state.customerReducer.loginresp,

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
