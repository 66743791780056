import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import common from '../../helpers/common'
import ReactPlayer from "react-player";
import ReactStars from "react-rating-stars-component";

class ReviewCarousel extends Component {

    render() {
        const {review} = this.props;
        let baseImageUrl = process.env.REACT_APP_BASE_IMG+'review/'+review.id+'/';
        let sliderimages    = [];
        let reviewList      = []

        const firstExample = {
            size: 30,
            value: review.rate,
            edit: false,
            activeColor: 'red',
        };

        if(review.media.length > 0){
            review.media.map(media =>
                {
                    let fileExtension = media.fileName.split('.').pop();
                    if (fileExtension !== "jpg" && fileExtension !== "jpeg" && fileExtension !== "png"){
                        sliderimages.push(
                            <div key={media.id} width="600px" height="200px" className='RCarouselItem'>
                                <video  controls>
                                    <source src={baseImageUrl+media.fileName} type="video/mp4"/>
                                </video>
                            </div>
                        )
                    }else {
                        sliderimages.push(
                            <div key={media.id} width="600px" height="200px" className='RCarouselItem'>
                                <img className="img-fluid" src={baseImageUrl+media.fileName}/>
                            </div>
                        )
                    }
                }
            )
        }else{
            sliderimages.push(
                <div className='RCarouselItem'>
                    <img src="https://www.carfitexperts.com/car-models/wp-content/uploads/2019/01/zen-1.jpg"/>
                </div>
            )
        }

        reviewList.push(
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="wo-modalcontent modal-content modal-lg">
                    <div className="wo-popuptitle">
                        <h4>Reviews and Feedback!</h4>
                        {/*<a  className="close"><i className="ti-close"></i></a>*/}
                    </div>
                    <div className="modal-body">
                <div className="row">
                    <div className="col-md-6">
                        <Carousel>
                            {sliderimages}
                        </Carousel>
                    </div>
                    <div className="col-md-6">
                        <div className="review-block-rate">
                            <ReactStars {...firstExample} />
                        </div>
                        <blockquote className="blockquote">
                            <div className="review-block-title">{review.deal.bid.car_maker.title} {review.deal.bid.car_model.title} {review.deal.bid.car_color.title}</div>
                            <div className="review-block-description">
                                {review.description}
                            </div>
                            <span className="reviewer-name">by {review.client.fullname} ({review.client.country.name}) <br/> {review.created_at}</span>
                        </blockquote>
                    </div>
                </div>

            </div>
                 </div>
            </div>
        )
        return (
            <div>
                {reviewList}
            </div>
        );
    }
}

export default ReviewCarousel