import {Component} from "react";
import Horizontal from '../partials/Listings/layouts/Horizontal'
import {connect} from "react-redux";

import SearchSparePartBody from "../advancedSearch/SearchSparePartBody";
import common from "../../helpers/common";
import ReactGa from 'react-ga'

class Search extends Component{
    constructor(props){
        super(props);
    }
    componentDidMount(){
        ReactGa.pageview(window.location.pathname + window.location.search);
    }
    render() {
        let searchParams =  common.useQuery(this.props)
        let usedCarSearch      = searchParams.get('search')
        let usedCarType  = searchParams.get('type')

        let type = this.props.type;
        let searchBody = '';
        if(type === 'spare-parts'){
            searchBody = <SearchSparePartBody></SearchSparePartBody>
        }else if(type === 'search') {
            searchBody = <Horizontal type="search" {...this.props}/>
        }else if(type === 'category') {
            searchBody = <Horizontal type="category" {...this.props}/>
        }else if(type === 'country') {
            searchBody = <Horizontal type="country" {...this.props}/>
        }
        // else if(type === 'country') {
        //     searchBody = <Horizontal type="country" {...this.props}/>
        // }
        else if(type === 'keysearch') {
            searchBody = <Horizontal type="keysearch" {...this.props}/>
        }
        else if(type === 'bid-type') {
            searchBody = <Horizontal type="bid-type" {...this.props}/>
        }
        else if(type === 'used_car') {
            searchBody = <Horizontal type="used_car" usedCarSearch={usedCarSearch} usedCarType={usedCarType} {...this.props}/>
        }
        else if(type === 'main-search') {
            searchBody = <Horizontal type="main-search" {...this.props}/>
        }else if(type === 'prices') {
            searchBody = <Horizontal type="prices" {...this.props}/>
        }else{
            // console.log(this.props.match.params)
        }
       return (
           <>
               {searchBody}
           </>
       );
    }
}

const mapDispatchToProps = dispatch => ({
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
})
export default connect(mapStateToProps, mapDispatchToProps)(Search);