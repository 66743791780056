import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { countries } from '../../data/countries'
// import common from '../../helpers/common';
import MetaHelmet from '../partials/widgets/MetaHelmet';

class SaleProcess extends Component {
  componentDidMount() {
    // common.appendScript('assets/js/main.js')
  }
  render() {
    let metaHelmet = {
      title: `Sale Process`,
      description: ''
    }
    return (
      <>
      <MetaHelmet {...metaHelmet} />
        <div className="container my-3">
          <div className="card">
            <div className="card-header">
              <div className="tabbable-responsive">
                <div className="tabbable">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="first-tab" data-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">Overview</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false">Services</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="third-tab" data-toggle="tab" href="#third" role="tab" aria-controls="third" aria-selected="false">Security Deposit</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="fourth-tab" data-toggle="tab" href="#fourth" role="tab" aria-controls="fourth" aria-selected="false">Bidding</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="fifth-tab" data-toggle="tab" href="#fifth" role="tab" aria-controls="fifth" aria-selected="false">Auction Schedule</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="sixth-tab" data-toggle="tab" href="#sixth" role="tab" aria-controls="sixth" aria-selected="false">Auction Result</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="sixth-tab" data-toggle="tab" href="#seven" role="tab" aria-controls="sixth" aria-selected="false">Freight</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="sixth-tab" data-toggle="tab" href="#eight" role="tab" aria-controls="sixth" aria-selected="false">Final Payment</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="sixth-tab" data-toggle="tab" href="#nine" role="tab" aria-controls="sixth" aria-selected="false">Documents</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">

              <div className="col-md-12 col-sm-12 col-xs-12 tab-content clearfix">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>Overview</h2>
                      <h3>Buy Used Cars Easily From Japanese Auction Houses</h3>
                      <p>CFBA Japan assists its customer with the broad and complex Auction system, making
                        the procedures easier, enabling them to access and purchase vehicles at reasonable
                        prices. At CFBA Japan, we pledge to find the perfect car for you; our platform
                        will allow you to browse through thousands of cars, daily, from leading auction
                        houses all across Japan. You will now be able to choose the car of your choice, from
                        the convenience of your computer screen.</p>
                      <p>With the assistance of our highly competent team, available 24/7 to make your
                        purchase experience as hassle-free and convenient as possible.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Overview.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>SERVICES</h2>
                      <h3>Have A Look At Our Services</h3>
                      <p>CFBA Japan commits to provide continuous service to its customers throughout the process till the car is delivered. If you are not sure about the import regulations
                        of your country or have doubts about the car, we have got you covered; our team of specialists are available to make you aware of the import policies of your countryas well as translate the auction sheet to make things easier for you.</p>

                      <p>We check and translate the auction sheet of your desired car to provide you with a clear picture of the car you are looking to buy.</p>

                      <p>With the assistance of our highly competent team, available 24/7 to make your
                        purchase experience as hassle-free and convenient as possible.</p>

                      <p className="color-p">Important Notice</p>
                      <ul>
                        <li>Bids that include additional bids are required to be placed 1 day prior to the
                          initiation of bidding.
                        </li>
                        <li>Buyers cannot lower the bid price one hour before the bidding start.</li>
                        <li>No changes in price and cancellations will accepted outside the auction
                          system.
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Services.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="third" role="tabpanel" aria-labelledby="third-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>SECURITY DEPOSIT</h2>
                      <h3>Security Deposit For Booking Your Car</h3>
                      <p>To take part in the auction, a fixed amount is required to be deposited by you.</p>
                      <p>Your amount will be secure with us and will be refunded in full in case of anunsuccessful bid. The amount will only be used to reserve the car when the biddingis won.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Security-Deposit.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="fourth" role="tabpanel" aria-labelledby="fourth-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>BIDDING</h2>
                      <h3>Place A Bid At Auction</h3>
                      <p>Place a bid on the car of your choice at our auction website, and let our agents bid on your behalf.</p>
                      <p>Place a higher bid to increase your chances of winning, as you will only be charged with the price of a successful bid.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Bidding.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="fifth" role="tabpanel" aria-labelledby="fifth-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>AUCTION SCHEDULE</h2>
                      <h3>Place A Bid At Auction</h3>
                      <p>we keep our customers updated about all upcoming auctions at the best auction houses in japan. at cfba japan, the customers’ ease and satisfaction is our number one priority. by providing a weekly schedule, we aim to satisfy our clients, allowing them to choose from a broader selection and book the car they desire.</p>
                      <p>through our auction schedule, we bring to you all renowned japanese used car auctions, listed in a single platform. not being able to find the car of your choice is no longer a problem; our auction schedule helps you access a vast variety of cars that will be up for auction in the entire week, so you can find the car of your choice, in the color you want and still stay in you budget.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Auction-Schedule.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="sixth" role="tabpanel" aria-labelledby="sixth-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>AUCTION RESULT</h2>
                      <h3>We Keep You Updated With The Auction Result</h3>
                      <p>You will be notified about the result of your bid. We will send you a porforma nvoice once you win the bid. The invoice will contain the total price including the
                        rice of car and Free-on-board (FOB) or Cost & Freight (C&F) depending upon the buying price stated at the auction.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Auction-Result.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="seven" role="tabpanel" aria-labelledby="sixth-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>FREIGHT</h2>
                      <h3>Transparent Freight Charges</h3>
                      <p>At cfba japan, we guarantee the best prices with no hidden charges. we believe in mutual trust and want you to believe your transaction is safe hands with us. you can expect complete transparency in all cost incurred and updation of any changes..</p>
                      <p className="color-p">CNF Price</p>
                      <p>Cost and freight (CNF) includes the buying price, Freight, and service charges</p>
                      <img src="/assets/images/innerpages/img-cnf-price.png" alt="img description"></img>
                      <p>Note: Shipment and import cost may vary according to the rules of the destination country</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Freight.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="eight" role="tabpanel" aria-labelledby="sixth-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>FINAL PAYMENT</h2>
                      <h3>Buy Used Cars Easily From Japanese Auction Houses</h3>
                      <p>Customers can make payments through bank transfer. all bank charges are to be borne by the customer. the payment must be done in either us dollar or japanese yen.</p>
                      <p>The accumulated payments and charges are finalized after mutual consensus with the customer. the shipment will only be dispatched once our agents have received the payment confirmation.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Final-payment.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="nine" role="tabpanel" aria-labelledby="sixth-tab">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 auction-text">
                      <h2>DOCUMENTS</h2>
                      <h3>We’ve Got Your Documentation Covered</h3>
                      <p>all required documents will be sent to you via courier after the shipment is sent.</p>
                      <p className="color-p">List Of Documents:</p>
                      <ul>
                        <li>Export Certificate</li>
                        <li>Bill Of Lading</li>
                        <li>Final Invoice</li>
                        <li>Marine Insurance (if required)</li>
                      </ul>
                      <p>Note:There are no extra or hidden charges other than C&amp;F or CIF charges.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 auction-img">
                      <img src="/assets/images/innerpages/Documents.jpg" alt="img description"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({

})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state,
})


export default connect(mapStateToProps, mapDispatchToProps)(SaleProcess);
