import {customer, dealConstants, dealQuote} from '../constants/index'
import axiosConfig from '../axiosConfig'
import common from "../helpers/common";
// import common from '../../src/helpers/common'
// import axios from "axios";
// import instance from "../axiosConfig";

const getClientDeals = (id,page =1,limit = 15) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/deals/client-list?id=${id}&limit=${limit}&page=${page}`)
        dispatch( {
            type   : customer.CLIENT_DEAL,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type   : customer.CLIENT_DEAL_ERROR,
            payload: console.log(e),
        })
    }
}

const getClientRemittance = (id,page =1,limit= 15) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/remittance/client-remittance?id=${id}&limit=${limit}&page=${page}`)
        dispatch( {
            type: customer.REMITTANCES,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.REMITTANCES_ERROR,
            payload: console.log(e),
        })
    }
}

const getStates = () => async dispatch => {
    try{
        const res = await axiosConfig.get(`/common/states`)
        dispatch( {
            type: customer.STATES,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.STATES_ERROR,
            payload: console.log(e),
        })
    }
}
const getCountry = () => async dispatch => {
    try{
        const res = await axiosConfig.get(`/common/countries`)
        dispatch( {
            type: customer.COUNTRIES,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.COUNTRIES_ERROR,
            payload: console.log(e),
        })
    }
}
const getCity = () => async dispatch => {
    try{
        const res = await axiosConfig.get(`/common/cities`)
        dispatch( {
            type: customer.CITIES,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.CITIES_ERROR,
            payload: console.log(e),
        })
    }
}

const getDashboardCount = (client_id) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/common/dashboard-count?client_id=`+client_id)
        dispatch( {
            type: customer.DASHBOARD,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.DASHBOARD_ERROR,
            payload: console.log(e),
        })
    }
}


const updateProfileAction = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/customer/store-profile',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.UPDATE_PROFILE,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.UPDATE_PROFILE,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.UPDATE_PROFILE_ERROR,
            payload: e,
        })
    }
}

const registerAction = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/customer/register',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.REGISTER,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.REGISTER_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.REGISTER_ERROR,
            payload: e,
        })
    }
}

const otpAction = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/customer/otp',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.OTP,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.OTP_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.OTP_ERROR,
            payload: e,
        })
    }
}


const storeReview = (jsonobj) => async dispatch => {
    try{
        let data = new FormData();
        data.append('title',jsonobj.title);
        data.append('rate',jsonobj.rate);
        data.append('description',jsonobj.description);
        data.append('car_id',jsonobj.car_id);
        data.append('client_id',jsonobj.client_id);
        data.append('image_lenght',jsonobj.file.length);
        for (let i = 0; i < jsonobj.file.length; i++) {
            data.append('file-'+i,jsonobj.file[i]);
        }
        const res = await axiosConfig.post('/customer/store-review',data,{ headers: {
                "Content-Type": "multipart/form-data",
            }}
        );
        if(res.status === 200){
            dispatch( {
                type: customer.STORE_REVIEW,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.STORE_REVIEW_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.STORE_REVIEW_ERROR,
            payload: e,
        })
    }
}



const clientStoreReview = (jsonobj) => async dispatch => {
    try{
        let data = new FormData();
        data.append('title',jsonobj.title);
        data.append('rate',jsonobj.rate);
        data.append('description',jsonobj.description);
        data.append('car_id',jsonobj.car_id);
        data.append('client_id',jsonobj.client_id);

        const res = await axiosConfig.post('/customer/client-store-review',data);
        if(res.status === 200){
            dispatch( {
                type: customer.STORE_REVIEW,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.STORE_REVIEW_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.STORE_REVIEW_ERROR,
            payload: e,
        })
    }
}


const storeFeedback = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/customer/store-feedback',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.STORE_FEEDBACK,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.STORE_FEEDBACK_ERROR,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.STORE_FEEDBACK_ERROR,
            payload: e,
        })
    }
}


const getcustomerReview = (jsonobj,limit = 24,page=1) => async dispatch => {
    try{
        // let queryString = common.objToQueryString(jsonobj);
        let queryString = common.objToQueryString(jsonobj);
        // let qString = (car_id != undefined && car_id != '') ? 'car_id='+car_id : ''
        let url = `/common/car-reviews?`+queryString+`&limit=`+limit +`&page=`+page
        const res = await axiosConfig.get(url)
        dispatch( {
            type: customer.REVIEW,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.REVIEW_ERROR,
            payload: console.log(e),
        })
    }
}

const getcustomerFeedback = (car_id) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/common/car-feedback?car_id=${car_id}`)
        dispatch( {
            type: customer.FEEBACK,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.FEEBACK_ERROR,
            payload: console.log(e),
        })
    }
}

const getNotifyParty = (id) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/customer/client-notify-party?client_id=${id}`)
        dispatch( {
            type: customer.NOTIFY_PARTY,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.NOTIFY_PARTY_ERROR,
            payload: console.log(e),
        })
    }
}


const getConsignee = (id) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/customer/client-consignee?client_id=${id}`)
        dispatch( {
            type: customer.CONSIGNEE,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: customer.CONSIGNEE_ERROR,
            payload: console.log(e),
        })
    }
}

const updateNotifyPartyAction = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/customer/store-notifyParty',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.UPDATE_NOTIFY_PARTY,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.UPDATE_NOTIFY_PARTY,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.UPDATE_NOTIFY_PARTY_ERROR,
            payload: e,
        })
    }
}
const updateConsigneeAction = (jsonobj) => async dispatch => {
    try{
        const res = await axiosConfig.post('/customer/store-consignee',jsonobj);
        if(res.status === 200){
            dispatch( {
                type: customer.UPDATE_CONSIGNEE,
                payload: res.data
            })
        }else{
            dispatch( {
                type: customer.UPDATE_CONSIGNEE,
                payload: res.data
            })
        }
    }
    catch(e){
        dispatch( {
            type: customer.UPDATE_CONSIGNEE_ERROR,
            payload: e,
        })
    }
}

export {getClientRemittance,getClientDeals,getStates,getCountry,getCity,updateProfileAction,
    storeReview,getcustomerReview,storeFeedback,getcustomerFeedback,registerAction,otpAction,getNotifyParty,getConsignee,
    updateConsigneeAction,updateNotifyPartyAction,getDashboardCount,clientStoreReview};