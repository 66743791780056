import {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {getModels, getShippinSchedule, getZone} from "../../actions/commonAction";
import MetaHelmet from '../partials/widgets/MetaHelmet';

class ShippingSchedule extends Component{
    constructor(props){
        super(props)
    }
    componentDidMount() {
        this.props.getShippingSchedule('');
        this.props.getZone();
    }
    handleChange = (e) => {
        this.props.getShippingSchedule(e.target.value);
    }
    render() {
        let shippingScheduleRes = this.props.shippingScheduleRes;
        let zoneRes = this.props.zoneRes
        let zoneArray = []
        zoneRes.map((zone,key) =>{
            zoneArray.push(
                <option value={zone.value} key={key}>{zone.label}</option>
            )
        })
        let portTitle = []
        let portTD = []
        shippingScheduleRes.ports.map((port,key) =>{
            portTitle.push(
                <th key={key}>{port.title}</th>
            )
        })
        if(shippingScheduleRes.query !== ''){
            shippingScheduleRes.query.map((query,key) =>{
                let portDates = query.port.split(',');
                let portDateTD = []
                shippingScheduleRes.ports.map((port,key) =>{
                    portDates.map((portDate,key) =>{
                        let portDateSplit = portDate.split('/');
                        if(port.title == portDateSplit[0]){
                            portDateTD.push(
                                <td key={key}>{portDateSplit[1]}</td>
                            )
                        }
                    })
                })

                portTD.push(
                    <tr key={key}>
                        <td>{query.shippingName}</td>
                        <td>{query.vesselName}</td>
                        <td>-</td>
                        {portDateTD}
                    </tr>
                )
            })
        }

        let metaHelmet = {
            title : 'Shipping Schedule',
            description : ''
        }
        return (
          <>
            <MetaHelmet {...metaHelmet}/>
              <div className="container-fluid shipping-schedule">
                    <h1>Shipping Schedule</h1>
                  <div className="col-md-4">
                      <div className="form-group">
                          <div className="wo-select">
                              <select name={"maker"} placeholder="Makers" onChange={e => this.handleChange(e)} className="form-control">
                                  <option value=''>Select Zone</option>
                                  {zoneArray}
                              </select>
                          </div>
                      </div>
                  </div>
                  <table className="table table-striped">
                      <tr>
                          <th>Shipper Line</th>
                          <th>Vessel</th>
                          <th>Voyage</th>
                          {portTitle}
                      </tr>
                      {portTD}
                  </table>
              </div>
          </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getShippingSchedule    : (id) => dispatch(getShippinSchedule(id)),
    getZone                : () => dispatch(getZone()),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    shippingScheduleRes   : state.homeReducer.shippingScheduleRes,
    zoneRes               : state.homeReducer.zoneRes,
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShippingSchedule));
