import React, { Component } from 'react'
import {verifyEmailAction} from "../../actions/loginAction";
import {connect} from "react-redux";
import { withRouter } from "react-router";
import {toast, ToastContainer} from "react-toastify";
import common from "../../helpers/common"

class EmailVerify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoggedIn       : false,
            status           : true,
            id               : '',
            message          : '',
            name             : '',
            username         : '',
            email            : '',
            email_verified_at: '',
            user_detail      : [],
            client_detail    : {
                country :{}
            },
            remember_token   : '',
            created_at       : '',
            token            : {
                headers        : {},
                original       : {},
                exception      : '',
            },
            password      : '',
            msg           : '',
            success       : '',
        }
        let loginSession = JSON.parse(localStorage.getItem('login-session'));
        if(loginSession.isLoggedIn === true){
            this.props.history.push('/user/dashboard');
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            'email'        : this.state.email,
            'password'     : this.state.password
        }
        this.setState({
            success   :'',
            msg       :'',
        })
        this.props.login(data)
    }

    enterKeySubmit = (e) => {
        e.preventDefault();
        const data = {
            'email'        : this.state.email,
            'password'     : this.state.password
        }
        this.setState({
            success   :'',
            msg       :'',
        })
        this.props.login(data)
    }

    register(e) {
        e.preventDefault();
        this.props.history.push('/register');
    }

    componentWillReceiveProps(nextProps){
        const apiResp = nextProps.loginResp
        this.setState({...nextProps.loginResp},()=>{
            if(apiResp !== undefined){
                if(this.state.isLoggedIn === true){
                    let loginNotification = ''
                        loginNotification = <ToastContainer>
                            {
                                toast.success('Login Successfully.', {
                                    position    : "top-right",
                                    autoClose   : 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable   : true,
                                    progress    : undefined,
                                })
                            }
                        </ToastContainer>

                    this.setState({
                        success   : loginNotification,
                        msg       :'',
                    },()=>{
                        localStorage.setItem('login-session', JSON.stringify(apiResp));
                        this.props.history.push('/user/dashboard')
                    });
                }else{
                    this.setState({
                        msg     :apiResp.message,
                        success :'',
                    });
                }
            }
        })

    }

    componentDidMount(){
        let searchParams =  common.useQuery(this.props)
        let userID      = this.props.match.params.id
        const jsondata = {
            'user_id' : userID,
            'signature' : searchParams.get('signature')
        }
        this.props.verifyEmail(jsondata)
    }

    render() {
       
        
        return (
            <div className="container ">
                {this.state.success}
                <div className="row justify-content-md-center">
                    <div className="col-lg-6" id="login">
                    <div className="wo-signup">
                                <div className="wo-signup__title">
                                    <h3>Email Verification</h3>
                                    <p>User Getting Verified...........!</p>
                                </div>
                                </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    verifyEmail   : (jsondata) => dispatch(verifyEmailAction(jsondata))
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    loginResp  : state.customerReducer.loginresp,

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailVerify));
