import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AccountSettings  from './AccountSettings'
import MyDeals from './MyDeals'
import MyRemittances from './MyRemittances'
import Dashboard from './Dashboard'
import Login from "./Login";
import common from "../../helpers/common";
import NotifyParty from "./NotifyParty";
import Consignee from "./Consignee";
import {updateLoginResp } from '../../actions/commonAction'

export class Layout extends Component {
    onLogout = (e) => {
        common.logout();
        this.props.history.push('/login');
    }
    componentDidMount(){
      let loginSession = this.props.customerData; //JSON.parse(localStorage.getItem('login-session'));
      if(loginSession.client_detail.phone_verified_at == null){
        this.props.updateLoginResp({message : 'Please enter valid OTP'});
        this.props.history.push('/otp');
      }
    }
    render() {
        let loginSession = this.props.customerData; //JSON.parse(localStorage.getItem('login-session'));
        let emailVerifyAlert = '';
        if(loginSession.email_verified_at == null){
          emailVerifyAlert = <div className="alert alert-danger" role="alert">
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Your email is not yet verified. Please verify your email!
          </div>
        }
       
        let bodycomponent = ''
        const { type } = this.props.match.params
        if(type === 'profile'){
          bodycomponent  =  <AccountSettings />
        }else if(type === 'notify-party'){
            bodycomponent  =  <NotifyParty />
        }else if(type === 'consignee'){
            bodycomponent  =  <Consignee />
        } else if(type === 'dashboard'){
          bodycomponent  =  <Dashboard />
        }else if(type === 'deals'){
          bodycomponent  =  <MyDeals />
        }else if(type === 'remittances'){
          bodycomponent  =  <MyRemittances />
        }else if(type === 'login'){
          bodycomponent  =  <Login />
        } else{
          this.props.history.push('/pagenotfound');
        }

      let dashbordList = '';
      if(loginSession.isLoggedIn !== false){
          dashbordList =
            <ul>
              <li>
                <Link to={'/user/dashboard'}><i className="ti-user" />Dashboard</Link>
              </li>
              <li>
                <Link to={'/user/profile'}><i className="ti-user" />Profile Settings</Link>
              </li>
              <li>
                <Link to={'/user/notify-party'}><i className="ti-car" />Notify Party</Link>
              </li>
                <li>
                <Link to={'/user/consignee'}><i className="ti-car" />Consignee</Link>
              </li>
              <li>
                <Link to={'/user/deals'} ><i className="ti-pencil-alt" />My Deals </Link>
              </li>
              <li>
                <Link to={'/user/remittances'}><i className="ti-bookmark-alt" />My Remittances </Link>
              </li>
              <li>
                <a onClick={(e) => this.onLogout(e)} ><i className="ti-power-off" />Logout </a>
              </li>
            </ul>;
      }else{
        dashbordList =
            <ul>
              <li>
                <Link to={'/user/login'} ><i className="ti-power-off" />Login </Link>
              </li>
            </ul>;
      }

      return (
            <main className="wo-main overflow-hidden">
              {emailVerifyAlert}
            {/* <div className="nav-icon4" id="nav-icon4">
              <span />
              <span />
              <span />
            </div> */}
            {/* Appreciation Start */}
            <section className="wo-main-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-2 ">
                    <div className="wo-dnavbar">
                      <div className="wo-dnavbar__title">
                        <h3>Dashboard</h3>
                      </div>
                      <div className="wo-dnavbar__nav">

                        {dashbordList}

                      </div>
                    </div>
                  </div>
                 {bodycomponent}
                 </div>
              </div>
            </section>
            {/* Appreciation End */}
          </main>
          )
    }
}
const mapDispatchToProps = dispatch => ({
  // getClientDeals : (id,page,limit) => dispatch(getClientDeals(id,page,limit)),
    updateLoginResp : (json) => dispatch(updateLoginResp(json))
})

/*
* mapStateToProps
*/
const mapStateToProps = state => {
  return {
      customerData  : state.customerReducer.loginresp,
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
