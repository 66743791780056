import moment from 'moment';
// import { Redirect } from 'react-router-dom';
// import moment from 'react-moment'

const common = {
    appendScript: function(scriptToAppend){
        const script = document.createElement("script");
        script.src = scriptToAppend;
        script.async = true;
        document.body.appendChild(script);
    },
    imgexists: function(imgpath){
        fetch(imgpath, { method: 'HEAD' })
        .then(res => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch(err => console.log('Error:', err));
    },
    objToQueryString: function(obj = {}){
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
    },
    imageFullPath: function (media,baseDir = 'bids'){
        var d = moment(media.created_at).utcOffset('+0000');
        var month = d.format('MM');
        var day = d.format('DD');

        let imagePath = baseDir+'/'+month+'/'+day+'/'+media.fileName;
        return imagePath;
    },
    useQuery : function(props){
        return new URLSearchParams(props.location.search);
    },
    capitalize : function(str){
        const arrOfWords = str.split(" ");
        const arrOfWordsCased = [];

        for (let i = 0; i < arrOfWords.length; i++) {
            const word = arrOfWords[i];
            arrOfWordsCased.push(word[0].toUpperCase() + word.slice(1).toLowerCase());
        }
        return arrOfWordsCased.join(" ");
    },
    logout : function(){
        const initialState = {
            isLoggedIn       : false,
            status           : true,
            id               : '',
            client_id        : '',
            message          : '',
            name             : '',
            username         : '',
            email            : '',
            email_verified_at: '',
            remember_token   : '',
            created_at       : '',
            token            : {
                headers        : {},
                original       : {},
                exception      : '',
            },
            loading: true,
        };

        let loginSession = JSON.parse(localStorage.getItem('login-session'));
        if(loginSession.isLoggedIn === true){
            localStorage.setItem('login-session', JSON.stringify(initialState));
            return 'Logout Successfully.'
        }
        return 'Already Logout.'
    }
}

export default common;
