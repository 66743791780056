// google analytics
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import AppConfig from '../src/App.config'


ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);


const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({page: location.pathname});
  ReactGA.pageview(location.pathname);
  // console.log('page=>',window.location.pathname);
});

export default history;