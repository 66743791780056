import React, {Component, useCallback} from 'react'
import Select from "react-dropdown-select";
import {connect} from "react-redux";
import {getCity, getCountry, getStates, updateProfileAction} from "../../actions/customerAction";
import { withRouter } from "react-router";
import {toast, ToastContainer} from "react-toastify";
import PhoneField from '../partials/widgets/PhoneField'

export class AccountSettings extends Component {
    constructor(props) {
        super(props)
        let loginSession  = this.props.loginresp //JSON.parse(localStorage.getItem('login-session'));
        this.state = {
            fullname    : loginSession.name,
            password    : '',
            email       : loginSession.email,
            phone       : loginSession.client_detail.mobile,
            country_id  : loginSession.user_detail.country_id,
            user_id     : loginSession.id,
            client_id   : loginSession.client_id,
            address     : loginSession.client_detail.address,
            loginSession: loginSession,
            client_detail : {
                country : {
                    code: ''
                }
            },
            nameError   : '',
            emailError  : '',
            phoneError  : '',
            success     : '',
            userData    : ''
        }
    }

    componentDidMount() {
        this.props.getCountries();
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },()=>{
            // console.log(this.state);
        })
    }

    dropDown = (selectedOption) => {
        if(selectedOption.length > 0 ){
            this.setState(state => {
                state.country_id = selectedOption[0]['value']
                state.phone = ''
                state.client_detail.country.code = selectedOption[0]['label'].split(" - ")[1]
                return state
            })
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        let loginSession  = this.props.loginresp

        const data = {
            'fullname'    : this.state.fullname,
            'password'    : this.state.password,
            'email'       : this.state.email,
            'phone'       : loginSession.client_detail.mobile,
            'country_id'  : this.state.country_id,
            'address'     : this.state.address,
            'user_id'     : loginSession.id,
            'client_id'   : loginSession.client_id,
        }
        this.props.updateProfile(data)
        this.setState({
            success    : '',
            nameError  : '',
            emailError : '',
            phoneError : '',
            userData   : '',
        })
    }

    componentWillReceiveProps(nextProps){
        const profileRes = nextProps.profileRes
        if(profileRes !== undefined){
            if(profileRes.status === true){
                let notification = ''
                    notification = <ToastContainer>
                        {
                            toast.success(profileRes.message, {
                                position    : "top-right",
                                autoClose   : 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable   : true,
                                progress    : undefined,
                            })
                        }
                    </ToastContainer>

                this.setState({
                    success    :profileRes.message,
                    nameError  : '',
                    emailError : '',
                    phoneError : '',
                    userData   : profileRes.data,
                },()=>{
                    localStorage.setItem('login-session', JSON.stringify(this.state.userData));
                })
            }else{
                this.setState({
                    success    :'',
                    nameError  :profileRes.errors.fullname,
                    emailError :profileRes.errors.email,
                    phoneError :profileRes.errors.phone,
                })
            }
        }
    }


    render() {
        let loginSession = this.state.loginSession;
        const countries  = this.props.countries;

        return (
            <>
                <div className="col-lg-8">
                    {this.state.success}
                    <div className="wo-settingsave">
                        <div className="wo-settingsave__title">
                            <h2>Profile Settings</h2>
                            <span>Click “Update Now” to update latest changes</span>
                        </div>
                        {/* <div className="wo-settingsave__btns">
                        </div> */}
                    </div>
                    <div className="wo-dashboardbox">
                        <form className="wo-themeform wo-profileform wo-profileformvtwo">
                            <fieldset>
                                {/*<div className="form-group">*/}
                                {/*    <label className="wo-titleinput">I Want To Start As:</label>*/}
                                {/*    <div className="wo-signup-status">*/}
                                {/*        <span className="wo-radio">*/}
                                {/*            <input type="radio" id="user" name="user" />*/}
                                {/*            <label htmlFor="user">*/}
                                {/*                <span><i className="far fa-circle" /> General User</span>*/}
                                {/*            </label>*/}
                                {/*        </span>*/}
                                {/*        <span className="wo-radio">*/}
                                {/*            <input type="radio" id="Dealer" name="user" />*/}
                                {/*            <label htmlFor="Dealer">*/}
                                {/*                <span><i className="far fa-circle" /> Dealer</span>*/}
                                {/*            </label>*/}
                                {/*        </span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="form-group">
                                    <label className="wo-titleinput">Your Full Name*:</label>
                                    <input type="text" defaultValue={loginSession.name} className="form-control" onChange={e => this.handleChange(e)} name="fullname" placeholder="Enter name*" />
                                </div>
                                <div className="form-group wo-inputicon">
                                    <label className="wo-titleinput">Password:</label>
                                    {/*<i className="ti-info-alt tippy" />*/}
                                    <input type="password" className="form-control" onChange={e => this.handleChange(e)} name="password" placeholder="Password" />
                                </div>
                                <div className="form-group wo-inputicon">
                                    <label className="wo-titleinput">Your Email ID*:</label>
                                    {/*<i className="ti-lock" />*/}
                                    <input type="email" defaultValue={loginSession.email} className="form-control" onChange={e => this.handleChange(e)} name="email" placeholder="christeen772@mail.com" />
                                </div>
                                <div className="form-group">
                                    <label className="wo-titleinput">Country:</label>
                                    <Select  placeholder={loginSession.client_detail.country.name ?? 'Select Country'} className="form-control" options={countries} name={"country_id"}  onChange={this.dropDown}/>
                                </div>
                                <div className="form-group">
                                    <label className="wo-titleinput">Mobile No*:</label>
                                    <PhoneField phone={this.state.phone} country={this.state.client_detail.country} countrySelectProps={{ unicodeFlags: true , disabled:true}}/>
                                    {/* <input type="number" defaultValue={loginSession.user_detail.phone} className="form-control" onChange={e => this.handleChange(e)} name="phone" placeholder="+1 4256 578 68" /> */}
                                </div>
                                <div className="form-group wo-locationtag">
                                    <label className="wo-titleinput">Address*:</label>
                                    <input type="text" defaultValue={loginSession.client_detail.address} className="form-control" onChange={e => this.handleChange(e)} name="address" placeholder="Enter Address*" />
                                    
                                </div>
                                <div className="form-group wo-locationtag uncompleted">
                                    <span className={'text-danger'}>{this.state.nameError} {this.state.emailError} {this.state.phoneError}</span>
                                    <span className={'text-success'}>{this.state.success}</span>
                                </div>
                                <div className="form-group wo-form-btn">
                                    <button onClick={(e) => this.onSubmit(e)} className='wo-btn'>Update Now</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    {/*<div className="wo-dashboardbox">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <label className="wo-titleinput">Profile Image:</label>*/}
                    {/*        <input type="file" className="form-control" name="profile_image" placeholder="Profile Image" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="col-lg-3 d-none">
                    <div className="wo-profilestrength">
                        <div className="wo-strengthhead">
                            <div className="wo-strengthhead__bar">
                                <div className="ProgressBar ProgressBar--animateAll" data-progress={90}>
                                    <svg className="ProgressBar-contentCircle">
                                        <circle transform="rotate(-5,60, 60)" className="ProgressBar-background" cx={32} cy={28} r={27} />
                                        <circle transform="rotate(-5, 60, 60)" className="ProgressBar-circle" cx={32} cy={28} r={27} />
                                    </svg>
                                    <span className="ProgressBar-percentage ProgressBar-percentage--count" />
                                </div>
                            </div>
                            <div className="wo-strengthhead__title  wo-strengthhead__area">
                                <h4>Ad Completed</h4>
                                <span>Complete profile to get maximum results</span>
                            </div>
                        </div>
                        <div className="wo-strengthlist">
                            <ul className="nav navbar-nav">
                                <li className="nav-item wo-listcom">
                                    <a className="active" id="type-tab" data-toggle="tab" href="#type">Ad Type</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="location-tab" data-toggle="tab" href="#location">Vehicle Location</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="title-tab" data-toggle="tab" href="#title">Vehicle &amp; Title</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="description-tab" data-toggle="tab" href="#description">Short Description</a>
                                </li>
                                <li className="nav-item wo-listpro">
                                    <a id="vdescription-tab" data-toggle="tab" href="#vdescription">Vehicle Description</a>
                                </li>
                                <li className="nav-item">
                                    <a id="features-tab" data-toggle="tab" href="#features">Vehicle Main Features</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="addfeatures-tab" data-toggle="tab" href="#addfeatures">Addtional Features</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="history-tab" data-toggle="tab" href="#history">Maintenance History</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="photos-tab" data-toggle="tab" href="#photos">Vehicle Photos</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="price-tab" data-toggle="tab" href="#price">Vehicle Price</a>
                                </li>
                                <li className="nav-item wo-listcom">
                                    <a id="contact-tab" data-toggle="tab" href="#contact">Contact Details</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCountries   : () => dispatch(getCountry()),
    updateProfile  : (jsondata) => dispatch(updateProfileAction(jsondata))
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    countries   : state.customerReducer.countries,
    profileRes  : state.customerReducer.profileRes,
    loginresp  : state.customerReducer.loginresp,

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSettings));
