import React, {Component} from 'react';
import ReactStars from "react-rating-stars-component";
import common from "../../helpers/common";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";



export class FeedbackList extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }
    render() {
        let feedbackList     = []
        let customerFeedback = this.props.data
        let baseImageUrl     = process.env.REACT_APP_BASE_IMG;

        customerFeedback.map((feedback,key) =>{
            let image = ''
            if (feedback.deal.bid.media.length > 0) {
                image =  <div className="col-sm-3">
                            <img src={baseImageUrl + common.imageFullPath(feedback.deal.bid.media[0])}
                                alt={feedback.deal.bid.car_maker.title + " " + feedback.deal.bid.year_month} />
                        </div>
            } else {
                image = <div className="col-sm-3">
                            <img src="https://www.carfitexperts.com/car-models/wp-content/uploads/2019/01/zen-1.jpg"
                                alt="no image available" />
                        </div>
            }
            if(feedback.name){
                feedbackList.push(
                    <div className="review-block">
                        <div className="row">
                            {image}
                            <div className="col-sm-9">
                                <div className="text-dark">Feedback</div>
                                <div className="review-block-title">{feedback.deal.bid.car_maker.title} {feedback.deal.bid.car_model.title} {feedback.deal.bid.car_color.title}</div>
                                <span className="reviewer-name">by {feedback.client.fullname} ({feedback.client.country.name}) on {feedback.created_at}</span>
                                <div className="review-block-description">
                                    {feedback.message}
                                </div>
                                {/*<p className="review-model">*/}
                                {/*    Review on <a href="/stocklist/make=1/model=212" target="_blank">TOYOTA Mark X*/}
                                {/*    (DBA-KGC10)</a>*/}
                                {/*</p>*/}
                            </div>
                        </div>
                    </div>
                )
            }
        })
        return (
            <div className="review">
                {feedbackList}
            </div>
        )
    }
}


export default FeedbackList;