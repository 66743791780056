import {CLIENT_REVIEW_EXISTS, dealConstants, homeConstants, SHIPPING_SCHEDULE} from '../../constants/index'

const initialState = {
    topnew : {
        id : '',
        title : '',
        description : '',
        deals : [],
        loading:true
    },
    featured:{
        data : [],
        loading:true
    },
    categories:[
        {
            title : '',
            deals : '',
        }
    ],
    countryRes:[
        {
            name  : '',
            deals : '',
        }
    ],
    makers:[
        {
            loading :true
        }
    ],
    zoneRes:[
        {
            loading:true
        }
    ],
    models:[
        {
            loading :true
        }
    ],
    deals:{
        data : [
            {
                client : {
                    fullname : '',
                    country : {
                        name : ''
                    }
                },
                bid: {
                    media: [],
                    car_maker: {},
                    car_model: {},
                    car_color: {},
                    attributes: {
                        selling_price :''
                      }
                  },
                  logistic_info:{
                    destination_port : {
                        title : ''
                      },
                    yard: {
                      title : ''
                    }
                  }
                
            }
        ],
        meta : {}
    },
    newarrivals:{
        data : [
            {
                client : {
                    fullname : '',
                    country : {
                        name : ''
                    }
                },
                bid:{
                    media : [],
                    car_maker : {},
                    car_model : {},
                    attributes: {
                        selling_price :''
                      }
                }
            }
        ],
        meta : {}
    },
    premiumclass:{
        data : [
            {
                client : {
                    fullname : '',
                    country : {
                        name : ''
                    }
                },
                bid:{
                    media : [],
                    car_maker : {},
                    car_model : {},
                    attributes: {
                        selling_price :''
                      }
                }
            }
        ],
        meta : {}
    },
    similardeals:{
        data : [
            {
                client : {
                    fullname : '',
                    country : {
                        name : ''
                    }
                },
                bid:{
                    media : [],
                    car_maker : {},
                    car_model : {},
                    attributes: {
                        selling_price :''
                      }
                }
            }
        ],
        meta : {}
    },
    cheapdeals:{
        data : [
            {
                client : {
                    fullname : '',
                    country : {
                        name : ''
                    }
                },
                bid:{
                    media : [],
                    car_maker : {},
                    car_model : {},
                    attributes: {
                        selling_price :''
                      }
                }
            }
        ],
        meta : {}
    },
    shippingScheduleRes: {
        ports: [],
        query: [],
        loading: true
    },
    clientReviewRes: {
        status: '',
        client: {},
    },
    usedCarRes: {
        bidType : [],
        bidMaker: [],
        enginCC : []
    },
    keywordSearchRes:{},
    getkeywordSearchRes: {
        data: [
            {
                client: {
                    fullname: '',
                    country: {
                        name: ''
                    }
                },
                bid: {
                    media: [],
                    car_maker: {},
                    car_model: {},
                    car_color: {},
                    country: {},
                    attributes: {
                        selling_price :''
                    }
                },
                logistic_info:{
                    destination_port : {
                        title : ''
                    },
                    yard: {
                        title : ''
                    }
                }
            }
        ],
        meta: {}
    },
    urlSearchRes:{},
    getUsedCarSearchRes: {
        data: [
            {
                client: {
                    fullname: '',
                    country: {
                        name: ''
                    }
                },
                bid: {
                    media: [],
                    car_maker: {},
                    car_model: {},
                    car_color: {},
                    country: {},
                    attributes: {
                        selling_price :''
                    }
                },
                logistic_info:{
                    destination_port : {
                        title : ''
                    },
                    yard: {
                        title : ''
                    }
                }
            }
        ],
        meta: {}
    },
}


const reducer =  (state=initialState, action) => {
      switch(action.type){
        case homeConstants.TOP_NEW:
        return {
            ...state,
            topnew:action.payload.data,
            loading:false
        };
        case homeConstants.FEATURED:
        return {
            ...state,
            featured:action.payload,
            loading:false
        };
        case dealConstants.NEW_ARRIVALS:
            return {
                ...state,
                newarrivals:action.payload,
                loading:false
            };
        case dealConstants.CHEAP_DEALS:
        return {
            ...state,
            cheapdeals:action.payload,
            loading:false
        };
        case dealConstants.PREMIUM_CLASS:
        return {
            ...state,
            premiumclass:action.payload,
            loading:false
        };
        case dealConstants.SIMILAR_DEALS:
            return {
                ...state,
                similardeals:action.payload,
                loading:false
            };
        
      case homeConstants.MAKER_COUNT:
          return {
              ...state,
              categories:action.payload,
              loading:false
          };
      case homeConstants.MAKER_LISTING:
          return {
              ...state,
              deals:action.payload,
              loading:false
          };
      case homeConstants.COUNTRY_COUNT:
          return {
              ...state,
              countryRes:action.payload,
              loading:false
          };
      case homeConstants.MAKERS:
          return {
              ...state,
              makers:action.payload,
              loading:false
          };
      case homeConstants.MODELS:
          return {
              ...state,
              models:action.payload,
              loading:false
          };
      case SHIPPING_SCHEDULE:
         return {
          ...state,
          shippingScheduleRes:action.payload,
          loading:false
      };
     case CLIENT_REVIEW_EXISTS:
         return {
          ...state,
         clientReviewRes:action.payload,
          loading:false
      };
      case dealConstants.KEYWORD_SEARCH:
          return {
              ...state,
              keywordSearchRes:action.payload,
              loading:false
          };
      case dealConstants.GET_KEYWORD_SEARCH:
          return {
              ...state,
              getkeywordSearchRes:action.payload,
              loading:false
          };
      case dealConstants.GET_USED_CAR_SEARCH:
          return {
              ...state,
              getUsedCarSearchRes:action.payload,
              loading:false
          };
      case dealConstants.USED_CAR_SEARCH:
          return {
              ...state,
              usedCarRes:action.payload,
              loading:false
          };
      case dealConstants.KEY_SEARCH_URL_RES:
          return {
              ...state,
              urlSearchRes:action.payload,
          };
          default: return state
      case homeConstants.ZONE:
          return {
              ...state,
              zoneRes:action.payload,
              loading:false
          };
    }
  };

export default reducer
