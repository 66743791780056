import React, { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert';

export default class QuoteModels extends Component {

    onInquireNow = () => {
        confirmAlert({
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="wo-modalcontent modal-content">
                            <div className="wo-popuptitle">
                                <h4>Get Quote</h4>
                                <a onClick={() => { onClose() }} className="close"><i className="ti-close" ></i></a>
                            </div>
                            <div className="modal-body">
                                <form className="wo-themeform wo-formlogin">
                                    <fieldset>
                                        <div className="form-group">
                                            <label className="wo-titleinput">First Name:</label>
                                            <input name={'first_name'} placeholder={"First Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Last Name:</label>
                                            <input name={'last_name'} placeholder={"Last Name"} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.nameError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Email:</label>
                                            <input name={'email'} placeholder={"Enter your email address."} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.emailError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Phone:</label>
                                            <input name={'phone'} placeholder={"+1(801)889-3563*."} type='text' onChange={e => this.handleChange(e)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-danger'}>{this.state.phoneError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">City:</label>
                                            <select name={"city"} onChange={e => this.handleChange(e)} className="wo-titleinput">
                                                <option value="">Please Select City</option>
                                                <option value="antigua">antigua and barbuda</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="wo-titleinput">Message:</label>
                                            <textarea id="leads-remarks" name={'message'} className="input-detail  form-control" name="" rows="4" placeholder="Enter your message *"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <p className={'text-success'}>{this.state.success}</p>
                                        </div>
                                        <div className="form-group wo-form-btn">
                                            <button onClick={(e) => this.onSubmit(e)} className='wo-btn'>Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }
    render() {
        return (
            this.onInquireNow()
        )
    }
}
