import { dealQuote } from '../constants/index'
import axiosConfig from '../axiosConfig'
import common from '../../src/helpers/common'

const storeQuoteAction = (jsonobj,type) => async dispatch => {
    try{
        const res = await axiosConfig.post('/quote/quote-store',jsonobj);
        if(res.status === 200){
            if(type === "carDetail"){
                dispatch( {
                    type: dealQuote.QUOTE,
                    payload: res.data
                })
            }else{
                dispatch( {
                    type: dealQuote.LISTING_QUOTE,
                    payload: res.data
                })
            }

        }else{
            if(type === "carDetail"){
                dispatch( {
                    type: dealQuote.QUOTE,
                    payload: res.data
                })
            }else{
                dispatch( {
                    type: dealQuote.LISTING_QUOTE,
                    payload: res.data
                })
            }
        }
    }
    catch(e){
        if(type === "carDetail"){
            dispatch({
                type: dealQuote.QUOTE_ERROR,
                payload: e,
            })
        }else{
            dispatch( {
                type: dealQuote.LISTING_QUOTE_ERROR,
                payload: e
            })
        }
    }
}
export {storeQuoteAction};