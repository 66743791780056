import React, {Component} from 'react';
import { connect } from 'react-redux';

import {checkClientReviewExists, getCountries} from "../../actions/commonAction";
// import {storeQuoteAction} from "../../actions/storeQuoteAction";
import {clientStoreReview,} from "../../actions/customerAction";
import ReactStars from "react-rating-stars-component";
import {toast, ToastContainer} from "react-toastify";
import common from "../../helpers/common";
import MetaHelmet from '../partials/widgets/MetaHelmet';

class ClientReview extends Component{
    constructor(props) {
        super(props)
        let searchParams = common.useQuery(this.props)
        let car_id        = searchParams.get('car_id')
        let client_id     = searchParams.get('client_id')

        this.state = {
            title               : '',
            rate                : '',
            image               : [],
            description         : '',
            titleError          : '',
            rateError           : '',
            imageError          : '',
            descriptionError    : '',
            car_id              : car_id,
            client_id           : client_id,
            car_id_error        : '',
            client_id_error     : '',
            emailStatusCheck    : '',
            reviewExistsMsg     : 'Your review already exists',
        }
        this.textareaChange = this.textareaChange.bind(this);

    }
    updateStateData = () => {
        this.setState({
            emailStatusCheck:true
        })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(
            {
                reviewResp      :nextProps.reviewResp,
                emailStatusCheck:nextProps.clientReviewExistsRes.status
            },()=> {
                const reviewresp = this.state.reviewResp
                if (reviewresp !== undefined && reviewresp !== '') {
                    if (reviewresp.status === true) {
                        let reviewPopup = <ToastContainer>
                            {
                                toast.success(reviewresp.message, {
                                    position    : "top-right",
                                    autoClose   : 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable   : true,
                                    progress    : undefined,
                                })
                            }
                        </ToastContainer>;
                        this.setState({
                            success     : reviewPopup,
                            titleError  : '',
                            title       : '',
                            description : '',
                            rateError   : '',
                            descriptionError: '',
                            reviewResp  : '',
                            rate        : '',
                            reviewExistsMsg: 'Thank for your review',
                        },()=>{
                            this.updateStateData()
                        })

                    } else {
                        if (reviewresp.errors !== undefined) {
                            this.setState({
                                success: '',
                                titleError: reviewresp.errors.title,
                                rateError: reviewresp.errors.rate,
                                descriptionError: reviewresp.errors.description,
                            });
                        } else {
                            this.setState({
                                success: '',
                            });
                        }

                    }
                    this.setState({
                        reviewResp      : ''
                    })
                }
            });
    }
    handleChange    = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    textareaChange  (event)  {
        this.setState({ description: event.target.value });
    }

    componentDidMount() {
        this.props.checkClientReviewExists(this.state.car_id,this.state.client_id)
    }
    onSubmitReview  =(e) => {
        e.preventDefault();
        let data = {
            'title'         : this.state.title,
            'rate'          : this.state.rate,
            'description'   : this.state.description,
            'car_id'        : this.state.car_id,
            'client_id'     : this.state.client_id,
            'file'          : this.state.image,
        }

        this.props.storeReview(data)
        // this.setState({reviwModel:true});

    }
    render() {
        const secondExample = {
            size    : 25,
            count   : 5,
            color   : "black",
            activeColor : "red",
            value       : 7.5,
            a11y        : true,
            isHalf      : true,
            emptyIcon   : <i className="far fa-star" />,
            halfIcon    : <i className="fa fa-star-half-alt" />,
            filledIcon  : <i className="fa fa-star" />,
            onChange: newValue => {
                this.setState({
                    rate: newValue,
                })
            }
        };
        let clientName = <p>Dear {this.props.clientReviewExistsRes.client.fullname}.</p>
        let html = ''
        if(!this.state.emailStatusCheck){
            html = <form className="wo-themeform wo-formlogin" encType="multipart/form-data">
                <fieldset>
                    <div className="form-group">
                        <label className="wo-titleinput">Title:</label>
                        <input value={this.state.title} name="title" placeholder={"Title"} type='text'
                               onChange={e => this.handleChange(e)} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <p className={'text-danger'}>{this.state.titleError}</p>
                    </div>
                    <div className="form-group">
                        <label className="wo-titleinput">Rate:</label>
                        <ReactStars {...secondExample} />
                    </div>
                    <div className="form-group">
                        <p className={'text-danger'}>{this.state.rateError}</p>
                    </div>
                    <div className="form-group">
                        <label className="wo-titleinput">Comments/Advice:</label>
                        <textarea value={this.state.description} id="description" name="description" onChange={this.textareaChange}
                                  className="input-detail  form-control" name="" rows="4"
                                  placeholder="Enter your description *"></textarea>
                    </div>
                    <div className="form-group">
                        <p className={'text-danger'}>{this.state.descriptionError}{this.state.imageError}</p>
                    </div>
                    <div className="form-group wo-form-btn">
                        <button onClick={(e) => this.onSubmitReview(e)} className='wo-btn'>Submit
                        </button>
                    </div>
                </fieldset>
            </form>
        }else{
            html = <div className="wo-popuptitle">
                <h4>{this.state.reviewExistsMsg}.</h4>
            </div>
        }
        let metaHelmet = {
            title: `Reviews & Feedback`,
            description: ''
          }

        return (
          <>
            <MetaHelmet {...metaHelmet} />
              <div className="container">
                  <h3><img  src="https://imgcdn.cfbajapan.com/logo.png" alt="CFBA Japan" />
                  </h3><h4>JAPAN'S LEADING USED VEHICLE EXPORTER</h4>
                  {clientName}
                  <p>Through the below link rate our service.</p>
                  <div className="wo-popuptitle">
                      <h4>Rate Our Service</h4>
                  </div>
                  {html}
                  <div className="form-group">
                      <p className={'text-success'}>{this.state.success}</p>
                  </div>
              </div>
          </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    checkClientReviewExists   : (car_id,client_id) => dispatch(checkClientReviewExists(car_id,client_id)),
    storeReview     : (jsondata) => dispatch(clientStoreReview(jsondata)),

})

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state,
    clientReviewExistsRes   : state.homeReducer.clientReviewRes,
    reviewResp        : state.customerReducer.reviewresp,

})
export default connect(mapStateToProps, mapDispatchToProps)(ClientReview);
