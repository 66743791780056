import { Component } from 'react';
import { connect } from 'react-redux';
// import common from '../../helpers/common';
import { getcustomerReview } from '../../actions/customerAction'
import ReviewList from '../partials/ReviewList';
import MetaHelmet from '../partials/widgets/MetaHelmet';

class Reviews extends Component {

  componentWillReceiveProps(nextProps) {
    // this.props.customerReviews(0)
  }
  componentDidMount() {
    this.props.customerReviews(0)
  }
  render() {
    let metaHelmet = {
      title: `Reviews & Feedback`,
      description: ''
    }
    let customerReviews  = this.props.reviews.data
    console.log(customerReviews);
    return (
       <div className="col-8 offset-2">
         <MetaHelmet {...metaHelmet} />
        <div className="tittles typ">
            <h2><a href="">Customer Reviews and Feedback</a></h2>
        </div>
        <ReviewList data={customerReviews}/>
        </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  customerReviews: (id) => dispatch(getcustomerReview(id)),
})

/*
* mapStateToProps
*/
const mapStateToProps = state => ({
  ...state,
  reviews: state.customerReducer.customerReviews,
})

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);