import React, { Component } from 'react'
import { withRouter } from "react-router";
import {connect} from "react-redux";
import {getClientRemittance} from "../../actions/customerAction";
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';

export class MyRemittances extends Component {
    componentDidMount() {
        let loginSession = this.props.loginresp //JSON.parse(localStorage.getItem('login-session'));
        this.props.getClientRemittances(loginSession.client_id,1,15);
    }

    render() {
        // let data = [{ id: 1, title: 'Conan the Barbarian', year: '1982' }];
        let columns = [
            {
                name: 'Date',
                selector: 'date',
                sortable: true,
            },
        {
            name: 'Amount ($)',
            cell: row => <NumberFormat value={row.amount_USD} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={''} />,
            sortable: true,
        },
        {
            name: 'Amount (¥)',
            cell: row => <NumberFormat value={row.amount_JPY} defaultValue={0} displayType={'text'} thousandSeparator={true} prefix={''} />,
            sortable: true,
        },
        {
            name: 'Bank',
            cell: row => <div>{row.bank.bank_name} - {row.bank.account_no}</div>,
            sortable: true,
            
        }
        ];
        const selectRemittances   = this.props.clientRemittances;
        let remittances = [];

        selectRemittances.data.map((remittance,key) =>{
            remittances.push(
                <tr>
                    <th scope="row">{remittance.bank.bank_name}</th>
                    <td>{remittance.amount_USD}</td>
                    <td>{remittance.USD_rate}</td>
                    <td>{remittance.amount_JPY}</td>
                    <td>{remittance.total_JPY}</td>
                </tr>
            )
        })

        return (
                <div className="col-lg-8">
                    {/* <div className="wo-settingsave">
                        <div className="wo-settingsave__title">
                            <h2>My Remittances</h2>
                            <span>Your Remittances</span>
                        </div>
                    </div> */}
                    <div className="verticle">
                        {/* <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Bank</th>
                                <th scope="col">Amount -USD</th>
                                <th scope="col">Rate -USD</th>
                                <th scope="col">Amount -JPY</th>
                                <th scope="col">Total -JPY</th>
                            </tr>
                            </thead>
                            <tbody>
                                {remittances}
                            </tbody>
                        </table> */}
                         <DataTable
                            title="MY REMITTANCES"
                            pagination
                            columns={columns}
                            data={selectRemittances.data}
                        />
                    </div>

                </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getClientRemittances : (id,page,limit) => dispatch(getClientRemittance(id,page,limit)),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        clientRemittances  : state.customerReducer.remittances,
        loginresp : state.customerReducer.loginresp,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyRemittances));
