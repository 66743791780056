import { React, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {useState, setState} from 'react'
import { useDispatch,useStore } from 'react-redux'

const PhoneField = props => {

  // console.log('props',props)
    // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [phone, setPhone] = useState(props.phone)

  // const [phoneNo, updatePhone] = useState(phone)
  const [countryCode, setCountry] = useState(props.country.code)

  const dispatch = useDispatch()
  const store = useStore()
  const handleChange = e => {
    // setPhone(props.phone);
    // console.log(e)
    dispatch({ type: 'LOGIN_UPDATE', payload:{'client_detail':{...store.getState().customerReducer.loginresp.client_detail,'mobile':e}}})
    };

 useEffect(() => {
  const cs = store.getState().customerReducer
  setCountry(props.country.code);
  setPhone(props.phone);
    // action on update of movies
}, [props]);

// const test = () => {
//   dispatch({ type: 'LOGIN_UPDATE', payload:{'client_detail':{'telephone':updatePhone}}})
// }



  return (
    <PhoneInput
    // initialValueFormat="national"
      placeholder="Enter phone number"
      value={phone}
      onChange={handleChange}
      defaultCountry={countryCode}
      onCountryChange={setCountry}
      name={props.name}
      
      // countryCallingCodeEditable={false}
      international={true}
      {...props}

      />
  )
}

export default PhoneField
