import { Component } from "react";
import { connect } from "react-redux";
import { getCheap } from "../../actions/dealAction"
import DealItem from "../partials/Listings/items/DealItem"
import Loader from "./Loader";
// import Loader from "../../components/partials/Loader"

export class CheapDeals extends Component {
    componentDidMount() {
        // common.appendScript('./assets/js/pages/topnew.js')
        // common.appendScript('./assets/js/pages/brandslider.js')
        // this.props.getDeals(this.props.match.params.name);
        this.props.getCheap();
    }
    render() {
        const { data, meta, loading } = this.props.cheapdeals

        let result = []
        if(data.length != 1) {
            data.map((deal, key) => (
                result.push(
                    <div className="col-md-3" key={key}>
                        <DealItem deal={deal}/>
                    </div>
                )
            ))
        }else {
            result.push(
                <div className="col-md-3" >
                    <Loader/>
                </div>
            )
        }
        return (
            <>
                {result}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCheap : () => dispatch(getCheap('desc',8)),
})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        cheapdeals : state.homeReducer.cheapdeals,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheapDeals);
