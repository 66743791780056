import { connect } from "react-redux";
import {Component, React, useState} from "react";
import common from "../../../../helpers/common";
import Loader from "../../Loader"
import HorizontalItem from "../items/HorizontalItem";
import Pagination from "../../Pagination"
import { withRouter } from "react-router";

import {
    getKeywordSearch,
    getMainSearch,
    keysearchUrlRes,
    keywordSearch,
    serachByType
} from "../../../../actions/searchAction";
import Brands from "../../widgets/Brands";
import Countries from "../../widgets/Countries";
import PriceRange from "../../widgets/PriceRange";
import {getCountries, getMakers, getModels} from "../../../../actions/commonAction";
import NewArrival from "../../NewArrival";
import PremiumClass from "../../PremiumClass";
import CheapDeals from "../../CheapDeals";
import {Link} from "react-router-dom";
import ReviewList from "../../ReviewList";
import {confirmAlert} from "react-confirm-alert";
import PaginationItem from "../../PaginationItem";

class Horizontal extends Component {
    constructor(props) {
        super(props)
        this.state = {

            maker           : '',
            priceMin        : '',
            priceMax        : '',
            model           : '',
            bidtype         : '',
            yearMin         : '',
            yearMax         : '',
            freighttype     : '',
            steeringType    : '',
            fuelType        : '',
            transmission    : '',
            max_mileage     : '',
            min_mileage     : '',
            min_eng         : '',
            max_eng         : '',
            cd_player       : '',
            sun_roof        : '',
            leather_seat    : '',
            alloy_wheels    : '',
            power_steering  : '',
            power_window    : '',
            a_c             : '',
            abs             : '',
            air_bag         : '',
            radio           : '',
            cd_changer      : '',
            dvd             : '',
            tv              : '',
            power_seat      : '',
            back_tire       : '',
            grill_guard     : '',
            rear_spoiler    : '',
            central_locking : '',
            jack            : '',
            spare_tire      : '',
            degree_camera   : '',
            wheel_spanner   : '',
            back_camera     : '',
            push_start      : '',
            keyless_entry   : '',
            esc             : '',
            checkBoxHidden  : true,
            see_more        : '',
            sortBy          : '',
            type            : '',
            pageHeading     : '',
            category        : '',
            bitTypeSearch   : '',
            usedCarSearch   : '',
            usedCarType     : '',
            keysearch       : '',
            keysearchType   : '',
            country         : '',
            prices          : '',
            loading         : true,
            search:{
                maker       : '',
                sort        : '',
                model       : '',
                location    : '',
                type        : '',
                prices      : '',
                years       : '',
                usedCarType : '',
                bidType     : '',
                engineCC    : '',
                key         : '',
            }
        };
    }

    updateStateData = () => {
        if (this.state.type === 'category') {
            const { category } = this.state;
            this.props.getMainSearch({ 'maker': category ,'sort':this.state.sortBy});
        } else if (this.state.type === 'country') {
            const { country } = this.state;
            this.props.getMainSearch({ 'location': country });
        } else if (this.state.type === 'prices') {
            const { prices } = this.state;
            this.props.getMainSearch({ 'prices': prices });
        }else if (this.state.type === 'main-search') {
            const { search } = this.state;
            this.props.getMainSearch({ ...search });
        }else if (this.state.type === 'keysearch') {
            // const { keysearch ,keysearchType} = this.state;
            // if(keysearch !== undefined){
            //     this.props.getkeywordSearch(keysearch,keysearchType);
            // }

            const { search } = this.state;
            this.props.getMainSearch({ ...search });

        }else if (this.state.type === 'bid-type') {
            const { search } = this.state;
            this.props.getMainSearch({ ...search });
        } else if (this.state.type === 'used_car') {
            const { search } = this.state;
            this.props.getMainSearch({ ...search });

        }
    }

    keySearchChangeData =(key,type,sort)=>{
        if(key !== undefined && key !== null){
            let stateJson = {
                'key'       : key,
                'type'      : type,
            }
            this.setState({
                'keysearch'     : key,
                'keysearchType' : type,
                'search'        : stateJson,
            }, () => {
                this.updateStateData()
            })
        }
    }

    handleChange = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.name === "sortBy" && this.state.type == 'main-search'){
            await this.onSubmit('','function')
        }else if(e.target.name === "sortBy" && this.state.type == 'category'){
            await this.props.getMainSearch({ 'maker': this.state.category ,'sort':this.state.sortBy});
        }else if(e.target.name === "sortBy" && this.state.type == 'country'){
            await this.props.getMainSearch({ 'location': this.state.country ,'sort':this.state.sortBy});
        }else if(e.target.name === "sortBy" && this.state.type == 'prices'){
            await this.props.getMainSearch({ 'prices': this.state.prices ,'sort':this.state.sortBy});
        }else if(e.target.name === "sortBy" && this.state.type == 'used_car' || this.state.type == 'keysearch'){
            const { search } = this.state;
            let stateJson = {
                'usedCarType'  : search.usedCarType,
                'bidType'      : search.bidType,
                'engineCC'     : search.engineCC,
                'maker'        : search.maker,
                'sort'         : this.state.sortBy,
            }
            await this.props.getMainSearch({ ...stateJson });
        } else if(e.target.name === "sortBy" && this.state.type == 'keysearch'){
            const { search } = this.state;
            let stateJson = {
                'key'       : search.key,
                'type'      : search.type,
                'sort'      : this.state.sortBy,
            }
            await this.props.getMainSearch({ ...stateJson });
        }
    }

    checkboxHandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked === true ? 1:0
        })
    }

    seeMoreHandleChange = (e) => {
        e.target.checked === true ?
            this.setState({
                'checkBoxHidden'     : false,
            }) :
            this.setState({
                'checkBoxHidden'     : true,
            })
    }

    onSubmit = (e,type) => {
        if(type !== "function"){
            e.preventDefault();
        }

        let prices = '';let years  = '';
        if(this.state.priceMin === '' && this.state.priceMax === ''){
            prices  = '';
        }else{
            prices  = this.state.priceMin+'-'+this.state.priceMax;
        }
        if(this.state.yearMin === '' && this.state.yearMax === ''){
            years  = '';
        }else{
            years  = this.state.yearMin +'-'+this.state.yearMax;
        }
        let mileage = '';let engCC = '';
        if(this.state.min_mileage === '' && this.state.max_mileage === ''){
            mileage  = '';
        }else{
            mileage  = this.state.min_mileage+'-'+this.state.max_mileage;
        }
        if(this.state.min_eng === '' && this.state.max_eng === ''){
            engCC  = '';
        }else{
            engCC  = this.state.min_eng+'-'+this.state.max_eng;
        }
            let stateJson = {
            'maker'         : this.state.maker,
            'sort'          : this.state.sortBy,
            'model'         : this.state.model,
            'type'          : this.state.bidtype,
            'prices'        : prices,
            'years'         : years,
            'freighttype'   : this.state.freighttype,
            'steering_type' : this.state.steeringType,
            'fuelType'      : this.state.fuelType,
            'transmission'  : this.state.transmission,
            'mileage'       : mileage,
            'engine_cc'     : engCC,
            'cd_player'     : this.state.cd_player,
            'sun_roof'      : this.state.sun_roof,
            'leather_seat'  : this.state.leather_seat,
            'alloy_wheels'  : this.state.alloy_wheels,
            'power_steering': this.state.power_steering,
            'power_window'  : this.state.power_window,
            'a_c'           : this.state.a_c,
            'abs'           : this.state.abs,
            'air_bag'       : this.state.air_bag,
            'radio'         : this.state.radio,
            'cd_changer'    : this.state.cd_changer,
            'dvd'           : this.state.dvd,
            'tv'            : this.state.tv,
            'power_seat'    : this.state.power_seat,
            'back_tire'     : this.state.back_tire,
            'grill_guard'   : this.state.grill_guard,
            'rear_spoiler'  : this.state.rear_spoiler,
            'central_locking': this.state.central_locking,
            'jack'          : this.state.jack,
            'spare_tire'    : this.state.spare_tire,
            'degree_camera' : this.state.degree_camera,
            'wheel_spanner' : this.state.wheel_spanner,
            'back_camera'   : this.state.back_camera,
            'push_start'    : this.state.push_start,
            'keyless_entry' : this.state.keyless_entry,
            'esc'           : this.state.esc,

            }

        this.setState({ 'search': stateJson ,'type': 'main-search'}, () => {
            this.updateStateData()
        })

        //     if(this.state.maker !== '' || this.state.bidtype !== '' || prices !== '' || this.state.model !== '' || this.state.freighttype !== '' || years !== ''){
        //         this.props.history.push(`/main-search?maker=`+this.state.maker+`&sort=`+this.state.sortBy+`&type=`+this.state.bidtype +`&prices=`+prices +`&model=`+this.state.model +`&years=`+years +`&freighttype=`+this.state.freighttype +
        //             `&steering_type=`+this.state.steeringType +`&fuel_type=`+this.state.fuelType +`&transmission=`+this.state.transmission +`&mileage=`+mileage +`&engine_cc=`+engCC+`&cd_player=`+this.state.cd_player +
        //             `&sun_roof=`+this.state.sun_roof +`&leather_seat=`+this.state.leather_seat +`&alloy_wheels=`+this.state.alloy_wheels +`&power_steering=`+this.state.power_steering +`&power_window=`+this.state.power_steering +`&a_c=`+this.state.a_c +`&abs=`+this.state.abs +`&air_bag=`+this.state.air_bag +
        //             `&radio=`+this.state.radio +`&cd_changer=`+this.state.cd_changer +`&dvd=`+this.state.dvd +`&tv=`+this.state.tv +`&power_seat=`+this.state.power_seat +`&back_tire=`+this.state.back_tire +`&grill_guard=`+this.state.grill_guard +`&rear_spoiler=`+this.state.rear_spoiler +
        //             `&central_locking=`+this.state.central_locking +`&jack=`+this.state.jack +`&spare_tire=`+this.state.spare_tire +`&degree_camera=`+this.state.degree_camera +`&wheel_spanner=`+this.state.wheel_spanner +`&back_camera=`+this.state.back_camera +`&push_start=`+this.state.push_start +`&keyless_entry=`+this.state.keyless_entry +`&esc=`+this.state.esc +
        //             `&limit=`+15 +`&page=`+1);
            // }

    }

    usedCarStateChange = () =>{
        let searchParams = common.useQuery(this.props)
        let search  = searchParams.get('search')
        let type    = searchParams.get('type')
        let maker   = '';
        let engine_cc = '';
        let bidtype   = '';
        if(type === 'maker'){
            maker   = search;
        }else if(type == 'bidtype'){
            bidtype = search;
        }else if(type == 'engine_cc'){
            engine_cc = search;
        }

        let stateJson = {
            'maker'       : maker,
            'usedCarType' : type,
            'bidType'     : bidtype,
            'engineCC'     : engine_cc,
        }
        this.setState({ 'search' : stateJson}, () => {
            this.updateStateData()
        })
    }

    componentWillReceiveProps = (nextProps)=> {
        if(this.props.urlSearchRes.key !== nextProps.urlSearchRes.key){
            this.keySearchChangeData(nextProps.urlSearchRes.key,nextProps.urlSearchRes.type,this.state.sortBy)
        }

        //when used_car params is change after that this function will auto use
        if(this.props.usedCarSearch !== nextProps.usedCarSearch ){
            this.setState({ 'usedCarSearch' : nextProps.usedCarSearch ,'usedCarType' : nextProps.usedCarType}, () => {
                this.usedCarStateChange()
            })
        }
        const { loading } = nextProps.mainSearchData
        this.setState({
            'loading' : loading
        })

        if (nextProps.location.pathname !== this.props.location.pathname) {
            const { name } = nextProps.match.params
            const type = nextProps.type
            let country = '';
            let category = '';
            let prices  =  '';

            if(type === 'country'){
                country = name;
            }else if(type === 'category'){
                category = name
            }else if(type === 'prices'){
                prices = name;
            }

            let json ={
                'maker'     : category,
                'location'  : country,
                'prices'    : prices,
            }

            this.setState({
                'type'      : type,
                'country'   : country,
                'category'  : category,
                'search'    : json,
                'prices'    : prices,
                'pageHeading': name,
                'loading'   : true
            }, () => {
                window.scrollTo(0, 150);
                this.updateStateData()
            });
        }
    }


    async componentDidMount() {
        await this.props.getMakers();
        await this.props.getCountries();
        await this.props.getModels();
        await this.props.searchByType();

        const { name } = this.props.match.params
        this.setState({
            'type'      : this.props.type,
            'country'   : name,
            'category'  : name,
            'prices'    : name,
            'pageHeading': name,
        }, () => {
            if (this.state.type === 'country') {
                this.setState({ 'country': name }, () => {
                    this.updateStateData()
                })
            } else if (this.state.type === 'category') {
                let stateJson = {
                    'maker': name,
                }
                this.setState({ 'category': name ,'search' : stateJson}, () => {
                    this.updateStateData()
                })
            }  else if (this.state.type === 'bid-type') {
                let searchParams = common.useQuery(this.props)
                let search = searchParams.get('search')
                const removeDash = search.replace("-", " ");
                let stateJson = {
                    'bitTypeSearch' : removeDash,
                }
                this.setState({ 'search': stateJson }, () => {
                    this.updateStateData()
                })
            }else if (this.state.type === 'used_car') {
                let searchParams = common.useQuery(this.props)
                let search  = searchParams.get('search')
                let type    = searchParams.get('type')
                let maker   = '';
                let engine_cc = '';
                let bidtype   = '';
                if(type === 'maker'){
                    maker   = search;
                }else if(type == 'bidtype'){
                    bidtype = search;
                }else if(type == 'engine_cc'){
                    engine_cc = search;
                }

                let stateJson = {
                    'maker'       : maker,
                    'usedCarType' : type,
                    'bidType'     : bidtype,
                    'engineCC'     : engine_cc,
                }
                this.setState({ 'search' : stateJson}, () => {
                    this.updateStateData()
                })
            }
            else if (this.state.type === 'keysearch') {
                let searchParams = common.useQuery(this.props)
                let search = searchParams.get('search')
                let type   = searchParams.get('type')

                // if(this.props.urlSearchRes.key === undefined){
                //     this.props.keysearchUrlRes({'key':search,'type':type})
                // }

                let stateJson = {
                    'key'       : search,
                    'type'      : type,
                }
                this.setState({ 'search' : stateJson}, () => {
                    this.updateStateData()
                })

                // this.setState({ 'keysearch': this.props.urlSearchRes.key ,'keysearchType':this.props.urlSearchRes.type}, () => {
                //     this.updateStateData()
                // })
            } else if (this.state.type === 'prices') {
                this.setState({ 'prices': name }, () => {
                    this.updateStateData()
                })
            } else if (this.state.type === 'main-search') {
                let searchParams = common.useQuery(this.props)

                let maker        = searchParams.get('maker')
                let model        = searchParams.get('model')
                let type         = searchParams.get('type')
                let freighttype  = searchParams.get('freighttype')
                let sort         = searchParams.get('sort')
                let prices       = searchParams.get('prices')
                let years        = searchParams.get('years')
                let steering_type= searchParams.get('steering_type')
                let fuelType     = searchParams.get('fuelType')
                let transmission = searchParams.get('transmission')
                let mileage      = searchParams.get('mileage')
                let engine_cc    = searchParams.get('engine_cc')
                let cd_player    = searchParams.get('cd_player')
                let sun_roof     = searchParams.get('sun_roof')
                let leather_seat = searchParams.get('leather_seat')
                let alloy_wheels = searchParams.get('alloy_wheels')
                let power_steering= searchParams.get('power_steering')
                let power_window  = searchParams.get('power_window')
                let a_c           = searchParams.get('a_c')
                let abs           = searchParams.get('abs')
                let air_bag       = searchParams.get('air_bag')
                let radio         = searchParams.get('radio')
                let cd_changer    = searchParams.get('cd_changer')
                let dvd           = searchParams.get('dvd')
                let tv            = searchParams.get('tv')
                let power_seat    = searchParams.get('power_seat')
                let back_tire     = searchParams.get('back_tire')
                let grill_guard   = searchParams.get('grill_guard')
                let rear_spoiler  = searchParams.get('rear_spoiler')
                let central_locking= searchParams.get('central_locking')
                let jack          = searchParams.get('jack')
                let spare_tire    = searchParams.get('spare_tire')
                let degree_camera = searchParams.get('degree_camera')
                let wheel_spanner = searchParams.get('wheel_spanner')
                let back_camera   = searchParams.get('back_camera')
                let push_start    = searchParams.get('push_start')
                let keyless_entry = searchParams.get('keyless_entry')
                let esc           = searchParams.get('esc')

                let stateJson = {
                    'maker'         : maker,
                    'sort'          : sort,
                    'model'         : model,
                    'type'          : type,
                    'prices'        : prices,
                    'years'         : years,
                    'freighttype'   : freighttype,
                    'steering_type' : steering_type,
                    'fuelType'      : fuelType,
                    'transmission'  : transmission,
                    'mileage'       : mileage,
                    'engine_cc'     : engine_cc,
                    'cd_player'     : cd_player,
                    'sun_roof'      : sun_roof,
                    'leather_seat'  : leather_seat,
                    'alloy_wheels'  : alloy_wheels,
                    'power_steering': power_steering,
                    'power_window'  : power_window,
                    'a_c'           : a_c,
                    'abs'           : abs,
                    'air_bag'       : air_bag,
                    'radio'         : radio,
                    'cd_changer'    : cd_changer,
                    'dvd'           : dvd,
                    'tv'            : tv,
                    'power_seat'    : power_seat,
                    'back_tire'     : back_tire,
                    'grill_guard'   : grill_guard,
                    'rear_spoiler'  : rear_spoiler,
                    'central_locking':central_locking,
                    'jack'          : jack,
                    'spare_tire'    : spare_tire,
                    'degree_camera' : degree_camera,
                    'wheel_spanner' : wheel_spanner,
                    'back_camera'   : back_camera,
                    'push_start'    : push_start,
                    'keyless_entry' : keyless_entry,
                    'esc'           : esc,

                }
                this.setState({
                    'search'        : stateJson,
                    'maker'         : maker,
                    'model'         : model,
                    'bidtype'       : type,
                    'freighttype'   : freighttype,
                }, () => {
                    this.updateStateData()
                })
            }
            window.scrollTo(0, 150);
        });
    }

    render() {

        const selectMakers    = this.props.makers
        const selectModels    = this.props.models
        let typeSearchRes     = this.props.searchTypeRes
        let countrieslist     = this.props.countrieslist


        let searchType = []
        for (const [type, makerModels] of Object.entries(typeSearchRes)) {
            let makerCount = [];
            for (const [name, count] of Object.entries(makerModels)) {
                makerCount.push(
                    <li> <a href='#' onClick={(e) => this.onClickTpye(name)}>{name} ({count})</a></li>
                )
            }
        }

        let makers      = []
        let models      = []
        let years       = []
        let freighttype = []
        let types = [{'title':'FOB'},{'title':'CnF'}]
        let currentYear = (new Date().getFullYear())

        for (var i = 1990; i <= currentYear; i++) {
            years.push(
                <option value={i} key={i}>{i}</option>
            )
        }
        selectMakers.map((maker,key) =>{
            makers.push(
                <option value={maker.title} key={key}>{maker.title}</option>
            )
        })
        selectModels.map((model,key) =>{
            models.push(
                <option value={model.title} key={key}>{model.title}</option>
            )
        })

        types.map((types,key) =>{
            freighttype.push(
                <option value={types.title} key={key}>{types.title}</option>
            )
        })

        let Item    = []
        const { data, meta, links } =  this.props.mainSearchData

        let loading = this.state.loading

        if(data.length > 0){
            data.map((deal, key) => (
                Item.push(
                    // <Link to={'/car-details/'+deal.id} key={key} >
                        <HorizontalItem countrieslist={countrieslist} deal={deal} />
                    // </Link>
                )
            ))
        }else{
            Item.push(
                <div className="wo-topvehicles wo-topvehicles-list">
                    <p>No Record Found!</p>
                </div>
            );
        }
        const { search } = this.state;

        return (
            <main className="wo-main overflow-hidden">
                <section className="wo-main-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div id="wo-twocolumns" className="wo-twocolumns">
                                <div className="col-12 col-md-3 col-xl-2">
                                    <aside id="wo-sidebar" className="wo-sidebar">
                                        <Brands />
                                        <hr/>
                                        <PriceRange/>
                                    </aside>
                                </div>
                                <div className="col-12 col-md-6 col-xl-8" id="customlist">
                                    <div className="wo-searchhead">
                                        <div className="col-md-12 indexCss">
                                            <div className="wo-innerbanner mb-5">
                                                <div className="wo-searchbar">
                                                    <form className="wo-themeform wo-bannerform" >
                                                        <fieldset>
                                                            <div className="wo-group-wrap">
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"maker"} placeholder="Makers" onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value=''>Select Makers</option>
                                                                                {makers}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"bidtype"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Type</option>
                                                                                <option value={'sedan'}>Sedan</option>
                                                                                <option value={'hatchback'}>Hatchback</option>
                                                                                <option value={'suv'}>SUV</option>
                                                                                <option value={'mini_van'}>Mini Van</option>
                                                                                <option value={'van'}>Van</option>
                                                                                <option value={'truck'}>Truck</option>
                                                                                <option value={'wagon'}>Wagon</option>
                                                                                <option value={'coupe'}>Coupe</option>
                                                                                <option value={'mini_vehicle'}>Mini Vehicle</option>
                                                                                <option value={'bus'}>Bus</option>
                                                                                <option value={'mini_bus'}>Mini Bus</option>
                                                                                <option value={'pick_up'}>Pick Up</option>
                                                                                <option value={'convertible'}>Convertible</option>
                                                                                <option value={'tractor'}>Tractor</option>
                                                                                <option value={'forklift'}>Forklift</option>
                                                                                <option value={'Machinery'}>Machinery</option>
                                                                                <option value={'bus_20_seats'}>Bus 20 Seats</option>
                                                                                <option value={'unspecified'}>Unspecified</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"model"}  onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Models</option>
                                                                                {models}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group mb-2">
                                                                        <input name={'priceMin'} placeholder={"Min Price"} type='number' onChange={e => this.handleChange(e)} className="form-control" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <input name={'priceMax'} placeholder={"Max Price"} type='number' onChange={e => this.handleChange(e)} className="form-control" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"yearMin"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Min Year</option>
                                                                                {years}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"yearMax"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Max Year</option>
                                                                                {years}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"min_mileage"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Min Mileage</option>
                                                                                <option value={'50000'}>50,000</option>
                                                                                <option value={'80000'}>80,000</option>
                                                                                <option value={'100000'}>100,000</option>
                                                                                <option value={'150000'}>150,000</option>
                                                                                <option value={'200000'}>200,000</option>
                                                                                <option value={'300000'}>300,000</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"max_mileage"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Max Mileage</option>
                                                                                <option value={'50000'}>50,000</option>
                                                                                <option value={'80000'}>80,000</option>
                                                                                <option value={'100000'}>100,000</option>
                                                                                <option value={'150000'}>150,000</option>
                                                                                <option value={'200000'}>200,000</option>
                                                                                <option value={'300000'}>300,000</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"steeringType"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Steering Type</option>
                                                                                <option value={'left'}>Left</option>
                                                                                <option value={'right'}>Right</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"fuelType"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Fuel Type</option>
                                                                                <option value={'cng'}>CNG</option>
                                                                                <option value={'diesel'}>Diesel</option>
                                                                                <option value={'electric'}>Electric</option>
                                                                                <option value={'hybrid-diesel'}>Hybrid Diesel</option>
                                                                                <option value={'hybrid-petrol'}>Hybrid Petrol</option>
                                                                                <option value={'petrol'}>Petrol</option>
                                                                                <option value={'lpg'}>LPG</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"transmission"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Transmission</option>
                                                                                <option value={'automatic'}>Automatic</option>
                                                                                <option value={'CVT'}>CVT</option>
                                                                                <option value={'DCT'}>DCT</option>
                                                                                <option value={'manual'}>Manual</option>
                                                                                <option value={'sami-automatic'}>Sami Automatic</option>
                                                                                <option value={'sport-at'}>Sport AT</option>
                                                                                <option value={'unspecified'}>Unspecified</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"min_eng"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Min Eng.cc</option>
                                                                                <option value="700">700cc</option>
                                                                                <option value="1000">1000cc</option>
                                                                                <option value="1500">1500cc</option>
                                                                                <option value="1800">1800cc</option>
                                                                                <option value="2000">2000cc</option>
                                                                                <option value="2500">2500cc</option>
                                                                                <option value="3000">3000cc</option>
                                                                                <option value="4000">4000cc</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2">
                                                                        <div className="wo-select">
                                                                            <select name={"max_eng"} onChange={e => this.handleChange(e)} className="form-control">
                                                                                <option value="">Select Max Eng.cc</option>
                                                                                <option value="700">700cc</option>
                                                                                <option value="1000">1000cc</option>
                                                                                <option value="1500">1500cc</option>
                                                                                <option value="1800">1800cc</option>
                                                                                <option value="2000">2000cc</option>
                                                                                <option value="2500">2500cc</option>
                                                                                <option value="3000">3000cc</option>
                                                                                <option value="4000">4000cc</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1" id="check">
                                                                    <div className="form-group form-check">
                                                                        <input type="radio" onChange={e => this.handleChange(e)} className="form-check-input" value="fob" name="freighttype" id="freighttype-fob"></input>
                                                                        <label className="form-check-label" htmlFor="freighttype-fob">FOB</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1" id="check">
                                                                    <div className="form-group form-check">
                                                                        <input type="radio" onChange={e => this.handleChange(e)} className="form-check-input" value="cnf" name="freighttype" id="freighttype-Cnf"></input>
                                                                        <label className="form-check-label" htmlFor="freighttype-Cnf">C&F</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2" id="check">
                                                                    <div className="form-group form-check">
                                                                        <input type="checkbox" onChange={e => this.seeMoreHandleChange(e)} className="form-check-input" name="see_more" id="see_more"></input>
                                                                        <label className="form-check-label" htmlFor="see_more">Advance Search...</label>
                                                                    </div>
                                                                </div>
                                                                <ul className={this.state.checkBoxHidden? 'see-more-ul searchCheckboxHidden' : 'see-more-ul'}>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="cd_player" id="cd_player"></input>
                                                                            <label className="form-check-label" htmlFor="cd_player">CD Player</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="sun_roof" id="sun_roof"></input>
                                                                            <label className="form-check-label" htmlFor="sun_roof">Sun Roof</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="leather_seat" id="leather_seat"></input>
                                                                            <label className="form-check-label" htmlFor="leather_seat">Leather Seat</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="alloy_wheels" id="alloy_wheels"></input>
                                                                            <label className="form-check-label" htmlFor="alloy_wheels">Alloy Wheels</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="power_steering" id="power_steering"></input>
                                                                            <label className="form-check-label" htmlFor="power_steering">Power Steering</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="power_window" id="power_window"></input>
                                                                            <label className="form-check-label" htmlFor="power_window">Power Window</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="a_c" id="a_c"></input>
                                                                            <label className="form-check-label" htmlFor="a_c">A/C</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="abs" id="abs"></input>
                                                                            <label className="form-check-label" htmlFor="abs">ABS</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="air_bag" id="air_bag"></input>
                                                                            <label className="form-check-label" htmlFor="air_bag">Air Bag</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="radio" id="radio"></input>
                                                                            <label className="form-check-label" htmlFor="radio">Radio</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="cd_changer" id="cd_changer"></input>
                                                                            <label className="form-check-label" htmlFor="cd_changer">Cd Changer</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="dvd" id="dvd"></input>
                                                                            <label className="form-check-label" htmlFor="dvd">DVD</label>
                                                                        </div>
                                                                    </li>

                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="tv" id="tv"></input>
                                                                            <label className="form-check-label" htmlFor="tv">TV</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="freighttype" id="power_seat"></input>
                                                                            <label className="form-check-label" htmlFor="power_seat">Power Seat</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="back_tire" id="back_tire"></input>
                                                                            <label className="form-check-label" htmlFor="back_tire">Back Tire</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="grill_guard" id="grill_guard"></input>
                                                                            <label className="form-check-label" htmlFor="grill_guard">Grill Guard</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="rear_spoiler" id="rear_spoiler"></input>
                                                                            <label className="form-check-label" htmlFor="rear_spoiler">Rear Spoiler</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="central_locking" id="central_locking"></input>
                                                                            <label className="form-check-label" htmlFor="central_locking">Central Locking</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="jack" id="jack"></input>
                                                                            <label className="form-check-label" htmlFor="jack">Jack</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="spare_tire" id="spare_tire"></input>
                                                                            <label className="form-check-label" htmlFor="spare_tire">Spare Tire</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="360_degree_camera" id="360_degree_camera"></input>
                                                                            <label className="form-check-label" htmlFor="360_degree_camera">360 Degree Camera</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="wheel_spanner" id="wheel_spanner"></input>
                                                                            <label className="form-check-label" htmlFor="wheel_spanner">Wheel Spanner</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="fog_lights" id="fog_lights"></input>
                                                                            <label className="form-check-label" htmlFor="fog_lights">Fog Lights</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="back_camera" id="back_camera"></input>
                                                                            <label className="form-check-label" htmlFor="back_camera">Back Camera</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="push_start" id="push_start"></input>
                                                                            <label className="form-check-label" htmlFor="push_start">Push Start</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input" name="keyless_entry" id="keyless_entry"></input>
                                                                            <label className="form-check-label" htmlFor="keyless_entry">Keyless Entry</label>
                                                                        </div>
                                                                    </li>
                                                                    <li className="col-md-2" id="check">
                                                                        <div className="form-group form-check">
                                                                            <input type="checkbox" onChange={e => this.checkboxHandleChange(e)} className="form-check-input"  name="ESC" id="ESC"></input>
                                                                            <label className="form-check-label" htmlFor="ESC">ESC</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>


                                                                <div className="col-md-12 text-center">
                                                                    <div className="form-group wo-form-btn typesearch">
                                                                        {/*<a href="/" className="wo-searchntn">SEARCH</a>*/}
                                                                        <button onClick={(e) => this.onSubmit(e,'button')} className='wo-searchntn'>SEARCH</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wo-searchhead__title">
                                            <h2>Your Search Result</h2>
                                            <span>About {meta.total} search result found in {this.state.pageHeading}</span>
                                        </div>

                                        <div className="wo-searchhead__type">
                                            <Pagination data={meta} links={links} onclick={this.props.getMainSearch.bind(this, {...search})} />

                                            <div className="wo-select">
                                                <select name={"sortBy"} onChange={e => this.handleChange(e)} className="form-control">
                                                    <option defaultValue="" data-select2-id="17">Sort By.</option>
                                                    <option value="price_high_to_low">Price High to Low</option>
                                                    <option value='price_low_to_high'>Price Low to High</option>
                                                    <option value='engine_high_to_low'>Engine High to Low</option>
                                                    <option value='engine_low_to_high'>Engine Low to High</option>
                                                    <option value='mileage_hight_to_low'>Mileage High to Low</option>
                                                    <option value='mileage_low_to_high'>Mileage Low to High</option>
                                                    <option value='year_new_to_old'>Year New to Old</option>
                                                    <option value='year_old_to_new'>Year Old to New</option>
                                                </select>
                                            </div>

                                            {/*<a href="#" className="wo-searchtype"><i className="ti-map"></i></a>*/}
                                            {/*<a href="#" className="wo-searchtype"><i className="ti-view-list"></i></a>*/}
                                            {/*<a href="#" className="wo-searchtype active"><i className="ti-view-grid"></i></a>*/}
                                        </div>
                                    </div>

                                    <div className="wo-vehicleslist">
                                        {loading ? <Loader /> : Item}
                                        <Pagination data={meta} links={links} onclick={this.props.getMainSearch.bind(this, {...search})} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div>
                                        <img src="/assets/images/ads/stock.jpg" alt="img description"></img>
                                    </div>
                                    <Countries/>
                                    <div style={{ marginTop: '20px' }}>
                                        <img src="/assets/images/ads/spare-parts.jpg" alt="img description"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getMainSearch   : (jsondata, page, limit)   => dispatch(getMainSearch(jsondata, page, limit)),
    // getkeywordSearch: (search,type,sort,page, limit) => dispatch(getKeywordSearch(search,type,sort,page, limit)),
    keysearchUrlRes : (key)                     => dispatch(keysearchUrlRes(key)),
    getMakers       : ()                        => dispatch(getMakers()),
    getModels       : ()                        => dispatch(getModels()),
    searchByType    : ()                        => dispatch(serachByType()),
    getCountries    : ()                        => dispatch(getCountries()),

})

/*
 * mapStateToProps
*/
const mapStateToProps = state => {
    return {
        // makerListing        : state.dealReducer.deals,
        mainSearchData      :   state.dealReducer.mainSearch,
        // getkeywordSearchRes :   state.homeReducer.getkeywordSearchRes,
        urlSearchRes        :   state.homeReducer.urlSearchRes,
        makers              :   state.homeReducer.makers,
        models              :   state.homeReducer.models,
        searchTypeRes       :   state.dealReducer.searchTypeRes,
        countrieslist:      state.dealReducer.countries,

        // getUsedCarSearchRes : state.homeReducer.urlSearchRes,

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Horizontal));

