import {GET_STOCK} from '../../constants/index'

const initialState = {
    stocks:[],
    loading:true
}


const reducer =  (state=initialState, action) => {
      switch(action.type){
        case GET_STOCK:
        return {
            ...state,
            stocks:action.payload,
            loading:false

        }
        default: return state
    }
  };


export default reducer
