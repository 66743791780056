import {customer, homeConstants} from '../../constants/index'

const initialState = {
  remittances :{
    data: [
      {
        client: {
          fullname: '',
          country: {
            name: ''
          }
        },
        bank : {},
        deals : [],
        status : {},
        transactions : {},
        type : {},
        user : {},
        media : [],

      }
    ],
    meta: {}
  },
  clientDeals: {
    data: [
      {
        client: {
          fullname: '',
          country: {
            name: ''
          }
        },
        BL : {
          fileName : ""
        },
        PerfomaInvoice :{
          fileName : ""
        },
        bid: {
          media: [],
          car_maker: {},
          car_model: {},
          car_color: {},
          country: {},
          attributes: {
            selling_price :''
          }
        },
        logistic_info:{
          shipping_line : {
            countries_policy:[{
                  inspectiontype:{
                    name : ""
                  }
                }]
        },
        vessel : {
          name : ''
        },
        shipper_line : {
          name: "",
        },
          destination_port : {
            title : ''
          },
          port : {
            title : ''
          },
          yard: {
            title : ''
          }
        }
      }
    ],
    meta: {}
  },
  states : [{
    label : '',
    value : '',
  }],
  countries : [{
    label : '',
    value : '',
  }],
  cities : [{
    label : '',
    value : '',
  }],
  notifypartyRes : [{
    id        : '',
    client_id : '',
    title     : '',
    nick_name : '',
    phone_number: '',
    address   : '',
    email     : '',
  }],
  consigneeRes : [{
    id        : '',
    client_id : '',
    party_name     : '',
    nick_name : '',
    phone: '',
    address   : '',
  }],
  loginresp :{
    code:200,
    status           : true,
    id               : '',
    message          : '',
    isLoggedIn       : false,
    name             : '',
    username         : '',
    email            : '',
    email_verified_at: '',
    user_detail      : [],
    client_detail    : {
      country : {
        name : '',
        code : 'JP'
      }
    },
    remember_token   : '',
    created_at       : '',
    token            : {
      headers        : {},
      original       : {},
      exception      : '',
    },
    OTP : {
      code:200,
      status:true,
      message:"",
      otp:''
    },
  },
  customerReviews: {
    data: [
      {
        client: {
          fullname: '',
          country: {
            name: ''
          }
        },
        deal:{
          bid: {
            media: [],
            car_maker: {},
            car_model: {},
            car_color: {},
          }
        }
      }
    ],
  },
  customerFeedback: {
    data: [
      {
        client: {
          fullname: '',
          country: {
            name: ''
          }
        },
        deal:{
          bid: {
            media: [],
            car_maker: {},
            car_model: {},
            car_color: {},
          }
        }
      }
    ],
  },
  dashboardCount :{
    shipped : 0,
    notShipped : 0
  },
  trustedClientRes: {
    status : '',
    data : [
        {
            fullname: "",
            rate    : "",
            is_trusted:"",
            country: {
              name: ''
            }
      }
    ],
  },
  loading: true,
};


const reducer = (state = initialState, action) => {

  switch (action.type) {
    case customer.LOGIN:
      return {
        ...state,
        loginresp : action.payload,
        loading   : false,
      };
      case customer.LOGIN_UPDATE:
      return {
        ...state,
        loginresp : {
          ...state.loginresp,
          ...action.payload
        },
        loading   : false,
      };
    case customer.LOGIN_ERROR:
      return {
        ...state,
        loginresp: action.payload.response.data,
        loading  : false,
      };
    case customer.REMITTANCES:
      return {
        ...state,
        remittances: action.payload,
        loading  : false
      };
      case customer.DASHBOARD:
      return {
        ...state,
        dashboardCount: action.payload,
        loading  : false
      };
    case customer.CLIENT_DEAL:
      return {
        ...state,
        clientDeals: action.payload,
        loading  : false
      };
    case customer.STATES:
      return {
        ...state,
        states: action.payload,
        loading  : false
      };
    case customer.COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        loading  : false
      };
    case customer.CITIES:
      return {
        ...state,
        cities: action.payload,
        loading  : false
      };
    case customer.UPDATE_PROFILE:
      return {
        ...state,
        profileRes: action.payload,
        loading: false
      };
    case customer.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        profileRes: action.payload.response.data,
        loading: false
      };
    case customer.OTP_GENERATE:
      return {
        ...state,
        OTP : {
          ...state.OTP,
          ...action.payload
        },
        message : action.payload.message,
        loading: false
      };
    case customer.OTP_GENERATE_ERROR:
      return {
        ...state,
        OTP: action.payload,
        loading: false
      };
      case customer.OTP_VERIFY:
        return {
          ...state,
          loginresp : {
            ...state.loginresp,
            ...action.payload
          },
          message : action.payload.message,
          loading: false
        };
      case customer.OTP_VERIFY_ERROR:
        return {
          ...state,
          loginresp : {
            ...state.loginresp,
            ...action.payload.response.data
          },
          message : action.payload.response.data.message,
          loading: false
        };
    case customer.REGISTER:
      return {
        ...state,
        registerRes: action.payload,
        loading: false
      };
    case customer.REGISTER_ERROR:
      return {
        ...state,
        registerRes: action.payload.response.data,
        loading: false
      };
    case customer.STORE_REVIEW:
      return {
        ...state,
        reviewresp: action.payload,
        loading: false
      };
    case customer.STORE_REVIEW_ERROR:
      return {
        ...state,
        reviewresp: action.payload.response.data,
        loading: false
      };

    case customer.STORE_FEEDBACK:
      return {
        ...state,
        feedbackresp: action.payload,
        loading: false
      };
    case customer.STORE_FEEDBACK_ERROR:
      return {
        ...state,
        feedbackresp: action.payload.response.data,
        loading: false
      };

    case homeConstants.TRUSTED_CLIENT:
      return {
        ...state,
        trustedClientRes: action.payload,
      };
    case customer.REVIEW:
      return {
        ...state,
        customerReviews: action.payload,
        loading  : false
      };
    case customer.FEEBACK:
      return {
        ...state,
        customerFeedback: action.payload,
        loading  : false
      };
    case customer.NOTIFY_PARTY:
      return {
        ...state,
        notifypartyRes: action.payload,
        loading  : false
      };
    case customer.UPDATE_NOTIFY_PARTY:
      return {
        ...state,
        updateNotifypartyRes: action.payload,
        loading: false
      };
    case customer.UPDATE_NOTIFY_PARTY_ERROR:
      return {
        ...state,
        updateNotifypartyRes: action.payload.response.data,
        loading: false
      };
    case customer.CONSIGNEE:
      return {
        ...state,
        consigneeRes: action.payload,
        loading  : false
      };
    default: return state
    case customer.UPDATE_CONSIGNEE:
      return {
        ...state,
        updateConsigneeRes: action.payload,
        loading: false
      };
    case customer.UPDATE_CONSIGNEE_ERROR:
      return {
        ...state,
        updateConsigneeRes: action.payload.response.data,
        loading: false
      };
  }
};


export default reducer
