import { dealConstants, carDetails, dealQuote, COUNTRIES_LIST } from '../../constants/index'
import { dealPayload } from '../payloads/deal';
import {detailPayload} from "../payloads/detail";
 
const initialState = {
  cardetails: {
    data: detailPayload,
    loading : true
  },
  deals: {
    data: dealPayload,
    meta: {},
    loading:false
  },
  mainSearch: {
    data: dealPayload,
    meta: {},
    loading:true
  },
  countries : [],
  searchTypeRes: {},
  loading: true
}


const reducer = (state = initialState, action) => {

  switch (action.type) {
    case dealConstants.LISTING:
      return {
        ...state,
        loading: false,
      };
    case dealConstants.LIST:
      return {
        ...state,
        deals: action.payload,
        loading: false
      };
    case dealConstants.SEARCH:
      return {
        ...state,
        mainSearch: action.payload,
      };
    case carDetails.CAR_DETAILS_DATA:
      return {
        ...state,
        cardetails: action.payload,
      };
    case dealConstants.MAKER_LISTING:
      return {
        ...state,
        deals: action.payload,
        loading: false
      };
    case dealQuote.QUOTE:
      return {
        ...state,
        quoteresp: action.payload,
        loading: false
      };
    case dealQuote.QUOTE_ERROR:
      return {
        ...state,
        quoteresp: action.payload.response.data,
        loading: false
      };
    case dealQuote.LISTING_QUOTE:
      return {
        ...state,
        listingQuoteresp: action.payload,
        loading: false
      };
    case dealQuote.LISTING_QUOTE_ERROR:
      return {
        ...state,
        listingQuoteresp: action.payload.response.data,
        loading: false
      };

    case COUNTRIES_LIST:
      return {
        ...state,
        countries: action.payload,
        loading: false
      };
    case dealConstants.TYPE_SEARCH:
      return {
        ...state,
        searchTypeRes: action.payload,
        loading: false

      };
    default: return state
  }
};


export default reducer
