import { dealConstants,carDetails } from '../constants/index'
import axiosConfig from '../axiosConfig'
import common from '../helpers/common'

const getDeals = (page = 1, limit = 8) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/deals/view?limit=`+limit+`&page=`+page)
        dispatch( {
            type: dealConstants.LIST,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }
}

const getNewArrival = (createdDate = 'desc', limit = 8) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/search/main-search?limit=`+limit+`&createdDate=`+createdDate)
        dispatch( {
            type: dealConstants.NEW_ARRIVALS,
            payload: res.data
        })
    }

    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }
}

const getPremiumClass = (priceSort = 'desc', limit = 8) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/search/main-search?limit=`+limit+`&priceSort=`+priceSort)
        dispatch( {
            type: dealConstants.PREMIUM_CLASS,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }
}

const getCheap = (priceSort = 'asc', limit = 4) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/search/main-search?limit=`+limit+`&priceSort=`+priceSort)
        dispatch( {
            type: dealConstants.CHEAP_DEALS,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }
}

const getSimilar = (jsonobj = {'type':'sedan'}, limit = 4) => async dispatch => {
    try{
        let queryString = common.objToQueryString(jsonobj);
        let url = `/search/main-search?`+queryString+`&limit=`+limit
        const res = await axiosConfig.get(url)
        dispatch( {
            type: dealConstants.SIMILAR_DEALS,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }
}

const getCarDetails = (id = 0) => async dispatch => {
    try{
        const res = await axiosConfig.get(`/deals/detail?id=`+id)
        dispatch( {
            type: carDetails.CAR_DETAILS_DATA,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: carDetails.CAR_DETAILS_DATA,
            payload: console.log(e),
        })
    }
}

const getMakerListing = (jsonobj,page =1,limit= 15) => async dispatch => {
    try{
        let queryString = common.objToQueryString(jsonobj);
        let url = `/search/main-search?`+queryString+`&limit=`+limit +`&page=`+page
        const res = await axiosConfig.get(url)
        // const res = await axiosConfig.get(`/search/main-search?maker=${maker}&limit=${limit}&page=${page}`)
        dispatch( {
            type: dealConstants.SEARCH,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: dealConstants.ERROR,
            payload: console.log(e),
        })
    }
}

  export {getCarDetails , getDeals,getNewArrival, getPremiumClass, getCheap, getMakerListing,getSimilar};